import { Icon } from "@iconify/react";
import { useSnackbar } from "notistack";
import { useRef, useState } from "react";
import personFill from "@iconify/icons-eva/person-fill";
import settings2Fill from "@iconify/icons-eva/settings-2-fill";
import baselineHub from "@iconify/icons-ic/baseline-hub";
import { Link as RouterLink } from "react-router-dom";
// material
import {
  Avatar,
  Stack,
  Box,
  Divider,
  MenuItem,
  Typography,
} from "@mui/material";
// components
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import MenuPopover from "../../components/MenuPopover";
import useAuth from "../../hooks/useAuth";
import useIsMountedRef from "../../hooks/useIsMountedRef";
import { ASSETS_PATH, CheckPerm } from "../../utils/apis";
import { PATH_DASHBOARD } from "../../routes/paths";

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: "My Profile",
    icon: personFill,
    linkTo: PATH_DASHBOARD.general.profile,
    permissions: "",
  },
  {
    label: "Activity Management",
    icon: baselineHub,
    linkTo: PATH_DASHBOARD.laboratory.log,
    permissions: "",
  },
  {
    label: "Preferences",
    icon: settings2Fill,
    linkTo: PATH_DASHBOARD.general.settings,
    permissions: "",
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const isMountedRef = useIsMountedRef();
  const { user, logout } = useAuth();
  const photo =
    user && user.user && user.user.photo
      ? `${ASSETS_PATH.personnel}${user.user.photo}`
      : "/static/placeholder.svg";
  const OPTIONS = MENU_OPTIONS.filter((opt) => CheckPerm(opt.permissions));

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleLogout = async () => {
    try {
      await logout();
      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      enqueueSnackbar("Unable to logout", { variant: "error" });
    }
  };
  return (
    <>
      <Stack
        direction="row"
        ref={anchorRef}
        onClick={handleOpen}
        justifyContent="space-between"
        alignItems="center"
        sx={{ height: 1, width: 1, px: 1, cursor: "pointer" }}
      >
        <Stack
          direction="row"
          spacing={1.5}
          alignItems="center"
          justifyContent="center"
        >
          <Avatar
            variant="square"
            alt={user && user.user ? user.user.fname : ""}
            src={photo}
          >
            {user && user.user
              ? `${user.user.fname[0].toUpperCase()}${user.user.lname[0].toUpperCase()}`
              : ""}
          </Avatar>
          <Stack
            direction="column"
            alignItems="start"
            spacing={0}
            justifyContent="center"
            sx={{ height: 1, width: 150, overflow: "hidden" }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                color: "common.white",
                width: "inherit",
                textAlign: "left",
              }}
            >
              {`${user.user.fname} ${user.user.lname}`}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "common.white",
                width: "inherit",
                textAlign: "left",
              }}
            >
              {(user.role && user.role.name) || ""}
            </Typography>
          </Stack>
        </Stack>
        <UnfoldMoreIcon sx={{ color: "common.white" }} />
      </Stack>

      <MenuPopover
        arrow="bottom-left"
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 1 }}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Avatar
              variant="square"
              alt={user && user.user ? user.user.fname : ""}
              src={photo}
            >
              {user && user.user
                ? `${user.user.fname[0].toUpperCase()}${user.user.lname[0].toUpperCase()}`
                : ""}
            </Avatar>
            <Stack>
              <Typography variant="subtitle2" noWrap>
                {user && user.user
                  ? `${user.user.fname} ${user.user.lname}`
                  : ""}
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: "text.secondary" }}
                noWrap
              >
                {(user.role && user.role.name) || ""}
              </Typography>
            </Stack>
          </Stack>
        </Box>

        <Divider sx={{ my: 1 }} />

        {OPTIONS.map((option, index) => (
          <MenuItem
            key={index}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: "body2", py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24,
              }}
            />

            {option.label}
          </MenuItem>
        ))}
        <MenuItem
          onClick={handleLogout}
          sx={{ typography: "body2", py: 1, px: 2.5 }}
        >
          <ExitToAppIcon
            sx={{
              mr: 2,
              width: 24,
              height: 24,
            }}
          />
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}
