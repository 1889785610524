import { motion } from "framer-motion";
import { Link as RouterLink } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import { Box, Button, Typography, Container } from "@mui/material";
// components
import { MotionContainer, varBounceIn } from "../../components/animate";
import Page from "../../components/Page";
import { PageNotFoundIllustration } from "../../assets";
import { PATH_AUTH } from "../../routes/paths";

const RootStyle = styled(Page)(({ theme }) => ({
  display: "flex",
  minHeight: "100%",
  alignItems: "center",
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

export default function StoreError() {
  return (
    <RootStyle title="Session Ended">
      <Container>
        <MotionContainer initial="initial" open>
          <Box sx={{ maxWidth: 480, margin: "auto", textAlign: "center" }}>
            <motion.div variants={varBounceIn}>
              <Typography variant="h3" paragraph>
                You have been logged out!
              </Typography>
            </motion.div>
            <Typography sx={{ color: "text.secondary" }}>
              Please login again to proceed.
            </Typography>
            <motion.div variants={varBounceIn}>
              <PageNotFoundIllustration sx={{ height: 450 }} />
            </motion.div>
            <Button
              to={PATH_AUTH.Login}
              size="large"
              variant="contained"
              component={RouterLink}
            >
              Login
            </Button>
          </Box>
        </MotionContainer>
      </Container>
    </RootStyle>
  );
}
