import PropTypes from "prop-types";
import * as Yup from "yup";
import { Link as RouterLink } from "react-router-dom";
import { Form, FormikProvider, useFormik } from "formik";
import { useState, useCallback, useEffect } from "react";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
// material
import {
  Container,
  TextField,
  Box,
  Avatar,
  Button,
  Stack,
  Autocomplete,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  MenuItem,
  Alert,
  Typography,
  Divider,
  IconButton,
  Badge,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import CancelIcon from "@mui/icons-material/Cancel";
import { AddIcon } from "../../../assets";
// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// hooks
import useSettings from "../../../hooks/useSettings";
import { ASSETS_PATH, GENDERS, STATES } from "../../../utils/apis";
// components
import Page from "../../Page";
import HeaderBreadcrumbs from "../../HeaderBreadcrumbs";
import useAuth from "../../../hooks/useAuth";
import useIsMountedRef from "../../../hooks/useIsMountedRef";
import { UploadAvatar } from "../../upload";
import { iff, CircularIndeterminate, ShowError } from "../../../utils/loader";

AddSuccess.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};
function AddSuccess({ open, handleClose }) {
  return (
    <Dialog maxWidth="sm" open={open} onClose={handleClose}>
      <DialogTitle>
        <IconButton
          sx={{ position: "absolute", right: 8, top: 8 }}
          onClick={handleClose}
          color="error"
        >
          <CancelIcon />
        </IconButton>{" "}
      </DialogTitle>
      <DialogContent sx={{ textAlign: "center" }}>
        <AddIcon sx={{ mx: "auto" }} />
        <DialogTitle>Profile has been updated!</DialogTitle>
        <DialogContentText>
          Your profile has been updated successfully!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ width: "100%" }}
        >
          <Button fullWidth onClick={handleClose} color="primary">
            Close
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
export default function ViewPersonnel() {
  const { themeStretch } = useSettings();
  const isMountedRef = useIsMountedRef();
  const {
    user,
    updatePersonnel,
    getPersonnel,
    getLabs,
    getRoles,
    getJobs,
    getDepartments,
  } = useAuth();
  const uid = user && user.user ? user.user.uid : "";
  const [personnel, setpersonnel] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [newPhoto, setPhoto] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const [opened, setOpened] = useState([]);
  const [ROLES, setROLES] = useState([]);
  const [JOBS, setJOBS] = useState([]);
  const [DEPARTMENTS, setDEPARTMENTS] = useState([]);
  const [LABS, setLABS] = useState([]);

  const [openSuccess, setOpenSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleSuccessClose = () => {
    setOpenSuccess(false);
  };

  const get = useCallback(() => {
    async function fetch() {
      try {
        setIsLoading(true);
        setpersonnel(await getPersonnel(uid));
      } catch (error) {
        setError(error);
      }
      setIsLoading(false);
    }
    fetch();
  }, [uid, getPersonnel]);

  useEffect(() => {
    get();
  }, [get]);

  const addSchema = Yup.object().shape({
    fname: Yup.string().required("First name is required"),
    lname: Yup.string().required("Last name is required"),
    phone: Yup.string().required("Phone number is required"),
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email address is required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: personnel.username || "",
      fname: personnel.fname || "",
      lname: personnel.lname || "",
      mname: personnel.mname || "",
      gender: personnel.gender || "",
      dob: personnel.dob || "",
      phone: personnel.phone || "",
      email: personnel.email || "",
      address: personnel.address || "",
      city: personnel.city || "",
      state: personnel.state || "",
      photo: "",
      lab: {},
      job: personnel.job || "",
      department: personnel.department || "",
      role: personnel.role || "",
      employDate: personnel.employDate || "",
      employType: personnel.employType || "",
      password: "",
      cpassword: "",
    },

    validationSchema: addSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        if (values.password) {
          if (values.password !== values.cpassword) {
            enqueueSnackbar(
              "Passwords do not match! Please confirm password to proceed.",
              {
                variant: "error",
              }
            );
            return;
          }
        }
        await updatePersonnel(values, uid);
        if (isMountedRef.current) {
          setSubmitting(false);
        }
        setOpenSuccess(true);
      } catch (error) {
        if (isMountedRef.current) {
          setErrors({ afterSubmit: error.message });
          setSubmitting(false);
        }
      }
    },
  });

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setPhoto({
          ...file,
          preview: URL.createObjectURL(file),
        });
        const reader = new FileReader();
        reader.readAsDataURL(file);
        // eslint-disable-next-line
        reader.onload = function () {
          setFieldValue(
            "photo",
            reader.result ? reader.result.split(";base64,")[1] : ""
          );
        };
        // eslint-disable-next-line
        reader.onerror = function (error) {
          enqueueSnackbar(
            "An error occured while trying to upload the photo! Please check image and try again.",
            { variant: "error" }
          );
        };
      }
    },
    [enqueueSnackbar, setFieldValue]
  );

  useEffect(() => {
    async function get() {
      const roles = await getRoles("");
      setROLES(roles);
      const selectedRole = roles.filter((role) => role.rid === personnel.role);
      setFieldValue("role", selectedRole.length > 0 ? selectedRole[0] : {});

      const jobs = await getJobs("");
      setJOBS(jobs);
      const selectedJob = jobs.filter((job) => job.uid === personnel.job);
      setFieldValue("job", selectedJob.length > 0 ? selectedJob[0] : {});

      const departments = await getDepartments("");
      setDEPARTMENTS(departments);
      const selectedDepartment = departments.filter(
        (department) => department.did === personnel.department
      );
      setFieldValue(
        "department",
        selectedDepartment.length > 0 ? selectedDepartment[0] : {}
      );

      const labs = await getLabs("");
      setLABS(labs);
      const selectedLab = labs.filter((lab) => lab.lid === personnel.lab);
      setFieldValue("lab", selectedLab.length > 0 ? selectedLab[0] : {});
    }
    get();
  }, [getRoles, getJobs, getDepartments, getLabs, personnel, setFieldValue]);

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <Page title="My Profile">
      {personnel.fname && !isLoading && !error ? (
        <Container maxWidth={themeStretch ? false : "lg"}>
          <HeaderBreadcrumbs
            isBack
            heading="My Profile"
            links={[
              { name: "Dashboard", href: PATH_DASHBOARD.root },
              { name: "My Profile" },
            ]}
          />
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack direction="column" spacing={2}>
                <Box
                  sx={{
                    py: 2,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Avatar
                      variant="square"
                      src={
                        personnel.photo
                          ? `${ASSETS_PATH.personnel}${personnel.photo}`
                          : ""
                      }
                      alt={personnel.fname}
                      sx={{ width: 80, height: 80 }}
                    />
                    <Stack>
                      <Typography
                        variant="h4"
                        noWrap
                        sx={{ color: "text.primary" }}
                      >
                        {`${personnel.fname} ${personnel.lname}`}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        noWrap
                        sx={{ color: "text.secondary" }}
                      >
                        ID: {uid}
                      </Typography>
                    </Stack>
                  </Stack>
                </Box>
                <Divider flexItem />
                {!!errors.afterSubmit && (
                  <Alert severity="error">{errors.afterSubmit}</Alert>
                )}
                <Stack
                  direction="column"
                  divider={<Divider orientation="horizontal" flexItem />}
                  spacing={2}
                >
                  <Box>
                    <Stack
                      direction={{ xs: "column", md: "row" }}
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={2}
                      divider={<Divider orientation="horizontal" flexItem />}
                      sx={{ p: 1, width: 1 }}
                    >
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Badge
                          badgeContent={1}
                          color="primary"
                          overlap="circular"
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                        />
                        <Divider textAlign="left" flexItem>
                          <Typography variant="subtitle1">BIO DATA</Typography>
                        </Divider>
                      </Stack>
                      <Button
                        color="info"
                        startIcon={
                          opened.includes(1) ? (
                            <ArrowDropUpIcon />
                          ) : (
                            <ArrowDropDownIcon />
                          )
                        }
                        onClick={() => {
                          if (opened.includes(1)) {
                            setOpened((prev) =>
                              prev.filter((item) => item !== 1)
                            );
                          } else {
                            setOpened((prev) => [...prev, 1]);
                          }
                        }}
                      >
                        {opened.includes(1) ? "Show" : "Hide"} Section
                      </Button>
                    </Stack>
                    {!opened.includes(1) && (
                      <Box
                        sx={{
                          borderRadius: 1,
                          border: "1px solid rgba(145, 158, 171, 0.24)",
                          p: 2,
                        }}
                      >
                        <Stack
                          direction="column"
                          alignItems="flex-start"
                          justifyContent="flex-start"
                          spacing={2}
                          sx={{ width: 1, px: 2 }}
                        >
                          <UploadAvatar
                            accept="image/jpeg,image/png"
                            file={
                              newPhoto ||
                              (personnel.photo &&
                                `${ASSETS_PATH.personnel}${personnel.photo}`)
                            }
                            maxSize={1000000}
                            onDrop={handleDrop}
                            sx={{
                              mx: 0,
                              m: 0,
                              borderWidth: 2,
                              borderStyle: "solid",
                              borderColor: "common.white",
                              width: { xs: 80, md: 150 },
                              height: { xs: 80, md: 150 },
                              display: "flex",
                            }}
                            name={`${values.fname} ${values.lname}`}
                          />
                          <Stack spacing={3} sx={{ width: 1 }}>
                            <Stack
                              direction={{ md: "row", xs: "column" }}
                              spacing={{ md: 1, xs: 3 }}
                            >
                              <TextField
                                fullWidth
                                label="Username"
                                name="username"
                                {...getFieldProps("username")}
                                disabled
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <Icon icon="ci:list-checklist-alt" />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                              <TextField
                                fullWidth
                                label="First Name"
                                placeholder="-- Enter first name--"
                                {...getFieldProps("fname")}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <Icon icon="ci:user" />
                                    </InputAdornment>
                                  ),
                                }}
                                error={Boolean(touched.fname && errors.fname)}
                                helperText={touched.fname && errors.fname}
                              />
                              <TextField
                                fullWidth
                                label="Middle Name "
                                name="mname"
                                placeholder="-- Enter middle name--"
                                {...getFieldProps("mname")}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <Icon icon="ci:user" />
                                    </InputAdornment>
                                  ),
                                }}
                                error={Boolean(touched.mname && errors.mname)}
                                helperText={touched.mname && errors.mname}
                              />
                            </Stack>
                            <Stack
                              direction={{ md: "row", xs: "column" }}
                              spacing={{ md: 1, xs: 3 }}
                            >
                              <TextField
                                fullWidth
                                label="Last Name"
                                name="lname"
                                placeholder="-- Enter last name--"
                                {...getFieldProps("lname")}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <Icon icon="ci:user" />
                                    </InputAdornment>
                                  ),
                                }}
                                error={Boolean(touched.lname && errors.lname)}
                                helperText={touched.lname && errors.lname}
                              />
                              <TextField
                                select
                                fullWidth
                                label="Gender"
                                value={values.gender}
                                onChange={(event) => {
                                  setFieldValue("gender", event.target.value);
                                }}
                                error={Boolean(touched.gender && errors.gender)}
                                helperText={touched.gender && errors.gender}
                              >
                                {GENDERS.map((option) => (
                                  <MenuItem
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </TextField>
                              <TextField
                                fullWidth
                                label="Date of Birth"
                                name="dob"
                                type="date"
                                placeholder="-- Enter Date of birth --"
                                {...getFieldProps("dob")}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <Icon icon="ci:calendar" />
                                    </InputAdornment>
                                  ),
                                }}
                                error={Boolean(touched.dob && errors.dob)}
                                helperText={touched.dob && errors.dob}
                              />
                            </Stack>
                            <Stack
                              direction={{ md: "row", xs: "column" }}
                              spacing={{ md: 1, xs: 3 }}
                            >
                              <TextField
                                fullWidth
                                label="Contact Phone"
                                name="phone"
                                placeholder="-- Enter Phone Number --"
                                {...getFieldProps("phone")}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <Icon icon="ci:phone-outline" />
                                    </InputAdornment>
                                  ),
                                }}
                                error={Boolean(touched.phone && errors.phone)}
                                helperText={touched.phone && errors.phone}
                              />
                              <TextField
                                fullWidth
                                label="Contact Email"
                                name="email"
                                placeholder="-- Enter email address --"
                                {...getFieldProps("email")}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <Icon icon="ci:mail" />
                                    </InputAdornment>
                                  ),
                                }}
                                error={Boolean(touched.email && errors.email)}
                                helperText={touched.email && errors.email}
                              />
                            </Stack>
                            <Stack
                              direction={{ md: "row", xs: "column" }}
                              spacing={{ md: 1, xs: 3 }}
                            >
                              <TextField
                                fullWidth
                                label="Street Address"
                                name="address"
                                placeholder="-- Enter Address --"
                                {...getFieldProps("address")}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <Icon icon="ci:calendar" />
                                    </InputAdornment>
                                  ),
                                }}
                                error={Boolean(
                                  touched.address && errors.address
                                )}
                                helperText={touched.address && errors.address}
                              />
                              <TextField
                                fullWidth
                                label="City"
                                name="city"
                                placeholder="-- Enter City --"
                                {...getFieldProps("city")}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <Icon icon="ci:list-checklist-alt" />
                                    </InputAdornment>
                                  ),
                                }}
                                error={Boolean(touched.city && errors.city)}
                                helperText={touched.city && errors.city}
                              />
                              <TextField
                                fullWidth
                                select
                                label="State"
                                value={values.state}
                                onChange={(event) => {
                                  setFieldValue("state", event.target.value);
                                }}
                                error={Boolean(touched.state && errors.state)}
                                helperText={touched.state && errors.state}
                              >
                                {STATES.map((option) => (
                                  <MenuItem key={option} value={option}>
                                    {option}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Box>
                    )}
                  </Box>
                  <Box>
                    <Stack
                      direction={{ xs: "column", md: "row" }}
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={2}
                      divider={<Divider orientation="horizontal" flexItem />}
                      sx={{ p: 1, width: 1 }}
                    >
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Badge
                          badgeContent={2}
                          color="primary"
                          overlap="circular"
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                        />
                        <Divider textAlign="left" flexItem>
                          <Typography variant="subtitle1">
                            EMPLOYMENT DATA
                          </Typography>
                        </Divider>
                      </Stack>
                      <Button
                        color="info"
                        startIcon={
                          opened.includes(2) ? (
                            <ArrowDropUpIcon />
                          ) : (
                            <ArrowDropDownIcon />
                          )
                        }
                        onClick={() => {
                          if (opened.includes(2)) {
                            setOpened((prev) =>
                              prev.filter((item) => item !== 2)
                            );
                          } else {
                            setOpened((prev) => [...prev, 2]);
                          }
                        }}
                      >
                        {opened.includes(2) ? "Show" : "Hide"} Section
                      </Button>
                    </Stack>
                    {!opened.includes(2) && (
                      <Box
                        sx={{
                          borderRadius: 1,
                          border: "1px solid rgba(145, 158, 171, 0.24)",
                          p: 2,
                        }}
                      >
                        <Stack
                          direction="column"
                          alignItems="flex-start"
                          justifyContent="flex-start"
                          spacing={2}
                          sx={{ width: 1, px: 2 }}
                        >
                          <Stack spacing={3} sx={{ width: 1 }}>
                            <Stack
                              direction={{ md: "row", xs: "column" }}
                              spacing={{ md: 1, xs: 3 }}
                            >
                              <Autocomplete
                                fullWidth
                                options={LABS}
                                {...getFieldProps("lab")}
                                value={values.lab}
                                onChange={(event, newValue) => {
                                  setFieldValue("lab", newValue);
                                }}
                                getOptionLabel={(option) => option.name || ""}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Lab"
                                    margin="none"
                                    error={Boolean(touched.lab && errors.lab)}
                                    helperText={touched.lab && errors.lab}
                                  />
                                )}
                              />
                              <TextField
                                fullWidth
                                label="Date of Employment"
                                name="employDate"
                                type="date"
                                {...getFieldProps("employDate")}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <Icon icon="ci:list-checklist-alt" />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                              <TextField
                                select
                                fullWidth
                                label="Employment Type"
                                value={values.employType}
                                onChange={(event) => {
                                  setFieldValue(
                                    "employType",
                                    event.target.value
                                  );
                                }}
                                error={Boolean(
                                  touched.employType && errors.employType
                                )}
                                helperText={
                                  touched.employType && errors.employType
                                }
                              >
                                {["Full Time", "Part Time"].map((option) => (
                                  <MenuItem key={option} value={option}>
                                    {option}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Stack>
                            <Stack
                              direction={{ md: "row", xs: "column" }}
                              spacing={{ md: 1, xs: 3 }}
                            >
                              <Autocomplete
                                fullWidth
                                options={DEPARTMENTS}
                                name="department"
                                value={values.department}
                                onChange={(event, newValue) => {
                                  if (newValue && newValue.did) {
                                    setFieldValue("department", newValue);
                                  }
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.did === value
                                }
                                getOptionLabel={(option) => option.name || ""}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Department"
                                    margin="none"
                                    error={Boolean(
                                      touched.department && errors.department
                                    )}
                                    helperText={
                                      touched.department && errors.department
                                    }
                                  />
                                )}
                              />
                              <Autocomplete
                                fullWidth
                                options={JOBS}
                                name="job"
                                value={values.job}
                                onChange={(event, newValue) => {
                                  if (newValue && newValue.uid) {
                                    setFieldValue("job", newValue);
                                  }
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.uid === value
                                }
                                getOptionLabel={(option) => option.name || ""}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Designation"
                                    margin="none"
                                    error={Boolean(touched.job && errors.job)}
                                    helperText={touched.job && errors.job}
                                  />
                                )}
                              />
                              <Autocomplete
                                fullWidth
                                options={ROLES}
                                disabled
                                name="role"
                                value={values.role}
                                onChange={(event, newValue) => {
                                  if (newValue && newValue.rid) {
                                    setFieldValue("role", newValue);
                                  }
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.rid === value
                                }
                                getOptionLabel={(option) => option.name || ""}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Role"
                                    margin="none"
                                    error={Boolean(touched.role && errors.role)}
                                    helperText={touched.role && errors.role}
                                  />
                                )}
                              />
                            </Stack>
                          </Stack>
                        </Stack>
                      </Box>
                    )}
                  </Box>
                  <Box>
                    <Stack
                      direction={{ xs: "column", md: "row" }}
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={2}
                      divider={<Divider orientation="horizontal" flexItem />}
                      sx={{ p: 1, width: 1 }}
                    >
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Badge
                          badgeContent={3}
                          color="primary"
                          overlap="circular"
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                        />
                        <Divider textAlign="left" flexItem>
                          <Typography variant="subtitle1">SECURITY</Typography>
                        </Divider>
                      </Stack>
                      <Button
                        color="info"
                        startIcon={
                          opened.includes(3) ? (
                            <ArrowDropUpIcon />
                          ) : (
                            <ArrowDropDownIcon />
                          )
                        }
                        onClick={() => {
                          if (opened.includes(3)) {
                            setOpened((prev) =>
                              prev.filter((item) => item !== 3)
                            );
                          } else {
                            setOpened((prev) => [...prev, 3]);
                          }
                        }}
                      >
                        {opened.includes(3) ? "Show" : "Hide"} Section
                      </Button>
                    </Stack>
                    {!opened.includes(3) && (
                      <Box
                        sx={{
                          borderRadius: 1,
                          border: "1px solid rgba(145, 158, 171, 0.24)",
                          p: 2,
                        }}
                      >
                        <Stack
                          direction="column"
                          alignItems="flex-start"
                          justifyContent="flex-start"
                          spacing={2}
                          sx={{ width: 1, px: 2 }}
                        >
                          <Stack spacing={3} sx={{ width: 1 }}>
                            <Stack
                              direction={{ md: "row", xs: "column" }}
                              spacing={{ md: 1, xs: 3 }}
                            >
                              <TextField
                                fullWidth
                                label="New Password"
                                name="password"
                                type={showPassword ? "text" : "password"}
                                {...getFieldProps("password")}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <Icon icon="ci:list-checklist-alt" />
                                    </InputAdornment>
                                  ),
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        onClick={handleShowPassword}
                                        edge="end"
                                      >
                                        <Icon
                                          icon={
                                            showPassword ? eyeFill : eyeOffFill
                                          }
                                        />
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                              <TextField
                                fullWidth
                                label="Confirm New Password"
                                name="cpassword"
                                type={showPassword ? "text" : "password"}
                                {...getFieldProps("cpassword")}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <Icon icon="ci:list-checklist-alt" />
                                    </InputAdornment>
                                  ),
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        onClick={handleShowPassword}
                                        edge="end"
                                      >
                                        <Icon
                                          icon={
                                            showPassword ? eyeFill : eyeOffFill
                                          }
                                        />
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Stack>
                          </Stack>
                        </Stack>
                      </Box>
                    )}
                  </Box>
                </Stack>

                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Button
                    to={PATH_DASHBOARD.laboratory.personnel}
                    component={RouterLink}
                    variant="outlined"
                    size="large"
                    startIcon={<Icon icon="ic:baseline-cancel" />}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    startIcon={<Icon icon="eva:done-all-fill" />}
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                  >
                    Save Changes
                  </LoadingButton>
                </Stack>
              </Stack>
            </Form>
          </FormikProvider>
        </Container>
      ) : (
        iff(
          isLoading,
          <CircularIndeterminate />,
          <ShowError message={error} tryAgain />
        )
      )}
      <AddSuccess open={openSuccess} handleClose={handleSuccessClose} />
    </Page>
  );
}
