import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
import { API_PATH } from "../../utils/apis";

const initialState = {
  isLoading: false,
  error: false,
  deleteError: false,
  search: "",
  page: 0,
  perPage: 10,
  total: 0,
  approvals: [],
  summary: {},
  sortBy: null,
  filters: {
    method: "",
  },
};

const slice = createSlice({
  name: "approvals",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    removeDeleteError(state) {
      state.deleteError = false;
    },
    hasDeleteError(state, action) {
      state.isLoading = false;
      state.deleteError = action.payload;
    },
    getSuccess(state, action) {
      state.isLoading = false;
      state.approvals = action.payload.data;
      state.summary = action.payload.summary;
      state.total = parseInt(action.payload.total, 10);
    },
    approveSuccess(state, action) {
      const { approve, type, reason } = action.payload;
      state.approvals = state.approvals.map((obj) =>
        parseInt(obj.oid, 10) === parseInt(approve, 10)
          ? {
              ...obj,
              status: type === "1" ? 5 : 4,
              approvalStatus: type === "1" ? 1 : 2,
              reason,
            }
          : obj
      );
      state.isLoading = false;
    },
    deletedSuccess(state, action) {
      const deleted = action.payload.split(",");
      state.approvals = state.approvals.filter(
        (obj) => !deleted.includes(obj.cid)
      );
      state.isLoading = false;
    },
    //  SORT & FILTER POSTS
    sortBy(state, action) {
      state.sortBy = action.payload;
    },
    filter(state, action) {
      state.filters.method = action.payload.method;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { filter, sortBy } = slice.actions;

export function getApprovals(search, date, page, perpage, order, sortBy) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(API_PATH.approvals, {
        page,
        search,
        perpage,
        order,
        sortBy,
        date,
      });
      const { status, data, total, summary } = response.data;
      if (status === "success") {
        dispatch(slice.actions.getSuccess({ data, total, summary }));
      } else {
        dispatch(
          slice.actions.hasError(
            new Error(
              "We cannot fetch approvals at the moment! Please check back later."
            )
          )
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteApproval(remove) {
  return async (dispatch) => {
    dispatch(slice.actions.removeDeleteError());
    try {
      const response = await axios.post(API_PATH.approvals, { remove });
      if (response.data.status === "success") {
        dispatch(slice.actions.deletedSuccess(remove));
      } else if (response.data.status === "permission_denied") {
        dispatch(
          slice.actions.hasDeleteError(
            new Error("You do not have permission to perform this action!")
          )
        );
      } else {
        dispatch(
          slice.actions.hasDeleteError(
            new Error("An error has occured! Please try again later.")
          )
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasDeleteError(error));
    }
  };
}
export function approveDisprove(approve, type, reason) {
  return async (dispatch) => {
    dispatch(slice.actions.removeDeleteError());
    try {
      const response = await axios.post(API_PATH.tests, {
        approve,
        type,
        reason,
      });
      if (response.data.status === "success") {
        dispatch(slice.actions.approveSuccess({ approve, type, reason }));
      } else if (response.data.status === "permission_denied") {
        dispatch(
          slice.actions.hasDeleteError(
            new Error("You do not have permission to perform this action!")
          )
        );
      } else {
        dispatch(
          slice.actions.hasDeleteError(
            new Error("An error has occured! Please try again later.")
          )
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasDeleteError(error));
    }
  };
}
