import PropTypes from "prop-types";
import { useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
// material
import { alpha, styled } from "@mui/material/styles";
import {
  Box,
  Divider,
  Stack,
  Drawer,
  Tooltip,
  CardActionArea,
} from "@mui/material";
// hooks
import useCollapseDrawer from "../../hooks/useCollapseDrawer";
// components
import Logo from "../../components/Logo";
import Scrollbar from "../../components/Scrollbar";
import NavSection from "../../components/NavSection";
//
import { MHidden } from "../../components/@material-extend";
import sidebarConfig from "./SidebarConfig";
import { CheckPerm, PAGE_PERMS } from "../../utils/apis";
import AccountPopover from "./AccountPopover";

const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 102;

const RootStyle = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    transition: theme.transitions.create("width", {
      duration: theme.transitions.duration.complex,
    }),
  },
}));
// ----------------------------------------------------------------------

IconCollapse.propTypes = {
  onToggleCollapse: PropTypes.func,
  collapseClick: PropTypes.bool,
};

function IconCollapse({ onToggleCollapse, collapseClick }) {
  return (
    <Tooltip title="Collapse Menu">
      <CardActionArea
        onClick={onToggleCollapse}
        sx={{
          width: 18,
          height: 18,
          display: "flex",
          cursor: "pointer",
          borderRadius: "50%",
          alignItems: "center",
          color: "text.primary",
          justifyContent: "center",
          border: "solid 1px currentColor",
          ...(collapseClick && {
            borderWidth: 2,
          }),
        }}
      >
        <Box
          sx={{
            width: 8,
            height: 8,
            borderRadius: "50%",
            bgcolor: "currentColor",
            transition: (theme) => theme.transitions.create("all"),
            ...(collapseClick && {
              width: 0,
              height: 0,
            }),
          }}
        />
      </CardActionArea>
    </Tooltip>
  );
}

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();

  const sidebarData = sidebarConfig.map((obj) => {
    const items = obj.items.filter((it) => {
      const { permission } = PAGE_PERMS.filter((pp) => pp.path === it.path)[0];
      return true;
      // return permission === "" || CheckPerm(permission);
    });
    return { subheader: items.length > 0 ? obj.subheader : "", items };
  });
  const {
    isCollapse,
    collapseClick,
    collapseHover,
    onHoverEnter,
    onHoverLeave,
  } = useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: "100%",
        "& .simplebar-content": {
          height: "100%",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Stack
        direction="column"
        spacing={3}
        sx={{
          px: 2.5,
          pt: 0,
          pb: 2,
          ...(isCollapse && {
            alignItems: "center",
          }),
        }}
      >
        <Stack
          direction="column"
          spacing={3}
          sx={{
            px: 2.5,
            py: 1,
            ...(isCollapse && {
              alignItems: "center",
            }),
          }}
        >
          <Box component={RouterLink} to="/" sx={{ display: "inline-flex" }}>
            <Logo isText disabledLink />
          </Box>
          <Divider
            variant="fullWidth"
            sx={{
              borderColor: "background.default",
              marginTop: "0px!important",
            }}
          />
        </Stack>
      </Stack>

      <NavSection navConfig={sidebarData} isShow={!isCollapse} />
      <Box sx={{ flexGrow: 1 }} />

      {!isCollapse && (
        <Box
          sx={{
            width: 1,
            textAlign: "center",
            px: 2.5,
            position: "absolute",
            left: 0,
            right: 0,
            bottom: 30,
          }}
        >
          <Divider
            variant="fullWidth"
            sx={{
              borderColor: "common.white",
              my: 1,
            }}
          />
          <AccountPopover />
        </Box>
      )}
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        displayPrint: "none",
        width: {
          lg: isCollapse ? COLLAPSE_WIDTH : DRAWER_WIDTH,
        },
        ...(collapseClick && {
          position: "absolute",
        }),
      }}
    >
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH, bgcolor: "primary.main" },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: "primary.main",
              ...(isCollapse && {
                width: COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                borderRight: 0,
                backdropFilter: "blur(6px)",
                WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
                boxShadow: (theme) => theme.customShadows.z20,
                bgcolor: (theme) =>
                  alpha(theme.palette.background.default, 0.88),
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
