import PropTypes from "prop-types";
import { useLocation, useParams } from "react-router-dom";
import { PAGE_PERMS, CheckPerm } from "../utils/apis";
import AccessDenied from "../pages/errors/AccessDenied";
import LoggedOut from "../pages/errors/LoggedOut";
import Profile from "../pages/dashboard/Profile";
import useAuth from "../hooks/useAuth";

// ----------------------------------------------------------------------

RoleBasedGuard.propTypes = {
  children: PropTypes.node,
};

const useBasePath = (location, params) =>
  Object.values(params).reduce(
    (path, param) => path.replace(`/${param}`, ""),
    location.pathname
  );

const useCurrentRole = (location, params) => {
  const pathname = useBasePath(location, params);
  if (pathname === "/") {
    return "view_dashboard_analytics";
  }
  const perm = PAGE_PERMS.filter((item) => item.path === pathname);
  return perm && perm.length > 0 ? perm[0].permission : "no_permission";
};
export default function RoleBasedGuard({ children }) {
  const location = useLocation();
  const params = useParams();
  const { isAuthenticated } = useAuth();

  const perm = useCurrentRole(location, params);

  if (!isAuthenticated) {
    return <LoggedOut />;
  }
  if (perm !== "no_permission" && perm !== "" && !CheckPerm(perm)) {
    return (
      (perm === "view_dashboard_analytics" && <Profile />) || <AccessDenied />
    );
  }
  // eslint-disable-next-line
  return <>{children}</>;
}
