import PropTypes from "prop-types";
// material
import { Box, Typography, TextField } from "@mui/material";

// ----------------------------------------------------------------------

Label.propTypes = {
  title: PropTypes.string,
};

export function Label({ title }) {
  return (
    <Typography
      variant="overline"
      component="p"
      gutterBottom
      sx={{ color: "text.secondary" }}
    >
      {title}
    </Typography>
  );
}

Block.propTypes = {
  search: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
  open: PropTypes.bool,
  sx: PropTypes.object,
  handleChange: PropTypes.func,
};

export function Block({ title, sx, children, open, search, handleChange }) {
  return open ? (
    <Box sx={{ overflow: "unset", position: "unset", width: "100%", my: 1 }}>
      {title && (
        <Typography
          variant="caption"
          sx={{ fontWeight: 500, color: "text.disabled", ml: 2 }}
        >
          {title}
        </Typography>
      )}
      <TextField
        size="small"
        sx={{
          px: 2,
          mt: 1,
          fontSize: "14px",
          "& label": {
            fontSize: "12px",
            px: 2,
          },
        }}
        label="Search Lab"
        values={search}
        onChange={handleChange}
      />
      <Box
        sx={{
          p: 0,
          mt: 1,
          minHeight: 180,
          ...sx,
        }}
      >
        {children}
      </Box>
    </Box>
  ) : (
    <Box />
  );
}
