import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
import { API_PATH } from "../../utils/apis";

const initialState = {
  isLoading: false,
  error: false,
  logoutError: false,
  search: "",
  page: 0,
  perPage: 10,
  total: 0,
  devices: [],
  summary: {},
  sortBy: null,
  filters: {
    method: "",
  },
};

const slice = createSlice({
  name: "device",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    removeDeleteError(state) {
      state.deleteError = false;
    },
    hasLogoutError(state, action) {
      state.isLoading = false;
      state.logoutError = action.payload;
    },
    getSuccess(state, action) {
      state.isLoading = false;
      state.devices = action.payload.data;
      state.summary = action.payload.summary;
      state.total = parseInt(action.payload.total, 10);
    },
    logoutSuccess(state, action) {
      const deleted = action.payload.split(",");
      state.devices = state.devices.filter((obj) => !deleted.includes(obj.id));
      state.isLoading = false;
    },
    //  SORT & FILTER POSTS
    sortBy(state, action) {
      state.sortBy = action.payload;
    },
    filter(state, action) {
      state.filters.method = action.payload.method;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { filter, sortBy } = slice.actions;

export function getDevices(search, date, page, perpage, order, sortBy) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(API_PATH.devices, {
        page,
        search,
        perpage,
        date,
        order,
        sortBy,
      });
      const { status, data, summary, total } = response.data;
      if (status === "success") {
        dispatch(slice.actions.getSuccess({ data, total, summary }));
      } else {
        dispatch(
          slice.actions.hasError(
            new Error(
              "We cannot fetch devices at the moment! Please check back later."
            )
          )
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
