import axios from "./axios";

const setCurrentLab = (labId) => {
  if (labId) {
    localStorage.setItem("labId", labId);
  } else {
    localStorage.removeItem("labId");
  }
};
const getCurrentLab = () => window.localStorage.getItem("labId") || "";

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    setHeader();
  } else {
    localStorage.removeItem("accessToken");
  }
};
const setHeader = () => {
  const accessToken = window.localStorage.getItem("accessToken")
    ? window.localStorage.getItem("accessToken")
    : "";
  axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
};

export { setSession, setHeader, setCurrentLab, getCurrentLab };
