// material
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";

// ----------------------------------------------------------------------

export default function PageNotFoundIllustration({ ...other }) {
  const theme = useTheme();
  const PRIMARY_MAIN = theme.palette.primary.main;

  return (
    <Box {...other}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 500 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M500 382.4H0V382.65H500V382.4Z" fill="#EBEBEB" />
        <path d="M449.9 398.49H416.78V398.74H449.9V398.49Z" fill="#EBEBEB" />
        <path
          d="M331.22 401.211H322.53V401.461H331.22V401.211Z"
          fill="#EBEBEB"
        />
        <path
          d="M415.78 389.211H396.59V389.461H415.78V389.211Z"
          fill="#EBEBEB"
        />
        <path d="M95.65 390.891H52.46V391.141H95.65V390.891Z" fill="#EBEBEB" />
        <path
          d="M110.89 390.891H104.56V391.141H110.89V390.891Z"
          fill="#EBEBEB"
        />
        <path
          d="M225.15 395.109H131.47V395.359H225.15V395.109Z"
          fill="#EBEBEB"
        />
        <path
          d="M237 337.8H43.91C42.3973 337.797 40.9475 337.195 39.8788 336.124C38.8102 335.053 38.21 333.603 38.21 332.09V60.66C38.2231 59.156 38.8292 57.718 39.8964 56.6582C40.9637 55.5985 42.4059 55.0026 43.91 55H237C238.514 55 239.967 55.6016 241.038 56.6724C242.108 57.7433 242.71 59.1956 242.71 60.71V332.09C242.71 333.604 242.108 335.057 241.038 336.128C239.967 337.198 238.514 337.8 237 337.8ZM43.91 55.2C42.4636 55.2026 41.0774 55.7791 40.0556 56.8027C39.0338 57.8264 38.46 59.2136 38.46 60.66V332.09C38.46 333.536 39.0338 334.924 40.0556 335.947C41.0774 336.971 42.4636 337.547 43.91 337.55H237C238.447 337.547 239.834 336.971 240.858 335.948C241.881 334.924 242.457 333.537 242.46 332.09V60.66C242.457 59.2127 241.881 57.8255 240.858 56.8021C239.834 55.7787 238.447 55.2026 237 55.2H43.91Z"
          fill="#EBEBEB"
        />
        <path
          d="M453.31 337.8H260.21C258.696 337.797 257.246 337.195 256.175 336.125C255.105 335.054 254.503 333.604 254.5 332.09V60.66C254.516 59.1551 255.124 57.7171 256.193 56.6576C257.262 55.5981 258.705 55.0025 260.21 55H453.31C454.812 55.0052 456.252 55.6022 457.317 56.6617C458.382 57.7212 458.987 59.1578 459 60.66V332.09C459 333.601 458.401 335.05 457.335 336.121C456.268 337.191 454.821 337.795 453.31 337.8ZM260.21 55.2C258.763 55.2026 257.375 55.7787 256.352 56.8021C255.329 57.8255 254.753 59.2127 254.75 60.66V332.09C254.753 333.537 255.329 334.924 256.352 335.948C257.375 336.971 258.763 337.547 260.21 337.55H453.31C454.757 337.547 456.145 336.971 457.168 335.948C458.191 334.924 458.767 333.537 458.77 332.09V60.66C458.767 59.2127 458.191 57.8255 457.168 56.8021C456.145 55.7787 454.757 55.2026 453.31 55.2H260.21Z"
          fill="#EBEBEB"
        />
        <path
          d="M334.32 153.679H320.22V126.579L333.22 128.039L333.5 128.399L333.77 128.109L357.49 130.769L357.71 131.039L357.92 130.809L405.66 136.169V350.859H418.88V117.609H317.57V153.679H304.36V160.679H334.36L334.32 153.679ZM405.69 133.509L398.39 132.689L405.69 122.689V133.509ZM404.23 120.249L396.51 130.779L387.62 120.249H404.23ZM394.23 132.249L377.83 130.409L385.78 122.239L394.23 132.249ZM384 120.249L375.61 128.879L367.78 120.249H384ZM373 129.839L360.25 128.409L366 122.199L373 129.839ZM364.28 120.249L357.9 126.999L352.55 120.249H364.28ZM355.28 127.849L346.88 126.899L350.88 122.309L355.28 127.849ZM349.19 120.249L344.88 125.169L340.96 120.249H349.19ZM342.49 126.409L336.09 125.689L339.2 122.279L342.49 126.409ZM337.49 120.249L333.76 124.329L330.52 120.249H337.49ZM331.02 125.119L326.59 124.629L328.75 122.279L331.02 125.119ZM327.02 120.249L324.65 122.809L322.77 120.249H327.02ZM320.3 121.359L322.36 124.149L320.3 123.919V121.359Z"
          fill="#F0F0F0"
        />
        <path
          d="M347.54 155.44H344V334.32H389.82V150.15H386.3V166.89H368.68V158.08H366V166.89H347.5L347.54 155.44ZM368.68 171.3H386.31V181H368.68V171.3ZM347.54 171.3H366V181H347.5L347.54 171.3Z"
          fill="#F0F0F0"
        />
        <path
          d="M181.18 168V100H77V373.72H90.54V119.1L139.65 113.6L139.87 113.83L140.09 113.55L164.49 110.81L164.76 111.11L165.05 110.75L178.46 109.24V168H164V175.25H194.81V168H181.18ZM111 104.75L119.17 113.16L102.3 115.05L111 104.75ZM100 113.56L92.06 102.73H109.15L100 113.56ZM112.82 102.73H129.5L121.45 111.6L112.82 102.73ZM178.47 103.87V106.51L176.35 106.75L178.47 103.87ZM173.99 105.37L171.56 102.73H175.93L173.99 105.37ZM167.99 102.73L164.65 106.93L160.82 102.73H167.99ZM157.24 102.73L153.24 107.79L148.82 102.73H157.24ZM145.24 102.73L139.74 109.67L133.23 102.73H145.24ZM90.58 105.28L98.1 115.53L90.58 116.37V105.28ZM124.26 112.59L131.38 104.74L137.38 111.12L124.26 112.59ZM147 104.85L151.13 109.57L142.49 110.57L147 104.85ZM155.64 109.07L159.03 104.82L162.23 108.33L155.64 109.07ZM169.78 104.82L172.01 107.23L167.45 107.74L169.78 104.82Z"
          fill="#EBEBEB"
        />
        <path
          d="M150.36 181.579H131.36V172.519H128.64V181.579H110.48V164.359H106.86V353.779H154V169.779H150.37L150.36 181.579ZM128.61 196.079H110.48V186.079H128.61V196.079ZM150.36 196.079H131.36V186.079H150.36V196.079Z"
          fill="#EBEBEB"
        />
        <path
          d="M434.01 381.881V335.511H430.05V313.411H424.25V236.591H415.87V226.681L397.98 218.341V208.341H395.5V236.591H392.03V313.411H388.56V273.021H379.15V252.201H376.52V313.411H369.37V290.121H364.66V284.171H353.75V263.851H347.79V249.481H340.44V228.541H337.82V249.481H330.49V263.851H324.51V284.171H313.61V290.121H308.9V313.411H303.32V284.171H292.41V221.231H288.94V214.291H265.65V221.231H260.69V266.821H240.87V271.281H233.44V274.751H240.87V292.601H220.05V233.121H215.59V220.481H205.93V175.631H203.2V220.481H196.26V233.121H155.58V228.961H153.11V257.211H149.64V257.711H147.55V285.961H144.08V293.641H136.75V272.821H134.12V322.391H131.2V301.571H128.57V334.031H126.97V310.741H122.26V304.791H111.36V284.471H105.39V270.101H98.0501V249.161H95.4301V270.101H88.0901V284.471H82.1201V304.791H71.2101V310.741H66.5001V333.541H65.6601V334.031H63.3301V381.881H434.01Z"
          fill="#E0E0E0"
        />
        <path d="M181.09 235.9H178.28V238.71H181.09V235.9Z" fill="#F5F5F5" />
        <path
          d="M181.09 240.609H178.28V243.419H181.09V240.609Z"
          fill="#F5F5F5"
        />
        <path
          d="M181.09 259.439H178.28V262.249H181.09V259.439Z"
          fill="#F5F5F5"
        />
        <path d="M181.09 264.15H178.28V266.96H181.09V264.15Z" fill="#F5F5F5" />
        <path
          d="M181.09 268.859H178.28V271.669H181.09V268.859Z"
          fill="#F5F5F5"
        />
        <path d="M181.09 282.98H178.28V285.79H181.09V282.98Z" fill="#F5F5F5" />
        <path
          d="M181.09 287.689H178.28V290.499H181.09V287.689Z"
          fill="#F5F5F5"
        />
        <path d="M185.55 254.73H182.74V257.54H185.55V254.73Z" fill="#F5F5F5" />
        <path
          d="M185.55 259.439H182.74V262.249H185.55V259.439Z"
          fill="#F5F5F5"
        />
        <path d="M185.55 264.15H182.74V266.96H185.55V264.15Z" fill="#F5F5F5" />
        <path
          d="M185.55 268.859H182.74V271.669H185.55V268.859Z"
          fill="#F5F5F5"
        />
        <path d="M185.55 273.57H182.74V276.38H185.55V273.57Z" fill="#F5F5F5" />
        <path d="M185.55 282.98H182.74V285.79H185.55V282.98Z" fill="#F5F5F5" />
        <path
          d="M185.55 287.689H182.74V290.499H185.55V287.689Z"
          fill="#F5F5F5"
        />
        <path d="M190.01 235.9H187.2V238.71H190.01V235.9Z" fill="#F5F5F5" />
        <path
          d="M190.01 240.609H187.2V243.419H190.01V240.609Z"
          fill="#F5F5F5"
        />
        <path d="M190.01 245.32H187.2V248.13H190.01V245.32Z" fill="#F5F5F5" />
        <path
          d="M190.01 250.029H187.2V252.839H190.01V250.029Z"
          fill="#F5F5F5"
        />
        <path d="M190.01 254.73H187.2V257.54H190.01V254.73Z" fill="#F5F5F5" />
        <path
          d="M190.01 259.439H187.2V262.249H190.01V259.439Z"
          fill="#F5F5F5"
        />
        <path d="M190.01 264.15H187.2V266.96H190.01V264.15Z" fill="#F5F5F5" />
        <path
          d="M190.01 268.859H187.2V271.669H190.01V268.859Z"
          fill="#F5F5F5"
        />
        <path d="M190.01 273.57H187.2V276.38H190.01V273.57Z" fill="#F5F5F5" />
        <path
          d="M190.01 278.279H187.2V281.089H190.01V278.279Z"
          fill="#F5F5F5"
        />
        <path
          d="M190.01 287.689H187.2V290.499H190.01V287.689Z"
          fill="#F5F5F5"
        />
        <path d="M194.47 235.9H191.66V238.71H194.47V235.9Z" fill="#F5F5F5" />
        <path
          d="M194.47 240.609H191.66V243.419H194.47V240.609Z"
          fill="#F5F5F5"
        />
        <path d="M194.47 245.32H191.66V248.13H194.47V245.32Z" fill="#F5F5F5" />
        <path
          d="M194.47 250.029H191.66V252.839H194.47V250.029Z"
          fill="#F5F5F5"
        />
        <path d="M194.47 254.73H191.66V257.54H194.47V254.73Z" fill="#F5F5F5" />
        <path
          d="M194.47 259.439H191.66V262.249H194.47V259.439Z"
          fill="#F5F5F5"
        />
        <path d="M194.47 264.15H191.66V266.96H194.47V264.15Z" fill="#F5F5F5" />
        <path
          d="M194.47 306.529H191.66V309.339H194.47V306.529Z"
          fill="#F5F5F5"
        />
        <path d="M198.93 235.9H196.12V238.71H198.93V235.9Z" fill="#F5F5F5" />
        <path
          d="M198.93 240.609H196.12V243.419H198.93V240.609Z"
          fill="#F5F5F5"
        />
        <path d="M198.93 245.32H196.12V248.13H198.93V245.32Z" fill="#F5F5F5" />
        <path
          d="M198.93 250.029H196.12V252.839H198.93V250.029Z"
          fill="#F5F5F5"
        />
        <path d="M198.93 254.73H196.12V257.54H198.93V254.73Z" fill="#F5F5F5" />
        <path
          d="M198.93 259.439H196.12V262.249H198.93V259.439Z"
          fill="#F5F5F5"
        />
        <path d="M198.93 264.15H196.12V266.96H198.93V264.15Z" fill="#F5F5F5" />
        <path
          d="M198.93 268.859H196.12V271.669H198.93V268.859Z"
          fill="#F5F5F5"
        />
        <path
          d="M198.93 297.109H196.12V299.919H198.93V297.109Z"
          fill="#F5F5F5"
        />
        <path d="M198.93 301.82H196.12V304.63H198.93V301.82Z" fill="#F5F5F5" />
        <path
          d="M198.93 306.529H196.12V309.339H198.93V306.529Z"
          fill="#F5F5F5"
        />
        <path d="M198.93 311.23H196.12V314.04H198.93V311.23Z" fill="#F5F5F5" />
        <path d="M198.93 330.07H196.12V332.88H198.93V330.07Z" fill="#F5F5F5" />
        <path d="M203.39 235.9H200.58V238.71H203.39V235.9Z" fill="#F5F5F5" />
        <path
          d="M203.39 240.609H200.58V243.419H203.39V240.609Z"
          fill="#F5F5F5"
        />
        <path d="M203.39 245.32H200.58V248.13H203.39V245.32Z" fill="#F5F5F5" />
        <path
          d="M203.39 250.029H200.58V252.839H203.39V250.029Z"
          fill="#F5F5F5"
        />
        <path d="M203.39 254.73H200.58V257.54H203.39V254.73Z" fill="#F5F5F5" />
        <path
          d="M203.39 259.439H200.58V262.249H203.39V259.439Z"
          fill="#F5F5F5"
        />
        <path d="M203.39 264.15H200.58V266.96H203.39V264.15Z" fill="#F5F5F5" />
        <path
          d="M203.39 268.859H200.58V271.669H203.39V268.859Z"
          fill="#F5F5F5"
        />
        <path d="M203.39 273.57H200.58V276.38H203.39V273.57Z" fill="#F5F5F5" />
        <path
          d="M203.39 278.279H200.58V281.089H203.39V278.279Z"
          fill="#F5F5F5"
        />
        <path d="M203.39 292.4H200.58V295.21H203.39V292.4Z" fill="#F5F5F5" />
        <path
          d="M203.39 297.109H200.58V299.919H203.39V297.109Z"
          fill="#F5F5F5"
        />
        <path d="M203.39 301.82H200.58V304.63H203.39V301.82Z" fill="#F5F5F5" />
        <path
          d="M203.39 306.529H200.58V309.339H203.39V306.529Z"
          fill="#F5F5F5"
        />
        <path d="M203.39 311.23H200.58V314.04H203.39V311.23Z" fill="#F5F5F5" />
        <path d="M203.39 330.07H200.58V332.88H203.39V330.07Z" fill="#F5F5F5" />
        <path d="M207.86 235.9H205.05V238.71H207.86V235.9Z" fill="#F5F5F5" />
        <path
          d="M207.86 240.609H205.05V243.419H207.86V240.609Z"
          fill="#F5F5F5"
        />
        <path d="M207.86 245.32H205.05V248.13H207.86V245.32Z" fill="#F5F5F5" />
        <path
          d="M207.86 250.029H205.05V252.839H207.86V250.029Z"
          fill="#F5F5F5"
        />
        <path d="M207.86 254.73H205.05V257.54H207.86V254.73Z" fill="#F5F5F5" />
        <path
          d="M207.86 259.439H205.05V262.249H207.86V259.439Z"
          fill="#F5F5F5"
        />
        <path d="M207.86 264.15H205.05V266.96H207.86V264.15Z" fill="#F5F5F5" />
        <path
          d="M207.86 268.859H205.05V271.669H207.86V268.859Z"
          fill="#F5F5F5"
        />
        <path d="M207.86 273.57H205.05V276.38H207.86V273.57Z" fill="#F5F5F5" />
        <path
          d="M207.86 278.279H205.05V281.089H207.86V278.279Z"
          fill="#F5F5F5"
        />
        <path d="M207.86 282.98H205.05V285.79H207.86V282.98Z" fill="#F5F5F5" />
        <path
          d="M207.86 287.689H205.05V290.499H207.86V287.689Z"
          fill="#F5F5F5"
        />
        <path d="M207.86 292.4H205.05V295.21H207.86V292.4Z" fill="#F5F5F5" />
        <path
          d="M207.86 297.109H205.05V299.919H207.86V297.109Z"
          fill="#F5F5F5"
        />
        <path d="M207.86 301.82H205.05V304.63H207.86V301.82Z" fill="#F5F5F5" />
        <path
          d="M207.86 306.529H205.05V309.339H207.86V306.529Z"
          fill="#F5F5F5"
        />
        <path d="M207.86 311.23H205.05V314.04H207.86V311.23Z" fill="#F5F5F5" />
        <path
          d="M207.86 315.939H205.05V318.749H207.86V315.939Z"
          fill="#F5F5F5"
        />
        <path d="M212.32 235.9H209.51V238.71H212.32V235.9Z" fill="#F5F5F5" />
        <path
          d="M212.32 240.609H209.51V243.419H212.32V240.609Z"
          fill="#F5F5F5"
        />
        <path d="M212.32 245.32H209.51V248.13H212.32V245.32Z" fill="#F5F5F5" />
        <path
          d="M212.32 250.029H209.51V252.839H212.32V250.029Z"
          fill="#F5F5F5"
        />
        <path d="M212.32 254.73H209.51V257.54H212.32V254.73Z" fill="#F5F5F5" />
        <path
          d="M212.32 259.439H209.51V262.249H212.32V259.439Z"
          fill="#F5F5F5"
        />
        <path d="M212.32 264.15H209.51V266.96H212.32V264.15Z" fill="#F5F5F5" />
        <path
          d="M212.32 268.859H209.51V271.669H212.32V268.859Z"
          fill="#F5F5F5"
        />
        <path d="M212.32 273.57H209.51V276.38H212.32V273.57Z" fill="#F5F5F5" />
        <path
          d="M212.32 278.279H209.51V281.089H212.32V278.279Z"
          fill="#F5F5F5"
        />
        <path d="M212.32 292.4H209.51V295.21H212.32V292.4Z" fill="#F5F5F5" />
        <path
          d="M212.32 297.109H209.51V299.919H212.32V297.109Z"
          fill="#F5F5F5"
        />
        <path d="M212.32 301.82H209.51V304.63H212.32V301.82Z" fill="#F5F5F5" />
        <path
          d="M212.32 306.529H209.51V309.339H212.32V306.529Z"
          fill="#F5F5F5"
        />
        <path d="M216.78 235.9H213.97V238.71H216.78V235.9Z" fill="#F5F5F5" />
        <path
          d="M216.78 240.609H213.97V243.419H216.78V240.609Z"
          fill="#F5F5F5"
        />
        <path d="M216.78 245.32H213.97V248.13H216.78V245.32Z" fill="#F5F5F5" />
        <path
          d="M216.78 250.029H213.97V252.839H216.78V250.029Z"
          fill="#F5F5F5"
        />
        <path d="M216.78 254.73H213.97V257.54H216.78V254.73Z" fill="#F5F5F5" />
        <path
          d="M216.78 259.439H213.97V262.249H216.78V259.439Z"
          fill="#F5F5F5"
        />
        <path d="M216.78 264.15H213.97V266.96H216.78V264.15Z" fill="#F5F5F5" />
        <path
          d="M216.78 268.859H213.97V271.669H216.78V268.859Z"
          fill="#F5F5F5"
        />
        <path d="M216.78 273.57H213.97V276.38H216.78V273.57Z" fill="#F5F5F5" />
        <path
          d="M216.78 278.279H213.97V281.089H216.78V278.279Z"
          fill="#F5F5F5"
        />
        <path d="M216.78 292.4H213.97V295.21H216.78V292.4Z" fill="#F5F5F5" />
        <path
          d="M216.78 297.109H213.97V299.919H216.78V297.109Z"
          fill="#F5F5F5"
        />
        <path d="M216.78 301.82H213.97V304.63H216.78V301.82Z" fill="#F5F5F5" />
        <path d="M267 223.67H264.19V226.48H267V223.67Z" fill="#F5F5F5" />
        <path d="M267 228.381H264.19V231.191H267V228.381Z" fill="#F5F5F5" />
        <path d="M267 233.09H264.19V235.9H267V233.09Z" fill="#F5F5F5" />
        <path d="M267 237.801H264.19V240.611H267V237.801Z" fill="#F5F5F5" />
        <path d="M267 242.51H264.19V245.32H267V242.51Z" fill="#F5F5F5" />
        <path d="M267 247.221H264.19V250.031H267V247.221Z" fill="#F5F5F5" />
        <path d="M267 251.93H264.19V254.74H267V251.93Z" fill="#F5F5F5" />
        <path d="M267 256.631H264.19V259.441H267V256.631Z" fill="#F5F5F5" />
        <path d="M267 270.76H264.19V273.57H267V270.76Z" fill="#F5F5F5" />
        <path d="M267 275.471H264.19V278.281H267V275.471Z" fill="#F5F5F5" />
        <path d="M271.46 223.67H268.65V226.48H271.46V223.67Z" fill="#F5F5F5" />
        <path
          d="M271.46 228.381H268.65V231.191H271.46V228.381Z"
          fill="#F5F5F5"
        />
        <path d="M271.46 233.09H268.65V235.9H271.46V233.09Z" fill="#F5F5F5" />
        <path
          d="M271.46 237.801H268.65V240.611H271.46V237.801Z"
          fill="#F5F5F5"
        />
        <path d="M271.46 242.51H268.65V245.32H271.46V242.51Z" fill="#F5F5F5" />
        <path
          d="M271.46 247.221H268.65V250.031H271.46V247.221Z"
          fill="#F5F5F5"
        />
        <path d="M271.46 251.93H268.65V254.74H271.46V251.93Z" fill="#F5F5F5" />
        <path
          d="M271.46 256.631H268.65V259.441H271.46V256.631Z"
          fill="#F5F5F5"
        />
        <path
          d="M271.46 275.471H268.65V278.281H271.46V275.471Z"
          fill="#F5F5F5"
        />
        <path d="M275.92 223.67H273.11V226.48H275.92V223.67Z" fill="#F5F5F5" />
        <path
          d="M275.92 228.381H273.11V231.191H275.92V228.381Z"
          fill="#F5F5F5"
        />
        <path d="M275.92 233.09H273.11V235.9H275.92V233.09Z" fill="#F5F5F5" />
        <path
          d="M275.92 237.801H273.11V240.611H275.92V237.801Z"
          fill="#F5F5F5"
        />
        <path d="M275.92 242.51H273.11V245.32H275.92V242.51Z" fill="#F5F5F5" />
        <path
          d="M275.92 247.221H273.11V250.031H275.92V247.221Z"
          fill="#F5F5F5"
        />
        <path d="M275.92 251.93H273.11V254.74H275.92V251.93Z" fill="#F5F5F5" />
        <path
          d="M275.92 256.631H273.11V259.441H275.92V256.631Z"
          fill="#F5F5F5"
        />
        <path d="M280.38 223.67H277.57V226.48H280.38V223.67Z" fill="#F5F5F5" />
        <path
          d="M280.38 228.381H277.57V231.191H280.38V228.381Z"
          fill="#F5F5F5"
        />
        <path d="M280.38 233.09H277.57V235.9H280.38V233.09Z" fill="#F5F5F5" />
        <path
          d="M280.38 237.801H277.57V240.611H280.38V237.801Z"
          fill="#F5F5F5"
        />
        <path d="M280.38 242.51H277.57V245.32H280.38V242.51Z" fill="#F5F5F5" />
        <path
          d="M280.38 247.221H277.57V250.031H280.38V247.221Z"
          fill="#F5F5F5"
        />
        <path
          d="M280.38 256.631H277.57V259.441H280.38V256.631Z"
          fill="#F5F5F5"
        />
        <path d="M280.38 261.34H277.57V264.15H280.38V261.34Z" fill="#F5F5F5" />
        <path
          d="M280.38 275.471H277.57V278.281H280.38V275.471Z"
          fill="#F5F5F5"
        />
        <path d="M280.38 280.18H277.57V282.99H280.38V280.18Z" fill="#F5F5F5" />
        <path
          d="M280.38 284.881H277.57V287.691H280.38V284.881Z"
          fill="#F5F5F5"
        />
        <path d="M280.38 289.59H277.57V292.4H280.38V289.59Z" fill="#F5F5F5" />
        <path
          d="M280.38 294.301H277.57V297.111H280.38V294.301Z"
          fill="#F5F5F5"
        />
        <path d="M280.38 299.01H277.57V301.82H280.38V299.01Z" fill="#F5F5F5" />
        <path
          d="M280.38 303.721H277.57V306.531H280.38V303.721Z"
          fill="#F5F5F5"
        />
        <path d="M284.84 223.67H282.03V226.48H284.84V223.67Z" fill="#F5F5F5" />
        <path
          d="M284.84 228.381H282.03V231.191H284.84V228.381Z"
          fill="#F5F5F5"
        />
        <path d="M284.84 233.09H282.03V235.9H284.84V233.09Z" fill="#F5F5F5" />
        <path
          d="M284.84 237.801H282.03V240.611H284.84V237.801Z"
          fill="#F5F5F5"
        />
        <path d="M284.84 242.51H282.03V245.32H284.84V242.51Z" fill="#F5F5F5" />
        <path
          d="M284.84 247.221H282.03V250.031H284.84V247.221Z"
          fill="#F5F5F5"
        />
        <path d="M284.84 261.34H282.03V264.15H284.84V261.34Z" fill="#F5F5F5" />
        <path
          d="M284.84 266.051H282.03V268.861H284.84V266.051Z"
          fill="#F5F5F5"
        />
        <path d="M284.84 270.76H282.03V273.57H284.84V270.76Z" fill="#F5F5F5" />
        <path
          d="M284.84 275.471H282.03V278.281H284.84V275.471Z"
          fill="#F5F5F5"
        />
        <path d="M284.84 280.18H282.03V282.99H284.84V280.18Z" fill="#F5F5F5" />
        <path
          d="M284.84 284.881H282.03V287.691H284.84V284.881Z"
          fill="#F5F5F5"
        />
        <path d="M284.84 289.59H282.03V292.4H284.84V289.59Z" fill="#F5F5F5" />
        <path
          d="M284.84 294.301H282.03V297.111H284.84V294.301Z"
          fill="#F5F5F5"
        />
        <path d="M284.84 299.01H282.03V301.82H284.84V299.01Z" fill="#F5F5F5" />
        <path
          d="M284.84 303.721H282.03V306.531H284.84V303.721Z"
          fill="#F5F5F5"
        />
        <path d="M284.84 308.43H282.03V311.24H284.84V308.43Z" fill="#F5F5F5" />
        <path d="M284.84 317.84H282.03V320.65H284.84V317.84Z" fill="#F5F5F5" />
        <path d="M289.3 223.67H286.49V226.48H289.3V223.67Z" fill="#F5F5F5" />
        <path d="M289.3 228.381H286.49V231.191H289.3V228.381Z" fill="#F5F5F5" />
        <path d="M289.3 233.09H286.49V235.9H289.3V233.09Z" fill="#F5F5F5" />
        <path d="M289.3 237.801H286.49V240.611H289.3V237.801Z" fill="#F5F5F5" />
        <path d="M289.3 242.51H286.49V245.32H289.3V242.51Z" fill="#F5F5F5" />
        <path d="M289.3 247.221H286.49V250.031H289.3V247.221Z" fill="#F5F5F5" />
        <path d="M289.3 261.34H286.49V264.15H289.3V261.34Z" fill="#F5F5F5" />
        <path d="M289.3 266.051H286.49V268.861H289.3V266.051Z" fill="#F5F5F5" />
        <path d="M289.3 270.76H286.49V273.57H289.3V270.76Z" fill="#F5F5F5" />
        <path d="M289.3 275.471H286.49V278.281H289.3V275.471Z" fill="#F5F5F5" />
        <path d="M289.3 289.59H286.49V292.4H289.3V289.59Z" fill="#F5F5F5" />
        <path d="M289.3 294.301H286.49V297.111H289.3V294.301Z" fill="#F5F5F5" />
        <path d="M289.3 299.01H286.49V301.82H289.3V299.01Z" fill="#F5F5F5" />
        <path d="M289.3 303.721H286.49V306.531H289.3V303.721Z" fill="#F5F5F5" />
        <path d="M289.3 308.43H286.49V311.24H289.3V308.43Z" fill="#F5F5F5" />
        <path d="M289.3 317.84H286.49V320.65H289.3V317.84Z" fill="#F5F5F5" />
        <path d="M289.3 322.551H286.49V325.361H289.3V322.551Z" fill="#F5F5F5" />
        <path
          d="M329.78 267.449H326.97V270.259H329.78V267.449Z"
          fill="#F5F5F5"
        />
        <path d="M329.78 272.16H326.97V274.97H329.78V272.16Z" fill="#F5F5F5" />
        <path
          d="M329.78 276.869H326.97V279.679H329.78V276.869Z"
          fill="#F5F5F5"
        />
        <path d="M329.78 281.58H326.97V284.39H329.78V281.58Z" fill="#F5F5F5" />
        <path
          d="M329.78 286.289H326.97V289.099H329.78V286.289Z"
          fill="#F5F5F5"
        />
        <path d="M329.78 291H326.97V293.81H329.78V291Z" fill="#F5F5F5" />
        <path
          d="M329.78 295.711H326.97V298.521H329.78V295.711Z"
          fill="#F5F5F5"
        />
        <path d="M329.78 309.83H326.97V312.64H329.78V309.83Z" fill="#F5F5F5" />
        <path
          d="M329.78 314.539H326.97V317.349H329.78V314.539Z"
          fill="#F5F5F5"
        />
        <path d="M329.78 319.25H326.97V322.06H329.78V319.25Z" fill="#F5F5F5" />
        <path
          d="M334.24 267.449H331.43V270.259H334.24V267.449Z"
          fill="#F5F5F5"
        />
        <path d="M334.24 272.16H331.43V274.97H334.24V272.16Z" fill="#F5F5F5" />
        <path
          d="M334.24 276.869H331.43V279.679H334.24V276.869Z"
          fill="#F5F5F5"
        />
        <path d="M334.24 281.58H331.43V284.39H334.24V281.58Z" fill="#F5F5F5" />
        <path
          d="M334.24 286.289H331.43V289.099H334.24V286.289Z"
          fill="#F5F5F5"
        />
        <path d="M334.24 291H331.43V293.81H334.24V291Z" fill="#F5F5F5" />
        <path
          d="M334.24 295.711H331.43V298.521H334.24V295.711Z"
          fill="#F5F5F5"
        />
        <path d="M338.7 267.449H335.89V270.259H338.7V267.449Z" fill="#F5F5F5" />
        <path d="M338.7 272.16H335.89V274.97H338.7V272.16Z" fill="#F5F5F5" />
        <path d="M338.7 276.869H335.89V279.679H338.7V276.869Z" fill="#F5F5F5" />
        <path d="M338.7 281.58H335.89V284.39H338.7V281.58Z" fill="#F5F5F5" />
        <path d="M338.7 286.289H335.89V289.099H338.7V286.289Z" fill="#F5F5F5" />
        <path d="M338.7 291H335.89V293.81H338.7V291Z" fill="#F5F5F5" />
        <path d="M338.7 295.711H335.89V298.521H338.7V295.711Z" fill="#F5F5F5" />
        <path
          d="M343.16 267.449H340.35V270.259H343.16V267.449Z"
          fill="#F5F5F5"
        />
        <path d="M343.16 272.16H340.35V274.97H343.16V272.16Z" fill="#F5F5F5" />
        <path
          d="M343.16 276.869H340.35V279.679H343.16V276.869Z"
          fill="#F5F5F5"
        />
        <path d="M343.16 281.58H340.35V284.39H343.16V281.58Z" fill="#F5F5F5" />
        <path
          d="M343.16 286.289H340.35V289.099H343.16V286.289Z"
          fill="#F5F5F5"
        />
        <path d="M343.16 291H340.35V293.81H343.16V291Z" fill="#F5F5F5" />
        <path
          d="M343.16 295.711H340.35V298.521H343.16V295.711Z"
          fill="#F5F5F5"
        />
        <path d="M343.16 300.41H340.35V303.22H343.16V300.41Z" fill="#F5F5F5" />
        <path
          d="M343.16 305.119H340.35V307.929H343.16V305.119Z"
          fill="#F5F5F5"
        />
        <path d="M343.16 309.83H340.35V312.64H343.16V309.83Z" fill="#F5F5F5" />
        <path
          d="M343.16 314.539H340.35V317.349H343.16V314.539Z"
          fill="#F5F5F5"
        />
        <path
          d="M347.62 267.449H344.81V270.259H347.62V267.449Z"
          fill="#F5F5F5"
        />
        <path d="M347.62 272.16H344.81V274.97H347.62V272.16Z" fill="#F5F5F5" />
        <path
          d="M347.62 276.869H344.81V279.679H347.62V276.869Z"
          fill="#F5F5F5"
        />
        <path d="M347.62 281.58H344.81V284.39H347.62V281.58Z" fill="#F5F5F5" />
        <path
          d="M347.62 286.289H344.81V289.099H347.62V286.289Z"
          fill="#F5F5F5"
        />
        <path d="M347.62 291H344.81V293.81H347.62V291Z" fill="#F5F5F5" />
        <path
          d="M347.62 295.711H344.81V298.521H347.62V295.711Z"
          fill="#F5F5F5"
        />
        <path d="M347.62 300.41H344.81V303.22H347.62V300.41Z" fill="#F5F5F5" />
        <path
          d="M347.62 305.119H344.81V307.929H347.62V305.119Z"
          fill="#F5F5F5"
        />
        <path d="M347.62 309.83H344.81V312.64H347.62V309.83Z" fill="#F5F5F5" />
        <path
          d="M347.62 314.539H344.81V317.349H347.62V314.539Z"
          fill="#F5F5F5"
        />
        <path d="M347.62 319.25H344.81V322.06H347.62V319.25Z" fill="#F5F5F5" />
        <path
          d="M347.62 333.369H344.81V336.179H347.62V333.369Z"
          fill="#F5F5F5"
        />
        <path d="M347.62 338.08H344.81V340.89H347.62V338.08Z" fill="#F5F5F5" />
        <path
          d="M352.08 267.449H349.27V270.259H352.08V267.449Z"
          fill="#F5F5F5"
        />
        <path d="M352.08 272.16H349.27V274.97H352.08V272.16Z" fill="#F5F5F5" />
        <path
          d="M352.08 276.869H349.27V279.679H352.08V276.869Z"
          fill="#F5F5F5"
        />
        <path d="M352.08 291H349.27V293.81H352.08V291Z" fill="#F5F5F5" />
        <path
          d="M352.08 295.711H349.27V298.521H352.08V295.711Z"
          fill="#F5F5F5"
        />
        <path d="M352.08 300.41H349.27V303.22H352.08V300.41Z" fill="#F5F5F5" />
        <path
          d="M352.08 305.119H349.27V307.929H352.08V305.119Z"
          fill="#F5F5F5"
        />
        <path d="M352.08 309.83H349.27V312.64H352.08V309.83Z" fill="#F5F5F5" />
        <path
          d="M352.08 314.539H349.27V317.349H352.08V314.539Z"
          fill="#F5F5F5"
        />
        <path d="M352.08 319.25H349.27V322.06H352.08V319.25Z" fill="#F5F5F5" />
        <path
          d="M352.08 333.369H349.27V336.179H352.08V333.369Z"
          fill="#F5F5F5"
        />
        <path d="M352.08 338.08H349.27V340.89H352.08V338.08Z" fill="#F5F5F5" />
        <path
          d="M352.08 342.789H349.27V345.599H352.08V342.789Z"
          fill="#F5F5F5"
        />
        <path
          d="M398.83 239.199H396.02V242.009H398.83V239.199Z"
          fill="#F5F5F5"
        />
        <path d="M398.83 243.91H396.02V246.72H398.83V243.91Z" fill="#F5F5F5" />
        <path
          d="M398.83 248.619H396.02V251.429H398.83V248.619Z"
          fill="#F5F5F5"
        />
        <path d="M398.83 253.33H396.02V256.14H398.83V253.33Z" fill="#F5F5F5" />
        <path
          d="M398.83 258.039H396.02V260.849H398.83V258.039Z"
          fill="#F5F5F5"
        />
        <path d="M398.83 262.75H396.02V265.56H398.83V262.75Z" fill="#F5F5F5" />
        <path
          d="M398.83 267.449H396.02V270.259H398.83V267.449Z"
          fill="#F5F5F5"
        />
        <path d="M398.83 272.16H396.02V274.97H398.83V272.16Z" fill="#F5F5F5" />
        <path
          d="M398.83 276.869H396.02V279.679H398.83V276.869Z"
          fill="#F5F5F5"
        />
        <path d="M398.83 281.58H396.02V284.39H398.83V281.58Z" fill="#F5F5F5" />
        <path
          d="M398.83 286.289H396.02V289.099H398.83V286.289Z"
          fill="#F5F5F5"
        />
        <path d="M398.83 291H396.02V293.81H398.83V291Z" fill="#F5F5F5" />
        <path
          d="M398.83 295.711H396.02V298.521H398.83V295.711Z"
          fill="#F5F5F5"
        />
        <path d="M398.83 300.41H396.02V303.22H398.83V300.41Z" fill="#F5F5F5" />
        <path
          d="M398.83 305.119H396.02V307.929H398.83V305.119Z"
          fill="#F5F5F5"
        />
        <path d="M398.83 309.83H396.02V312.64H398.83V309.83Z" fill="#F5F5F5" />
        <path
          d="M398.83 314.539H396.02V317.349H398.83V314.539Z"
          fill="#F5F5F5"
        />
        <path d="M398.83 319.25H396.02V322.06H398.83V319.25Z" fill="#F5F5F5" />
        <path
          d="M398.83 323.961H396.02V326.771H398.83V323.961Z"
          fill="#F5F5F5"
        />
        <path d="M398.83 328.66H396.02V331.47H398.83V328.66Z" fill="#F5F5F5" />
        <path
          d="M398.83 333.369H396.02V336.179H398.83V333.369Z"
          fill="#F5F5F5"
        />
        <path d="M398.83 338.08H396.02V340.89H398.83V338.08Z" fill="#F5F5F5" />
        <path
          d="M398.83 342.789H396.02V345.599H398.83V342.789Z"
          fill="#F5F5F5"
        />
        <path
          d="M403.29 239.199H400.48V242.009H403.29V239.199Z"
          fill="#F5F5F5"
        />
        <path d="M403.29 243.91H400.48V246.72H403.29V243.91Z" fill="#F5F5F5" />
        <path
          d="M403.29 248.619H400.48V251.429H403.29V248.619Z"
          fill="#F5F5F5"
        />
        <path d="M403.29 253.33H400.48V256.14H403.29V253.33Z" fill="#F5F5F5" />
        <path
          d="M403.29 258.039H400.48V260.849H403.29V258.039Z"
          fill="#F5F5F5"
        />
        <path d="M403.29 262.75H400.48V265.56H403.29V262.75Z" fill="#F5F5F5" />
        <path
          d="M403.29 267.449H400.48V270.259H403.29V267.449Z"
          fill="#F5F5F5"
        />
        <path d="M403.29 272.16H400.48V274.97H403.29V272.16Z" fill="#F5F5F5" />
        <path
          d="M403.29 276.869H400.48V279.679H403.29V276.869Z"
          fill="#F5F5F5"
        />
        <path d="M403.29 281.58H400.48V284.39H403.29V281.58Z" fill="#F5F5F5" />
        <path
          d="M403.29 286.289H400.48V289.099H403.29V286.289Z"
          fill="#F5F5F5"
        />
        <path d="M403.29 291H400.48V293.81H403.29V291Z" fill="#F5F5F5" />
        <path
          d="M403.29 295.711H400.48V298.521H403.29V295.711Z"
          fill="#F5F5F5"
        />
        <path d="M403.29 300.41H400.48V303.22H403.29V300.41Z" fill="#F5F5F5" />
        <path
          d="M403.29 305.119H400.48V307.929H403.29V305.119Z"
          fill="#F5F5F5"
        />
        <path d="M403.29 309.83H400.48V312.64H403.29V309.83Z" fill="#F5F5F5" />
        <path
          d="M403.29 314.539H400.48V317.349H403.29V314.539Z"
          fill="#F5F5F5"
        />
        <path d="M403.29 319.25H400.48V322.06H403.29V319.25Z" fill="#F5F5F5" />
        <path
          d="M403.29 323.961H400.48V326.771H403.29V323.961Z"
          fill="#F5F5F5"
        />
        <path
          d="M407.76 239.199H404.95V242.009H407.76V239.199Z"
          fill="#F5F5F5"
        />
        <path d="M407.76 243.91H404.95V246.72H407.76V243.91Z" fill="#F5F5F5" />
        <path
          d="M407.76 248.619H404.95V251.429H407.76V248.619Z"
          fill="#F5F5F5"
        />
        <path d="M407.76 253.33H404.95V256.14H407.76V253.33Z" fill="#F5F5F5" />
        <path
          d="M407.76 258.039H404.95V260.849H407.76V258.039Z"
          fill="#F5F5F5"
        />
        <path d="M407.76 262.75H404.95V265.56H407.76V262.75Z" fill="#F5F5F5" />
        <path
          d="M407.76 267.449H404.95V270.259H407.76V267.449Z"
          fill="#F5F5F5"
        />
        <path d="M407.76 272.16H404.95V274.97H407.76V272.16Z" fill="#F5F5F5" />
        <path
          d="M407.76 276.869H404.95V279.679H407.76V276.869Z"
          fill="#F5F5F5"
        />
        <path d="M407.76 281.58H404.95V284.39H407.76V281.58Z" fill="#F5F5F5" />
        <path
          d="M407.76 286.289H404.95V289.099H407.76V286.289Z"
          fill="#F5F5F5"
        />
        <path d="M407.76 291H404.95V293.81H407.76V291Z" fill="#F5F5F5" />
        <path
          d="M407.76 295.711H404.95V298.521H407.76V295.711Z"
          fill="#F5F5F5"
        />
        <path d="M407.76 300.41H404.95V303.22H407.76V300.41Z" fill="#F5F5F5" />
        <path
          d="M407.76 305.119H404.95V307.929H407.76V305.119Z"
          fill="#F5F5F5"
        />
        <path d="M407.76 309.83H404.95V312.64H407.76V309.83Z" fill="#F5F5F5" />
        <path
          d="M407.76 314.539H404.95V317.349H407.76V314.539Z"
          fill="#F5F5F5"
        />
        <path d="M407.76 319.25H404.95V322.06H407.76V319.25Z" fill="#F5F5F5" />
        <path
          d="M407.76 323.961H404.95V326.771H407.76V323.961Z"
          fill="#F5F5F5"
        />
        <path
          d="M412.22 239.199H409.41V242.009H412.22V239.199Z"
          fill="#F5F5F5"
        />
        <path d="M412.22 243.91H409.41V246.72H412.22V243.91Z" fill="#F5F5F5" />
        <path
          d="M412.22 248.619H409.41V251.429H412.22V248.619Z"
          fill="#F5F5F5"
        />
        <path d="M412.22 253.33H409.41V256.14H412.22V253.33Z" fill="#F5F5F5" />
        <path
          d="M412.22 258.039H409.41V260.849H412.22V258.039Z"
          fill="#F5F5F5"
        />
        <path d="M412.22 262.75H409.41V265.56H412.22V262.75Z" fill="#F5F5F5" />
        <path
          d="M412.22 267.449H409.41V270.259H412.22V267.449Z"
          fill="#F5F5F5"
        />
        <path d="M412.22 272.16H409.41V274.97H412.22V272.16Z" fill="#F5F5F5" />
        <path
          d="M412.22 276.869H409.41V279.679H412.22V276.869Z"
          fill="#F5F5F5"
        />
        <path d="M412.22 281.58H409.41V284.39H412.22V281.58Z" fill="#F5F5F5" />
        <path
          d="M412.22 286.289H409.41V289.099H412.22V286.289Z"
          fill="#F5F5F5"
        />
        <path d="M412.22 291H409.41V293.81H412.22V291Z" fill="#F5F5F5" />
        <path
          d="M412.22 295.711H409.41V298.521H412.22V295.711Z"
          fill="#F5F5F5"
        />
        <path d="M412.22 300.41H409.41V303.22H412.22V300.41Z" fill="#F5F5F5" />
        <path
          d="M412.22 305.119H409.41V307.929H412.22V305.119Z"
          fill="#F5F5F5"
        />
        <path
          d="M416.68 239.199H413.87V242.009H416.68V239.199Z"
          fill="#F5F5F5"
        />
        <path d="M416.68 243.91H413.87V246.72H416.68V243.91Z" fill="#F5F5F5" />
        <path
          d="M416.68 248.619H413.87V251.429H416.68V248.619Z"
          fill="#F5F5F5"
        />
        <path d="M416.68 253.33H413.87V256.14H416.68V253.33Z" fill="#F5F5F5" />
        <path
          d="M416.68 258.039H413.87V260.849H416.68V258.039Z"
          fill="#F5F5F5"
        />
        <path d="M416.68 262.75H413.87V265.56H416.68V262.75Z" fill="#F5F5F5" />
        <path
          d="M416.68 267.449H413.87V270.259H416.68V267.449Z"
          fill="#F5F5F5"
        />
        <path d="M416.68 272.16H413.87V274.97H416.68V272.16Z" fill="#F5F5F5" />
        <path
          d="M416.68 276.869H413.87V279.679H416.68V276.869Z"
          fill="#F5F5F5"
        />
        <path d="M416.68 281.58H413.87V284.39H416.68V281.58Z" fill="#F5F5F5" />
        <path
          d="M416.68 286.289H413.87V289.099H416.68V286.289Z"
          fill="#F5F5F5"
        />
        <path d="M416.68 291H413.87V293.81H416.68V291Z" fill="#F5F5F5" />
        <path
          d="M416.68 295.711H413.87V298.521H416.68V295.711Z"
          fill="#F5F5F5"
        />
        <path
          d="M421.14 239.199H418.33V242.009H421.14V239.199Z"
          fill="#F5F5F5"
        />
        <path d="M421.14 243.91H418.33V246.72H421.14V243.91Z" fill="#F5F5F5" />
        <path
          d="M421.14 248.619H418.33V251.429H421.14V248.619Z"
          fill="#F5F5F5"
        />
        <path d="M421.14 253.33H418.33V256.14H421.14V253.33Z" fill="#F5F5F5" />
        <path
          d="M421.14 258.039H418.33V260.849H421.14V258.039Z"
          fill="#F5F5F5"
        />
        <path d="M421.14 262.75H418.33V265.56H421.14V262.75Z" fill="#F5F5F5" />
        <path
          d="M421.14 267.449H418.33V270.259H421.14V267.449Z"
          fill="#F5F5F5"
        />
        <path d="M421.14 272.16H418.33V274.97H421.14V272.16Z" fill="#F5F5F5" />
        <path
          d="M421.14 276.869H418.33V279.679H421.14V276.869Z"
          fill="#F5F5F5"
        />
        <path d="M421.14 281.58H418.33V284.39H421.14V281.58Z" fill="#F5F5F5" />
        <path
          d="M421.14 286.289H418.33V289.099H421.14V286.289Z"
          fill="#F5F5F5"
        />
        <path d="M421.14 291H418.33V293.81H421.14V291Z" fill="#F5F5F5" />
        <path
          d="M93.3696 273.57H90.5596V276.38H93.3696V273.57Z"
          fill="#F5F5F5"
        />
        <path
          d="M93.3696 278.279H90.5596V281.089H93.3696V278.279Z"
          fill="#F5F5F5"
        />
        <path
          d="M93.3696 282.98H90.5596V285.79H93.3696V282.98Z"
          fill="#F5F5F5"
        />
        <path
          d="M93.3696 287.689H90.5596V290.499H93.3696V287.689Z"
          fill="#F5F5F5"
        />
        <path d="M93.3696 292.4H90.5596V295.21H93.3696V292.4Z" fill="#F5F5F5" />
        <path
          d="M93.3696 297.109H90.5596V299.919H93.3696V297.109Z"
          fill="#F5F5F5"
        />
        <path
          d="M93.3696 301.82H90.5596V304.63H93.3696V301.82Z"
          fill="#F5F5F5"
        />
        <path
          d="M93.3696 306.529H90.5596V309.339H93.3696V306.529Z"
          fill="#F5F5F5"
        />
        <path
          d="M93.3696 311.23H90.5596V314.04H93.3696V311.23Z"
          fill="#F5F5F5"
        />
        <path
          d="M93.3696 315.939H90.5596V318.749H93.3696V315.939Z"
          fill="#F5F5F5"
        />
        <path
          d="M93.3696 320.65H90.5596V323.46H93.3696V320.65Z"
          fill="#F5F5F5"
        />
        <path
          d="M93.3696 325.359H90.5596V328.169H93.3696V325.359Z"
          fill="#F5F5F5"
        />
        <path
          d="M93.3696 330.07H90.5596V332.88H93.3696V330.07Z"
          fill="#F5F5F5"
        />
        <path
          d="M93.3696 334.779H90.5596V337.589H93.3696V334.779Z"
          fill="#F5F5F5"
        />
        <path
          d="M93.3696 339.48H90.5596V342.29H93.3696V339.48Z"
          fill="#F5F5F5"
        />
        <path
          d="M93.3696 344.189H90.5596V346.999H93.3696V344.189Z"
          fill="#F5F5F5"
        />
        <path d="M93.3696 348.9H90.5596V351.71H93.3696V348.9Z" fill="#F5F5F5" />
        <path
          d="M93.3696 353.609H90.5596V356.419H93.3696V353.609Z"
          fill="#F5F5F5"
        />
        <path
          d="M93.3696 358.32H90.5596V361.13H93.3696V358.32Z"
          fill="#F5F5F5"
        />
        <path
          d="M93.3696 363.029H90.5596V365.839H93.3696V363.029Z"
          fill="#F5F5F5"
        />
        <path
          d="M97.8295 273.57H95.0195V276.38H97.8295V273.57Z"
          fill="#F5F5F5"
        />
        <path
          d="M97.8295 278.279H95.0195V281.089H97.8295V278.279Z"
          fill="#F5F5F5"
        />
        <path
          d="M97.8295 282.98H95.0195V285.79H97.8295V282.98Z"
          fill="#F5F5F5"
        />
        <path
          d="M97.8295 287.689H95.0195V290.499H97.8295V287.689Z"
          fill="#F5F5F5"
        />
        <path d="M97.8295 292.4H95.0195V295.21H97.8295V292.4Z" fill="#F5F5F5" />
        <path
          d="M97.8295 297.109H95.0195V299.919H97.8295V297.109Z"
          fill="#F5F5F5"
        />
        <path
          d="M97.8295 301.82H95.0195V304.63H97.8295V301.82Z"
          fill="#F5F5F5"
        />
        <path
          d="M97.8295 306.529H95.0195V309.339H97.8295V306.529Z"
          fill="#F5F5F5"
        />
        <path
          d="M97.8295 311.23H95.0195V314.04H97.8295V311.23Z"
          fill="#F5F5F5"
        />
        <path
          d="M97.8295 315.939H95.0195V318.749H97.8295V315.939Z"
          fill="#F5F5F5"
        />
        <path
          d="M97.8295 320.65H95.0195V323.46H97.8295V320.65Z"
          fill="#F5F5F5"
        />
        <path
          d="M97.8295 325.359H95.0195V328.169H97.8295V325.359Z"
          fill="#F5F5F5"
        />
        <path
          d="M97.8295 330.07H95.0195V332.88H97.8295V330.07Z"
          fill="#F5F5F5"
        />
        <path
          d="M97.8295 334.779H95.0195V337.589H97.8295V334.779Z"
          fill="#F5F5F5"
        />
        <path
          d="M97.8295 339.48H95.0195V342.29H97.8295V339.48Z"
          fill="#F5F5F5"
        />
        <path
          d="M97.8295 344.189H95.0195V346.999H97.8295V344.189Z"
          fill="#F5F5F5"
        />
        <path d="M97.8295 348.9H95.0195V351.71H97.8295V348.9Z" fill="#F5F5F5" />
        <path
          d="M97.8295 353.609H95.0195V356.419H97.8295V353.609Z"
          fill="#F5F5F5"
        />
        <path d="M102.29 273.57H99.4805V276.38H102.29V273.57Z" fill="#F5F5F5" />
        <path
          d="M102.29 278.279H99.4805V281.089H102.29V278.279Z"
          fill="#F5F5F5"
        />
        <path d="M102.29 282.98H99.4805V285.79H102.29V282.98Z" fill="#F5F5F5" />
        <path
          d="M102.29 287.689H99.4805V290.499H102.29V287.689Z"
          fill="#F5F5F5"
        />
        <path d="M102.29 292.4H99.4805V295.21H102.29V292.4Z" fill="#F5F5F5" />
        <path
          d="M102.29 297.109H99.4805V299.919H102.29V297.109Z"
          fill="#F5F5F5"
        />
        <path d="M102.29 301.82H99.4805V304.63H102.29V301.82Z" fill="#F5F5F5" />
        <path
          d="M102.29 306.529H99.4805V309.339H102.29V306.529Z"
          fill="#F5F5F5"
        />
        <path d="M102.29 311.23H99.4805V314.04H102.29V311.23Z" fill="#F5F5F5" />
        <path
          d="M102.29 315.939H99.4805V318.749H102.29V315.939Z"
          fill="#F5F5F5"
        />
        <path d="M102.29 320.65H99.4805V323.46H102.29V320.65Z" fill="#F5F5F5" />
        <path
          d="M102.29 325.359H99.4805V328.169H102.29V325.359Z"
          fill="#F5F5F5"
        />
        <path d="M102.29 330.07H99.4805V332.88H102.29V330.07Z" fill="#F5F5F5" />
        <path
          d="M102.29 334.779H99.4805V337.589H102.29V334.779Z"
          fill="#F5F5F5"
        />
        <path d="M102.29 339.48H99.4805V342.29H102.29V339.48Z" fill="#F5F5F5" />
        <path
          d="M102.29 344.189H99.4805V346.999H102.29V344.189Z"
          fill="#F5F5F5"
        />
        <path d="M102.29 348.9H99.4805V351.71H102.29V348.9Z" fill="#F5F5F5" />
        <path
          d="M102.29 353.609H99.4805V356.419H102.29V353.609Z"
          fill="#F5F5F5"
        />
        <path
          d="M76.6801 307.93H73.8701V310.74H76.6801V307.93Z"
          fill="#F5F5F5"
        />
        <path
          d="M76.6801 312.641H73.8701V315.451H76.6801V312.641Z"
          fill="#F5F5F5"
        />
        <path
          d="M76.6801 317.35H73.8701V320.16H76.6801V317.35Z"
          fill="#F5F5F5"
        />
        <path
          d="M76.6801 322.061H73.8701V324.871H76.6801V322.061Z"
          fill="#F5F5F5"
        />
        <path
          d="M76.6801 326.76H73.8701V329.57H76.6801V326.76Z"
          fill="#F5F5F5"
        />
        <path
          d="M76.6801 331.471H73.8701V334.281H76.6801V331.471Z"
          fill="#F5F5F5"
        />
        <path
          d="M76.6801 336.18H73.8701V338.99H76.6801V336.18Z"
          fill="#F5F5F5"
        />
        <path
          d="M76.6801 340.891H73.8701V343.701H76.6801V340.891Z"
          fill="#F5F5F5"
        />
        <path d="M76.6801 345.6H73.8701V348.41H76.6801V345.6Z" fill="#F5F5F5" />
        <path
          d="M76.6801 350.311H73.8701V353.121H76.6801V350.311Z"
          fill="#F5F5F5"
        />
        <path
          d="M76.6801 355.01H73.8701V357.82H76.6801V355.01Z"
          fill="#F5F5F5"
        />
        <path
          d="M76.6801 359.721H73.8701V362.531H76.6801V359.721Z"
          fill="#F5F5F5"
        />
        <path
          d="M76.6801 364.43H73.8701V367.24H76.6801V364.43Z"
          fill="#F5F5F5"
        />
        <path
          d="M81.1401 307.93H78.3301V310.74H81.1401V307.93Z"
          fill="#F5F5F5"
        />
        <path
          d="M81.1401 312.641H78.3301V315.451H81.1401V312.641Z"
          fill="#F5F5F5"
        />
        <path
          d="M81.1401 317.35H78.3301V320.16H81.1401V317.35Z"
          fill="#F5F5F5"
        />
        <path
          d="M81.1401 322.061H78.3301V324.871H81.1401V322.061Z"
          fill="#F5F5F5"
        />
        <path
          d="M81.1401 326.76H78.3301V329.57H81.1401V326.76Z"
          fill="#F5F5F5"
        />
        <path
          d="M81.1401 331.471H78.3301V334.281H81.1401V331.471Z"
          fill="#F5F5F5"
        />
        <path
          d="M81.1401 336.18H78.3301V338.99H81.1401V336.18Z"
          fill="#F5F5F5"
        />
        <path
          d="M81.1401 340.891H78.3301V343.701H81.1401V340.891Z"
          fill="#F5F5F5"
        />
        <path d="M81.1401 345.6H78.3301V348.41H81.1401V345.6Z" fill="#F5F5F5" />
        <path
          d="M81.1401 350.311H78.3301V353.121H81.1401V350.311Z"
          fill="#F5F5F5"
        />
        <path
          d="M81.1401 355.01H78.3301V357.82H81.1401V355.01Z"
          fill="#F5F5F5"
        />
        <path
          d="M81.1401 359.721H78.3301V362.531H81.1401V359.721Z"
          fill="#F5F5F5"
        />
        <path
          d="M81.1401 364.43H78.3301V367.24H81.1401V364.43Z"
          fill="#F5F5F5"
        />
        <path d="M85.6 307.93H82.79V310.74H85.6V307.93Z" fill="#F5F5F5" />
        <path d="M85.6 312.641H82.79V315.451H85.6V312.641Z" fill="#F5F5F5" />
        <path d="M85.6 317.35H82.79V320.16H85.6V317.35Z" fill="#F5F5F5" />
        <path d="M85.6 322.061H82.79V324.871H85.6V322.061Z" fill="#F5F5F5" />
        <path d="M85.6 326.76H82.79V329.57H85.6V326.76Z" fill="#F5F5F5" />
        <path d="M85.6 331.471H82.79V334.281H85.6V331.471Z" fill="#F5F5F5" />
        <path d="M85.6 336.18H82.79V338.99H85.6V336.18Z" fill="#F5F5F5" />
        <path d="M85.6 340.891H82.79V343.701H85.6V340.891Z" fill="#F5F5F5" />
        <path d="M85.6 345.6H82.79V348.41H85.6V345.6Z" fill="#F5F5F5" />
        <path d="M85.6 350.311H82.79V353.121H85.6V350.311Z" fill="#F5F5F5" />
        <path d="M85.6 355.01H82.79V357.82H85.6V355.01Z" fill="#F5F5F5" />
        <path d="M85.6 359.721H82.79V362.531H85.6V359.721Z" fill="#F5F5F5" />
        <path d="M85.6 364.43H82.79V367.24H85.6V364.43Z" fill="#F5F5F5" />
        <path d="M85.6 369.141H82.79V371.951H85.6V369.141Z" fill="#F5F5F5" />
        <path d="M110.38 309.33H107.57V312.14H110.38V309.33Z" fill="#F5F5F5" />
        <path
          d="M110.38 314.039H107.57V316.849H110.38V314.039Z"
          fill="#F5F5F5"
        />
        <path d="M110.38 318.75H107.57V321.56H110.38V318.75Z" fill="#F5F5F5" />
        <path
          d="M110.38 323.461H107.57V326.271H110.38V323.461Z"
          fill="#F5F5F5"
        />
        <path d="M110.38 328.17H107.57V330.98H110.38V328.17Z" fill="#F5F5F5" />
        <path
          d="M110.38 332.881H107.57V335.691H110.38V332.881Z"
          fill="#F5F5F5"
        />
        <path d="M110.38 337.59H107.57V340.4H110.38V337.59Z" fill="#F5F5F5" />
        <path
          d="M110.38 342.289H107.57V345.099H110.38V342.289Z"
          fill="#F5F5F5"
        />
        <path d="M110.38 347H107.57V349.81H110.38V347Z" fill="#F5F5F5" />
        <path
          d="M110.38 351.711H107.57V354.521H110.38V351.711Z"
          fill="#F5F5F5"
        />
        <path d="M110.38 365.84H107.57V368.65H110.38V365.84Z" fill="#F5F5F5" />
        <path d="M114.84 309.33H112.03V312.14H114.84V309.33Z" fill="#F5F5F5" />
        <path
          d="M114.84 314.039H112.03V316.849H114.84V314.039Z"
          fill="#F5F5F5"
        />
        <path d="M114.84 318.75H112.03V321.56H114.84V318.75Z" fill="#F5F5F5" />
        <path
          d="M114.84 323.461H112.03V326.271H114.84V323.461Z"
          fill="#F5F5F5"
        />
        <path d="M114.84 328.17H112.03V330.98H114.84V328.17Z" fill="#F5F5F5" />
        <path
          d="M114.84 332.881H112.03V335.691H114.84V332.881Z"
          fill="#F5F5F5"
        />
        <path d="M114.84 337.59H112.03V340.4H114.84V337.59Z" fill="#F5F5F5" />
        <path
          d="M114.84 342.289H112.03V345.099H114.84V342.289Z"
          fill="#F5F5F5"
        />
        <path d="M114.84 347H112.03V349.81H114.84V347Z" fill="#F5F5F5" />
        <path
          d="M114.84 361.131H112.03V363.941H114.84V361.131Z"
          fill="#F5F5F5"
        />
        <path d="M114.84 365.84H112.03V368.65H114.84V365.84Z" fill="#F5F5F5" />
        <path d="M119.3 309.33H116.49V312.14H119.3V309.33Z" fill="#F5F5F5" />
        <path d="M119.3 314.039H116.49V316.849H119.3V314.039Z" fill="#F5F5F5" />
        <path d="M119.3 318.75H116.49V321.56H119.3V318.75Z" fill="#F5F5F5" />
        <path d="M119.3 323.461H116.49V326.271H119.3V323.461Z" fill="#F5F5F5" />
        <path d="M119.3 328.17H116.49V330.98H119.3V328.17Z" fill="#F5F5F5" />
        <path d="M119.3 332.881H116.49V335.691H119.3V332.881Z" fill="#F5F5F5" />
        <path d="M119.3 337.59H116.49V340.4H119.3V337.59Z" fill="#F5F5F5" />
        <path d="M119.3 342.289H116.49V345.099H119.3V342.289Z" fill="#F5F5F5" />
        <path d="M119.3 356.42H116.49V359.23H119.3V356.42Z" fill="#F5F5F5" />
        <path d="M119.3 361.131H116.49V363.941H119.3V361.131Z" fill="#F5F5F5" />
        <path d="M119.3 365.84H116.49V368.65H119.3V365.84Z" fill="#F5F5F5" />
        <path
          d="M160.77 237.801H157.96V240.611H160.77V237.801Z"
          fill="#F5F5F5"
        />
        <path d="M160.77 242.51H157.96V245.32H160.77V242.51Z" fill="#F5F5F5" />
        <path
          d="M160.77 247.221H157.96V250.031H160.77V247.221Z"
          fill="#F5F5F5"
        />
        <path d="M160.77 251.93H157.96V254.74H160.77V251.93Z" fill="#F5F5F5" />
        <path
          d="M160.77 256.631H157.96V259.441H160.77V256.631Z"
          fill="#F5F5F5"
        />
        <path d="M160.77 261.34H157.96V264.15H160.77V261.34Z" fill="#F5F5F5" />
        <path
          d="M160.77 266.051H157.96V268.861H160.77V266.051Z"
          fill="#F5F5F5"
        />
        <path d="M160.77 270.76H157.96V273.57H160.77V270.76Z" fill="#F5F5F5" />
        <path
          d="M160.77 275.471H157.96V278.281H160.77V275.471Z"
          fill="#F5F5F5"
        />
        <path d="M160.77 280.18H157.96V282.99H160.77V280.18Z" fill="#F5F5F5" />
        <path
          d="M160.77 284.881H157.96V287.691H160.77V284.881Z"
          fill="#F5F5F5"
        />
        <path d="M160.77 289.59H157.96V292.4H160.77V289.59Z" fill="#F5F5F5" />
        <path
          d="M160.77 294.301H157.96V297.111H160.77V294.301Z"
          fill="#F5F5F5"
        />
        <path d="M160.77 299.01H157.96V301.82H160.77V299.01Z" fill="#F5F5F5" />
        <path
          d="M160.77 303.721H157.96V306.531H160.77V303.721Z"
          fill="#F5F5F5"
        />
        <path d="M160.77 308.43H157.96V311.24H160.77V308.43Z" fill="#F5F5F5" />
        <path
          d="M160.77 313.131H157.96V315.941H160.77V313.131Z"
          fill="#F5F5F5"
        />
        <path
          d="M160.77 322.551H157.96V325.361H160.77V322.551Z"
          fill="#F5F5F5"
        />
        <path d="M160.77 327.26H157.96V330.07H160.77V327.26Z" fill="#F5F5F5" />
        <path
          d="M160.77 331.971H157.96V334.781H160.77V331.971Z"
          fill="#F5F5F5"
        />
        <path d="M160.77 336.68H157.96V339.49H160.77V336.68Z" fill="#F5F5F5" />
        <path
          d="M160.77 341.381H157.96V344.191H160.77V341.381Z"
          fill="#F5F5F5"
        />
        <path
          d="M165.23 237.801H162.42V240.611H165.23V237.801Z"
          fill="#F5F5F5"
        />
        <path d="M165.23 242.51H162.42V245.32H165.23V242.51Z" fill="#F5F5F5" />
        <path
          d="M165.23 247.221H162.42V250.031H165.23V247.221Z"
          fill="#F5F5F5"
        />
        <path d="M165.23 251.93H162.42V254.74H165.23V251.93Z" fill="#F5F5F5" />
        <path
          d="M165.23 256.631H162.42V259.441H165.23V256.631Z"
          fill="#F5F5F5"
        />
        <path d="M165.23 261.34H162.42V264.15H165.23V261.34Z" fill="#F5F5F5" />
        <path
          d="M165.23 266.051H162.42V268.861H165.23V266.051Z"
          fill="#F5F5F5"
        />
        <path d="M165.23 270.76H162.42V273.57H165.23V270.76Z" fill="#F5F5F5" />
        <path
          d="M165.23 275.471H162.42V278.281H165.23V275.471Z"
          fill="#F5F5F5"
        />
        <path d="M165.23 280.18H162.42V282.99H165.23V280.18Z" fill="#F5F5F5" />
        <path
          d="M165.23 284.881H162.42V287.691H165.23V284.881Z"
          fill="#F5F5F5"
        />
        <path d="M165.23 289.59H162.42V292.4H165.23V289.59Z" fill="#F5F5F5" />
        <path
          d="M165.23 294.301H162.42V297.111H165.23V294.301Z"
          fill="#F5F5F5"
        />
        <path d="M165.23 299.01H162.42V301.82H165.23V299.01Z" fill="#F5F5F5" />
        <path
          d="M165.23 303.721H162.42V306.531H165.23V303.721Z"
          fill="#F5F5F5"
        />
        <path d="M165.23 308.43H162.42V311.24H165.23V308.43Z" fill="#F5F5F5" />
        <path d="M165.23 336.68H162.42V339.49H165.23V336.68Z" fill="#F5F5F5" />
        <path
          d="M165.23 341.381H162.42V344.191H165.23V341.381Z"
          fill="#F5F5F5"
        />
        <path
          d="M169.69 237.801H166.88V240.611H169.69V237.801Z"
          fill="#F5F5F5"
        />
        <path d="M169.69 242.51H166.88V245.32H169.69V242.51Z" fill="#F5F5F5" />
        <path
          d="M169.69 247.221H166.88V250.031H169.69V247.221Z"
          fill="#F5F5F5"
        />
        <path d="M169.69 251.93H166.88V254.74H169.69V251.93Z" fill="#F5F5F5" />
        <path
          d="M169.69 256.631H166.88V259.441H169.69V256.631Z"
          fill="#F5F5F5"
        />
        <path d="M169.69 261.34H166.88V264.15H169.69V261.34Z" fill="#F5F5F5" />
        <path
          d="M169.69 266.051H166.88V268.861H169.69V266.051Z"
          fill="#F5F5F5"
        />
        <path d="M169.69 270.76H166.88V273.57H169.69V270.76Z" fill="#F5F5F5" />
        <path
          d="M169.69 275.471H166.88V278.281H169.69V275.471Z"
          fill="#F5F5F5"
        />
        <path d="M169.69 280.18H166.88V282.99H169.69V280.18Z" fill="#F5F5F5" />
        <path
          d="M169.69 284.881H166.88V287.691H169.69V284.881Z"
          fill="#F5F5F5"
        />
        <path d="M169.69 289.59H166.88V292.4H169.69V289.59Z" fill="#F5F5F5" />
        <path
          d="M169.69 294.301H166.88V297.111H169.69V294.301Z"
          fill="#F5F5F5"
        />
        <path d="M169.69 299.01H166.88V301.82H169.69V299.01Z" fill="#F5F5F5" />
        <path
          d="M169.69 303.721H166.88V306.531H169.69V303.721Z"
          fill="#F5F5F5"
        />
        <path d="M169.69 308.43H166.88V311.24H169.69V308.43Z" fill="#F5F5F5" />
        <path d="M169.69 317.84H166.88V320.65H169.69V317.84Z" fill="#F5F5F5" />
        <path
          d="M169.69 322.551H166.88V325.361H169.69V322.551Z"
          fill="#F5F5F5"
        />
        <path d="M169.69 327.26H166.88V330.07H169.69V327.26Z" fill="#F5F5F5" />
        <path
          d="M169.69 331.971H166.88V334.781H169.69V331.971Z"
          fill="#F5F5F5"
        />
        <path
          d="M169.69 341.381H166.88V344.191H169.69V341.381Z"
          fill="#F5F5F5"
        />
        <path
          d="M144.58 297.109H141.77V299.919H144.58V297.109Z"
          fill="#F5F5F5"
        />
        <path d="M144.58 301.82H141.77V304.63H144.58V301.82Z" fill="#F5F5F5" />
        <path
          d="M144.58 306.529H141.77V309.339H144.58V306.529Z"
          fill="#F5F5F5"
        />
        <path d="M144.58 311.23H141.77V314.04H144.58V311.23Z" fill="#F5F5F5" />
        <path
          d="M144.58 315.939H141.77V318.749H144.58V315.939Z"
          fill="#F5F5F5"
        />
        <path d="M144.58 320.65H141.77V323.46H144.58V320.65Z" fill="#F5F5F5" />
        <path
          d="M144.58 325.359H141.77V328.169H144.58V325.359Z"
          fill="#F5F5F5"
        />
        <path
          d="M144.58 334.779H141.77V337.589H144.58V334.779Z"
          fill="#F5F5F5"
        />
        <path d="M144.58 339.48H141.77V342.29H144.58V339.48Z" fill="#F5F5F5" />
        <path
          d="M144.58 344.189H141.77V346.999H144.58V344.189Z"
          fill="#F5F5F5"
        />
        <path d="M144.58 348.9H141.77V351.71H144.58V348.9Z" fill="#F5F5F5" />
        <path
          d="M144.58 353.609H141.77V356.419H144.58V353.609Z"
          fill="#F5F5F5"
        />
        <path d="M144.58 358.32H141.77V361.13H144.58V358.32Z" fill="#F5F5F5" />
        <path
          d="M144.58 363.029H141.77V365.839H144.58V363.029Z"
          fill="#F5F5F5"
        />
        <path d="M144.58 367.74H141.77V370.55H144.58V367.74Z" fill="#F5F5F5" />
        <path
          d="M149.04 297.109H146.23V299.919H149.04V297.109Z"
          fill="#F5F5F5"
        />
        <path d="M149.04 301.82H146.23V304.63H149.04V301.82Z" fill="#F5F5F5" />
        <path
          d="M149.04 306.529H146.23V309.339H149.04V306.529Z"
          fill="#F5F5F5"
        />
        <path d="M149.04 311.23H146.23V314.04H149.04V311.23Z" fill="#F5F5F5" />
        <path
          d="M149.04 315.939H146.23V318.749H149.04V315.939Z"
          fill="#F5F5F5"
        />
        <path d="M149.04 320.65H146.23V323.46H149.04V320.65Z" fill="#F5F5F5" />
        <path
          d="M149.04 334.779H146.23V337.589H149.04V334.779Z"
          fill="#F5F5F5"
        />
        <path d="M149.04 339.48H146.23V342.29H149.04V339.48Z" fill="#F5F5F5" />
        <path
          d="M149.04 344.189H146.23V346.999H149.04V344.189Z"
          fill="#F5F5F5"
        />
        <path d="M149.04 348.9H146.23V351.71H149.04V348.9Z" fill="#F5F5F5" />
        <path
          d="M149.04 353.609H146.23V356.419H149.04V353.609Z"
          fill="#F5F5F5"
        />
        <path d="M149.04 358.32H146.23V361.13H149.04V358.32Z" fill="#F5F5F5" />
        <path
          d="M149.04 363.029H146.23V365.839H149.04V363.029Z"
          fill="#F5F5F5"
        />
        <path d="M149.04 367.74H146.23V370.55H149.04V367.74Z" fill="#F5F5F5" />
        <path d="M153.5 297.109H150.69V299.919H153.5V297.109Z" fill="#F5F5F5" />
        <path d="M153.5 301.82H150.69V304.63H153.5V301.82Z" fill="#F5F5F5" />
        <path d="M153.5 306.529H150.69V309.339H153.5V306.529Z" fill="#F5F5F5" />
        <path d="M153.5 311.23H150.69V314.04H153.5V311.23Z" fill="#F5F5F5" />
        <path d="M153.5 315.939H150.69V318.749H153.5V315.939Z" fill="#F5F5F5" />
        <path d="M153.5 320.65H150.69V323.46H153.5V320.65Z" fill="#F5F5F5" />
        <path d="M153.5 330.07H150.69V332.88H153.5V330.07Z" fill="#F5F5F5" />
        <path d="M153.5 334.779H150.69V337.589H153.5V334.779Z" fill="#F5F5F5" />
        <path d="M153.5 339.48H150.69V342.29H153.5V339.48Z" fill="#F5F5F5" />
        <path d="M153.5 344.189H150.69V346.999H153.5V344.189Z" fill="#F5F5F5" />
        <path d="M153.5 348.9H150.69V351.71H153.5V348.9Z" fill="#F5F5F5" />
        <path d="M153.5 353.609H150.69V356.419H153.5V353.609Z" fill="#F5F5F5" />
        <path d="M153.5 358.32H150.69V361.13H153.5V358.32Z" fill="#F5F5F5" />
        <path d="M153.5 363.029H150.69V365.839H153.5V363.029Z" fill="#F5F5F5" />
        <path d="M153.5 367.74H150.69V370.55H153.5V367.74Z" fill="#F5F5F5" />
        <path d="M247.5 270.76H244.69V273.57H247.5V270.76Z" fill="#F5F5F5" />
        <path d="M247.5 275.471H244.69V278.281H247.5V275.471Z" fill="#F5F5F5" />
        <path d="M247.5 280.18H244.69V282.99H247.5V280.18Z" fill="#F5F5F5" />
        <path d="M247.5 284.881H244.69V287.691H247.5V284.881Z" fill="#F5F5F5" />
        <path d="M247.5 289.59H244.69V292.4H247.5V289.59Z" fill="#F5F5F5" />
        <path d="M247.5 294.301H244.69V297.111H247.5V294.301Z" fill="#F5F5F5" />
        <path d="M247.5 299.01H244.69V301.82H247.5V299.01Z" fill="#F5F5F5" />
        <path d="M247.5 303.721H244.69V306.531H247.5V303.721Z" fill="#F5F5F5" />
        <path d="M247.5 308.43H244.69V311.24H247.5V308.43Z" fill="#F5F5F5" />
        <path d="M247.5 313.131H244.69V315.941H247.5V313.131Z" fill="#F5F5F5" />
        <path d="M247.5 317.84H244.69V320.65H247.5V317.84Z" fill="#F5F5F5" />
        <path d="M247.5 322.551H244.69V325.361H247.5V322.551Z" fill="#F5F5F5" />
        <path d="M247.5 327.26H244.69V330.07H247.5V327.26Z" fill="#F5F5F5" />
        <path d="M247.5 336.68H244.69V339.49H247.5V336.68Z" fill="#F5F5F5" />
        <path d="M247.5 341.381H244.69V344.191H247.5V341.381Z" fill="#F5F5F5" />
        <path d="M247.5 346.09H244.69V348.9H247.5V346.09Z" fill="#F5F5F5" />
        <path d="M247.5 350.801H244.69V353.611H247.5V350.801Z" fill="#F5F5F5" />
        <path d="M247.5 360.221H244.69V363.031H247.5V360.221Z" fill="#F5F5F5" />
        <path d="M247.5 364.93H244.69V367.74H247.5V364.93Z" fill="#F5F5F5" />
        <path d="M247.5 369.641H244.69V372.451H247.5V369.641Z" fill="#F5F5F5" />
        <path d="M251.97 270.76H249.16V273.57H251.97V270.76Z" fill="#F5F5F5" />
        <path
          d="M251.97 275.471H249.16V278.281H251.97V275.471Z"
          fill="#F5F5F5"
        />
        <path d="M251.97 280.18H249.16V282.99H251.97V280.18Z" fill="#F5F5F5" />
        <path
          d="M251.97 284.881H249.16V287.691H251.97V284.881Z"
          fill="#F5F5F5"
        />
        <path d="M251.97 289.59H249.16V292.4H251.97V289.59Z" fill="#F5F5F5" />
        <path
          d="M251.97 294.301H249.16V297.111H251.97V294.301Z"
          fill="#F5F5F5"
        />
        <path d="M251.97 299.01H249.16V301.82H251.97V299.01Z" fill="#F5F5F5" />
        <path
          d="M251.97 303.721H249.16V306.531H251.97V303.721Z"
          fill="#F5F5F5"
        />
        <path d="M251.97 308.43H249.16V311.24H251.97V308.43Z" fill="#F5F5F5" />
        <path
          d="M251.97 313.131H249.16V315.941H251.97V313.131Z"
          fill="#F5F5F5"
        />
        <path d="M251.97 317.84H249.16V320.65H251.97V317.84Z" fill="#F5F5F5" />
        <path
          d="M251.97 322.551H249.16V325.361H251.97V322.551Z"
          fill="#F5F5F5"
        />
        <path d="M251.97 327.26H249.16V330.07H251.97V327.26Z" fill="#F5F5F5" />
        <path
          d="M251.97 341.381H249.16V344.191H251.97V341.381Z"
          fill="#F5F5F5"
        />
        <path d="M251.97 346.09H249.16V348.9H251.97V346.09Z" fill="#F5F5F5" />
        <path d="M251.97 355.51H249.16V358.32H251.97V355.51Z" fill="#F5F5F5" />
        <path
          d="M251.97 360.221H249.16V363.031H251.97V360.221Z"
          fill="#F5F5F5"
        />
        <path d="M251.97 364.93H249.16V367.74H251.97V364.93Z" fill="#F5F5F5" />
        <path
          d="M251.97 369.641H249.16V372.451H251.97V369.641Z"
          fill="#F5F5F5"
        />
        <path d="M256.43 270.76H253.62V273.57H256.43V270.76Z" fill="#F5F5F5" />
        <path
          d="M256.43 275.471H253.62V278.281H256.43V275.471Z"
          fill="#F5F5F5"
        />
        <path d="M256.43 280.18H253.62V282.99H256.43V280.18Z" fill="#F5F5F5" />
        <path
          d="M256.43 284.881H253.62V287.691H256.43V284.881Z"
          fill="#F5F5F5"
        />
        <path d="M256.43 289.59H253.62V292.4H256.43V289.59Z" fill="#F5F5F5" />
        <path
          d="M256.43 294.301H253.62V297.111H256.43V294.301Z"
          fill="#F5F5F5"
        />
        <path d="M256.43 299.01H253.62V301.82H256.43V299.01Z" fill="#F5F5F5" />
        <path
          d="M256.43 303.721H253.62V306.531H256.43V303.721Z"
          fill="#F5F5F5"
        />
        <path d="M256.43 308.43H253.62V311.24H256.43V308.43Z" fill="#F5F5F5" />
        <path
          d="M256.43 313.131H253.62V315.941H256.43V313.131Z"
          fill="#F5F5F5"
        />
        <path d="M256.43 317.84H253.62V320.65H256.43V317.84Z" fill="#F5F5F5" />
        <path
          d="M256.43 322.551H253.62V325.361H256.43V322.551Z"
          fill="#F5F5F5"
        />
        <path d="M256.43 327.26H253.62V330.07H256.43V327.26Z" fill="#F5F5F5" />
        <path
          d="M256.43 331.971H253.62V334.781H256.43V331.971Z"
          fill="#F5F5F5"
        />
        <path
          d="M256.43 341.381H253.62V344.191H256.43V341.381Z"
          fill="#F5F5F5"
        />
        <path
          d="M256.43 350.801H253.62V353.611H256.43V350.801Z"
          fill="#F5F5F5"
        />
        <path d="M256.43 355.51H253.62V358.32H256.43V355.51Z" fill="#F5F5F5" />
        <path
          d="M256.43 360.221H253.62V363.031H256.43V360.221Z"
          fill="#F5F5F5"
        />
        <path d="M256.43 364.93H253.62V367.74H256.43V364.93Z" fill="#F5F5F5" />
        <path
          d="M256.43 369.641H253.62V372.451H256.43V369.641Z"
          fill="#F5F5F5"
        />
        <path
          d="M229.33 297.609H226.52V300.419H229.33V297.609Z"
          fill="#F5F5F5"
        />
        <path
          d="M229.33 302.311H226.52V305.121H229.33V302.311Z"
          fill="#F5F5F5"
        />
        <path d="M229.33 307.02H226.52V309.83H229.33V307.02Z" fill="#F5F5F5" />
        <path d="M229.33 311.73H226.52V314.54H229.33V311.73Z" fill="#F5F5F5" />
        <path
          d="M229.33 316.439H226.52V319.249H229.33V316.439Z"
          fill="#F5F5F5"
        />
        <path d="M229.33 321.15H226.52V323.96H229.33V321.15Z" fill="#F5F5F5" />
        <path d="M229.33 335.27H226.52V338.08H229.33V335.27Z" fill="#F5F5F5" />
        <path d="M229.33 339.98H226.52V342.79H229.33V339.98Z" fill="#F5F5F5" />
        <path
          d="M229.33 344.689H226.52V347.499H229.33V344.689Z"
          fill="#F5F5F5"
        />
        <path d="M229.33 349.4H226.52V352.21H229.33V349.4Z" fill="#F5F5F5" />
        <path
          d="M229.33 354.109H226.52V356.919H229.33V354.109Z"
          fill="#F5F5F5"
        />
        <path
          d="M229.33 358.811H226.52V361.621H229.33V358.811Z"
          fill="#F5F5F5"
        />
        <path d="M229.33 363.52H226.52V366.33H229.33V363.52Z" fill="#F5F5F5" />
        <path
          d="M233.79 297.609H230.98V300.419H233.79V297.609Z"
          fill="#F5F5F5"
        />
        <path
          d="M233.79 302.311H230.98V305.121H233.79V302.311Z"
          fill="#F5F5F5"
        />
        <path d="M233.79 307.02H230.98V309.83H233.79V307.02Z" fill="#F5F5F5" />
        <path d="M233.79 311.73H230.98V314.54H233.79V311.73Z" fill="#F5F5F5" />
        <path
          d="M233.79 316.439H230.98V319.249H233.79V316.439Z"
          fill="#F5F5F5"
        />
        <path d="M233.79 321.15H230.98V323.96H233.79V321.15Z" fill="#F5F5F5" />
        <path d="M233.79 335.27H230.98V338.08H233.79V335.27Z" fill="#F5F5F5" />
        <path d="M233.79 339.98H230.98V342.79H233.79V339.98Z" fill="#F5F5F5" />
        <path
          d="M233.79 344.689H230.98V347.499H233.79V344.689Z"
          fill="#F5F5F5"
        />
        <path d="M233.79 349.4H230.98V352.21H233.79V349.4Z" fill="#F5F5F5" />
        <path
          d="M233.79 354.109H230.98V356.919H233.79V354.109Z"
          fill="#F5F5F5"
        />
        <path
          d="M233.79 358.811H230.98V361.621H233.79V358.811Z"
          fill="#F5F5F5"
        />
        <path d="M233.79 368.23H230.98V371.04H233.79V368.23Z" fill="#F5F5F5" />
        <path
          d="M381.98 276.869H379.17V279.679H381.98V276.869Z"
          fill="#F5F5F5"
        />
        <path d="M381.98 281.58H379.17V284.39H381.98V281.58Z" fill="#F5F5F5" />
        <path
          d="M381.98 286.289H379.17V289.099H381.98V286.289Z"
          fill="#F5F5F5"
        />
        <path d="M381.98 291H379.17V293.81H381.98V291Z" fill="#F5F5F5" />
        <path
          d="M381.98 295.711H379.17V298.521H381.98V295.711Z"
          fill="#F5F5F5"
        />
        <path d="M381.98 300.41H379.17V303.22H381.98V300.41Z" fill="#F5F5F5" />
        <path
          d="M381.98 305.119H379.17V307.929H381.98V305.119Z"
          fill="#F5F5F5"
        />
        <path d="M381.98 309.83H379.17V312.64H381.98V309.83Z" fill="#F5F5F5" />
        <path
          d="M381.98 314.539H379.17V317.349H381.98V314.539Z"
          fill="#F5F5F5"
        />
        <path d="M381.98 319.25H379.17V322.06H381.98V319.25Z" fill="#F5F5F5" />
        <path
          d="M381.98 323.961H379.17V326.771H381.98V323.961Z"
          fill="#F5F5F5"
        />
        <path
          d="M381.98 333.369H379.17V336.179H381.98V333.369Z"
          fill="#F5F5F5"
        />
        <path d="M381.98 338.08H379.17V340.89H381.98V338.08Z" fill="#F5F5F5" />
        <path
          d="M381.98 342.789H379.17V345.599H381.98V342.789Z"
          fill="#F5F5F5"
        />
        <path d="M381.98 347.5H379.17V350.31H381.98V347.5Z" fill="#F5F5F5" />
        <path
          d="M381.98 352.211H379.17V355.021H381.98V352.211Z"
          fill="#F5F5F5"
        />
        <path d="M381.98 356.91H379.17V359.72H381.98V356.91Z" fill="#F5F5F5" />
        <path
          d="M381.98 361.619H379.17V364.429H381.98V361.619Z"
          fill="#F5F5F5"
        />
        <path d="M381.98 366.33H379.17V369.14H381.98V366.33Z" fill="#F5F5F5" />
        <path
          d="M386.44 276.869H383.63V279.679H386.44V276.869Z"
          fill="#F5F5F5"
        />
        <path d="M386.44 281.58H383.63V284.39H386.44V281.58Z" fill="#F5F5F5" />
        <path
          d="M386.44 286.289H383.63V289.099H386.44V286.289Z"
          fill="#F5F5F5"
        />
        <path d="M386.44 291H383.63V293.81H386.44V291Z" fill="#F5F5F5" />
        <path
          d="M386.44 295.711H383.63V298.521H386.44V295.711Z"
          fill="#F5F5F5"
        />
        <path d="M386.44 300.41H383.63V303.22H386.44V300.41Z" fill="#F5F5F5" />
        <path
          d="M386.44 305.119H383.63V307.929H386.44V305.119Z"
          fill="#F5F5F5"
        />
        <path d="M386.44 309.83H383.63V312.64H386.44V309.83Z" fill="#F5F5F5" />
        <path
          d="M386.44 314.539H383.63V317.349H386.44V314.539Z"
          fill="#F5F5F5"
        />
        <path d="M386.44 319.25H383.63V322.06H386.44V319.25Z" fill="#F5F5F5" />
        <path
          d="M386.44 323.961H383.63V326.771H386.44V323.961Z"
          fill="#F5F5F5"
        />
        <path d="M386.44 328.66H383.63V331.47H386.44V328.66Z" fill="#F5F5F5" />
        <path
          d="M386.44 333.369H383.63V336.179H386.44V333.369Z"
          fill="#F5F5F5"
        />
        <path d="M386.44 338.08H383.63V340.89H386.44V338.08Z" fill="#F5F5F5" />
        <path
          d="M386.44 342.789H383.63V345.599H386.44V342.789Z"
          fill="#F5F5F5"
        />
        <path d="M386.44 347.5H383.63V350.31H386.44V347.5Z" fill="#F5F5F5" />
        <path
          d="M386.44 352.211H383.63V355.021H386.44V352.211Z"
          fill="#F5F5F5"
        />
        <path d="M386.44 356.91H383.63V359.72H386.44V356.91Z" fill="#F5F5F5" />
        <path
          d="M386.44 361.619H383.63V364.429H386.44V361.619Z"
          fill="#F5F5F5"
        />
        <path d="M386.44 366.33H383.63V369.14H386.44V366.33Z" fill="#F5F5F5" />
        <path
          d="M360.01 295.211H357.2V298.021H360.01V295.211Z"
          fill="#F5F5F5"
        />
        <path d="M360.01 299.92H357.2V302.73H360.01V299.92Z" fill="#F5F5F5" />
        <path
          d="M360.01 304.631H357.2V307.441H360.01V304.631Z"
          fill="#F5F5F5"
        />
        <path d="M360.01 309.33H357.2V312.14H360.01V309.33Z" fill="#F5F5F5" />
        <path
          d="M360.01 314.039H357.2V316.849H360.01V314.039Z"
          fill="#F5F5F5"
        />
        <path d="M360.01 318.75H357.2V321.56H360.01V318.75Z" fill="#F5F5F5" />
        <path
          d="M360.01 323.461H357.2V326.271H360.01V323.461Z"
          fill="#F5F5F5"
        />
        <path d="M360.01 328.17H357.2V330.98H360.01V328.17Z" fill="#F5F5F5" />
        <path
          d="M360.01 332.881H357.2V335.691H360.01V332.881Z"
          fill="#F5F5F5"
        />
        <path d="M360.01 337.59H357.2V340.4H360.01V337.59Z" fill="#F5F5F5" />
        <path
          d="M364.47 295.211H361.66V298.021H364.47V295.211Z"
          fill="#F5F5F5"
        />
        <path d="M364.47 299.92H361.66V302.73H364.47V299.92Z" fill="#F5F5F5" />
        <path
          d="M364.47 304.631H361.66V307.441H364.47V304.631Z"
          fill="#F5F5F5"
        />
        <path d="M364.47 309.33H361.66V312.14H364.47V309.33Z" fill="#F5F5F5" />
        <path
          d="M364.47 314.039H361.66V316.849H364.47V314.039Z"
          fill="#F5F5F5"
        />
        <path d="M364.47 318.75H361.66V321.56H364.47V318.75Z" fill="#F5F5F5" />
        <path
          d="M364.47 323.461H361.66V326.271H364.47V323.461Z"
          fill="#F5F5F5"
        />
        <path d="M364.47 328.17H361.66V330.98H364.47V328.17Z" fill="#F5F5F5" />
        <path
          d="M364.47 332.881H361.66V335.691H364.47V332.881Z"
          fill="#F5F5F5"
        />
        <path
          d="M419.25 408.951L406.09 343.391H401.04L387.88 408.951L378.2 414.871V416.631H428.61V414.871L419.25 408.951Z"
          fill={PRIMARY_MAIN}
        />
        <path
          opacity="0.2"
          d="M428.61 414.871H378.2V417.081H428.61V414.871Z"
          fill="white"
        />
        <path
          opacity="0.2"
          d="M406.09 343.391H401.04L387.72 408.761H419.09L406.09 343.391Z"
          fill="white"
        />
        <path
          opacity="0.6"
          d="M414.87 387.55L411.88 372.5H395.11L392.04 387.55H414.87Z"
          fill="white"
        />
        <path
          d="M114.71 408.951L101.55 343.391H96.4999L83.3399 408.951L73.6699 414.871V416.631H124.07V414.871L114.71 408.951Z"
          fill={PRIMARY_MAIN}
        />
        <path
          opacity="0.2"
          d="M124.08 414.871H73.6699V417.081H124.08V414.871Z"
          fill="white"
        />
        <path
          opacity="0.2"
          d="M101.55 343.391H96.4997L83.1797 408.761H114.56L101.55 343.391Z"
          fill="white"
        />
        <path
          opacity="0.6"
          d="M110.33 387.55L107.34 372.5H90.5698L87.5098 387.55H110.33Z"
          fill="white"
        />
        <path
          d="M362.17 409.06L370.58 409.2C370.717 409.202 370.85 409.251 370.955 409.338C371.061 409.426 371.133 409.546 371.16 409.68L372.43 415.86C372.459 415.993 372.461 416.129 372.436 416.262C372.41 416.395 372.358 416.522 372.282 416.634C372.207 416.746 372.109 416.842 371.995 416.915C371.881 416.988 371.754 417.038 371.62 417.06C371.547 417.07 371.473 417.07 371.4 417.06C368.68 416.97 366.7 416.78 363.28 416.72C361.18 416.72 354.83 416.81 351.93 416.72C349.03 416.63 348.69 413.8 349.88 413.56C355.23 412.48 359.27 410.93 361 409.43C361.33 409.164 361.748 409.032 362.17 409.06Z"
          fill="#263238"
        />
        <path
          d="M291.3 409.06L299.71 409.2C299.847 409.202 299.98 409.251 300.085 409.338C300.191 409.425 300.263 409.546 300.29 409.68L301.55 415.86C301.58 415.992 301.582 416.128 301.557 416.26C301.532 416.393 301.481 416.519 301.406 416.631C301.331 416.743 301.235 416.839 301.122 416.912C301.009 416.986 300.883 417.037 300.75 417.06C300.674 417.07 300.597 417.07 300.52 417.06C297.8 416.97 295.83 416.78 292.41 416.72C290.31 416.72 283.96 416.81 281.05 416.72C278.14 416.63 277.82 413.8 279.05 413.56C284.4 412.48 288.44 410.93 290.17 409.43C290.489 409.173 290.891 409.042 291.3 409.06Z"
          fill="#263238"
        />
        <path
          d="M372.461 409.491H361.001C361.001 409.491 346.421 361.431 345.171 349.171C343.921 336.911 343.461 306.171 343.461 306.171V306.231C342.971 306.891 338.641 312.971 333.661 320.041C327.151 329.291 319.511 340.231 317.901 342.911C313.181 350.811 301.621 409.451 301.621 409.451H290.131C290.131 409.451 293.001 357.001 298.891 339.701C304.781 322.401 330.191 284.621 331.291 282.591C332.701 280.001 336.161 271.781 336.161 271.781L370.471 276.551C370.471 276.551 371.031 282.361 370.991 287.441C370.831 306.501 367.231 332.261 366.651 339.191C365.571 352.361 372.461 409.491 372.461 409.491Z"
          fill={PRIMARY_MAIN}
        />
        <path
          d="M344.261 216.402C344.261 216.402 333.991 234.712 336.171 271.782L370.471 276.552C371.471 271.322 371.701 245.172 380.241 221.062C376.563 219.803 372.809 218.781 369.001 218.002C364.184 217.052 359.316 216.385 354.421 216.002C351.029 215.871 347.632 216.004 344.261 216.402Z"
          fill="#263238"
        />
        <path
          opacity="0.2"
          d="M336.16 271.781L370.47 276.551C371.08 273.281 371.41 261.801 373.66 247.751C374.26 244.001 375 240.061 375.93 236.061C377.086 230.984 378.525 225.976 380.24 221.061C376.564 219.797 372.809 218.775 369 218.001C364.18 217.052 359.308 216.384 354.41 216.001C351.035 215.881 347.655 216.018 344.3 216.411C344.3 216.411 334 234.711 336.16 271.781Z"
          fill="white"
        />
        <path
          d="M365.301 199.811C364.601 204.591 364.091 213.301 367.551 216.301C363.111 218.451 359.901 219.901 356.231 223.541C354.721 220.701 352.851 217.011 352.851 217.011C358.061 215.501 357.741 211.591 356.641 207.951L365.301 199.811Z"
          fill="#AE7461"
        />
        <path
          opacity="0.2"
          d="M361.77 203.131L356.65 208.001C356.915 208.847 357.109 209.713 357.23 210.591C359.23 210.201 361.89 207.851 362 205.731C362.087 204.857 362.009 203.975 361.77 203.131Z"
          fill="black"
        />
        <path
          opacity="0.5"
          d="M372.461 409.491H361.001C361.001 409.491 346.421 361.431 345.171 349.171C343.921 336.911 343.461 306.171 343.461 306.171V306.231C342.971 306.891 338.641 312.971 333.661 320.041C327.151 329.291 319.511 340.231 317.901 342.911C313.181 350.811 301.621 409.451 301.621 409.451H290.131C290.131 409.451 293.001 357.001 298.891 339.701C304.781 322.401 330.191 284.621 331.291 282.591C332.701 280.001 336.161 271.781 336.161 271.781L370.471 276.551C370.471 276.551 371.031 282.361 370.991 287.441C370.831 306.501 367.231 332.261 366.651 339.191C365.571 352.361 372.461 409.491 372.461 409.491Z"
          fill="white"
        />
        <path
          d="M373.24 218.981L370.39 215.421L361.16 217.201L356.47 222.571L355.88 216.371L354.15 214.291L350.36 215.931V219.811L353.06 218.941L355.39 224.351L362.77 221.321L364.41 223.411L373.24 218.981Z"
          fill="#263238"
        />
        <path
          opacity="0.3"
          d="M373.24 218.981L370.39 215.421L361.16 217.201L356.47 222.571L355.88 216.371L354.15 214.291L350.36 215.931V219.811L353.06 218.941L355.39 224.351L362.77 221.321L364.41 223.411L373.24 218.981Z"
          fill="white"
        />
        <path
          d="M348.451 225.581C338.936 225.531 329.434 224.883 320.001 223.641C311.771 221.441 304.391 210.171 300.341 203.151L304.181 200.471C309.181 205.651 314.121 211.541 320.441 214.931C320.899 215.151 321.394 215.287 321.901 215.331C330.681 215.711 339.821 215.731 348.661 216.131L348.421 225.581H348.451Z"
          fill="#DC897C"
        />
        <path
          d="M352.24 229.601C347.24 233.281 327.79 227.801 327.79 227.801L330.21 215.881C335.707 215.271 341.259 215.372 346.73 216.181C355 217.641 357.77 225.511 352.24 229.601Z"
          fill="#263238"
        />
        <path
          opacity="0.2"
          d="M352.24 229.601C347.24 233.281 327.79 227.801 327.79 227.801L330.21 215.881C335.707 215.271 341.259 215.372 346.73 216.181C355 217.641 357.77 225.511 352.24 229.601Z"
          fill="white"
        />
        <path
          opacity="0.1"
          d="M351.59 230.001C352 227.221 350.2 224.791 344.79 223.461C339.529 222.37 334.128 222.123 328.79 222.731L327.79 227.801C327.79 227.801 345.84 233.001 351.59 230.001Z"
          fill="black"
        />
        <path
          d="M307 204.321L303.23 195.881L296.48 200.311C296.48 200.311 298.28 206.571 303.64 207.061L307 204.321Z"
          fill="#AE7461"
        />
        <path
          d="M298.061 191.842L293.811 196.342L296.491 200.312L303.241 195.882L298.061 191.842Z"
          fill="#AE7461"
        />
        <path
          opacity="0.1"
          d="M355.39 224.351L354.5 217.871L350.36 219.811L353.06 218.941L355.39 224.351Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M355.391 224.35L362.231 219.32L364.411 223.41L362.771 221.32L355.391 224.35Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M373.66 247.75C374.26 244 375 240.06 375.93 236.06C374.823 234.687 373.25 233.769 371.51 233.48C371.51 233.48 370.57 242.56 373.66 247.75Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M343.461 306.191V306.251C342.971 306.911 338.641 312.991 333.661 320.061C333.17 316.229 333.824 312.337 335.541 308.875C337.258 305.414 339.962 302.539 343.311 300.611L343.461 306.191Z"
          fill="black"
        />
        <path
          d="M366.321 226.451L355.741 229.171L352.001 214.631L326.751 221.151L323.001 225.671L335.761 176.771L308.241 183.871L301.241 189.251L282.551 256.411L287.811 276.801L329.091 266.151L333.301 282.461L359.301 275.741L366.301 270.361L362.951 257.361L365.651 256.671L372.651 251.291L366.321 226.451ZM322.611 237.721L319.691 238.481L321.261 232.481L322.611 237.721Z"
          fill={PRIMARY_MAIN}
        />
        <path
          opacity="0.2"
          d="M326.611 179.141L308.271 183.871L326.611 179.141Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M330.5 235.69L326.74 221.15L323.03 225.67L328.73 203.83L321.11 233.03L321.26 232.47L322.61 237.72L319.69 238.48L330.5 235.69Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M340.331 277.081L336.121 260.771L294.841 271.421L289.571 251.031L308.271 183.871L301.251 189.251L282.561 256.411L287.821 276.801L329.101 266.151L333.311 282.461L359.351 275.741L366.371 270.361L340.331 277.081Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M372.74 251.321L362.16 254.051L364.97 264.931L363.02 257.391L365.72 256.701L372.74 251.321L369.53 238.881L372.74 251.321Z"
          fill="black"
        />
        <path
          d="M384.211 226.77C385.111 230.95 385.791 235.05 386.311 239.27C386.851 244.66 387.651 250.59 386.421 256.27C383.021 266.66 368.031 260.6 361.421 257.34L363.001 253C367.761 253.81 372.511 254.78 377.081 254.31C377.613 254.278 378.131 254.121 378.591 253.85C378.671 253.79 378.591 253.77 378.431 253.85C378.286 253.977 378.17 254.134 378.091 254.31C378.091 254.13 378.151 253.71 378.171 253.31C378.171 245.12 376.271 236.72 374.921 228.63L384.181 226.73L384.211 226.77Z"
          fill="#DC897C"
        />
        <path
          d="M380.24 221.062C389.45 224.932 388.86 246.902 388.86 246.902L376.32 247.782C373.938 242.307 371.992 236.653 370.5 230.872C368.62 222.702 374.11 218.502 380.24 221.062Z"
          fill="#263238"
        />
        <path
          opacity="0.2"
          d="M380.24 221.062C389.45 224.932 388.86 246.902 388.86 246.902L376.32 247.782C373.938 242.307 371.992 236.653 370.5 230.872C368.62 222.702 374.11 218.502 380.24 221.062Z"
          fill="white"
        />
        <path
          d="M365.601 253.602L358.541 247.602L354.381 254.532C354.381 254.532 358.721 259.392 363.761 257.532L365.601 253.602Z"
          fill="#AE7461"
        />
        <path
          d="M352.12 246.24L350.25 252.14L354.38 254.56L358.54 247.63L352.12 246.24Z"
          fill="#AE7461"
        />
        <path
          d="M350.61 184.521C347.53 186.271 346.56 195.521 350.83 197.071C355.1 198.621 356.45 181.221 350.61 184.521Z"
          fill="#263238"
        />
        <path
          d="M366.181 193C365.371 199.47 365.231 203.29 361.741 206.53C360.62 207.594 359.23 208.331 357.72 208.661C356.211 208.991 354.64 208.902 353.177 208.404C351.715 207.905 350.416 207.016 349.423 205.833C348.429 204.65 347.778 203.217 347.541 201.69C346.541 195.82 348.001 186.23 354.541 183.83C355.935 183.278 357.451 183.102 358.935 183.319C360.419 183.536 361.82 184.138 362.999 185.067C364.177 185.995 365.091 187.216 365.65 188.608C366.208 190.001 366.391 191.515 366.181 193Z"
          fill="#AE7461"
        />
        <path
          d="M359.471 185.761L359.381 180.171C360.835 180.718 362.425 180.788 363.921 180.371C370.361 178.301 375.731 182.101 372.971 186.031C376.181 187.121 374.811 191.501 372.971 194.381C370.391 198.381 367.471 200.381 365.211 198.871C363.211 198.091 362.401 191.561 362.831 189.081C363.471 185.341 359.471 185.761 359.471 185.761Z"
          fill="#263238"
        />
        <path
          d="M368.24 199.48C367.326 200.812 365.98 201.787 364.43 202.24C362.43 202.77 361.84 200.95 362.7 199.07C363.46 197.37 365.42 195.07 367.25 195.37C369.08 195.67 369.39 197.81 368.24 199.48Z"
          fill="#AE7461"
        />
        <path
          d="M356.22 194.531C356.22 195.061 355.88 195.481 355.54 195.471C355.2 195.461 354.94 195.021 354.97 194.471C355 193.921 355.31 193.521 355.66 193.531C356.01 193.541 356.26 194.001 356.22 194.531Z"
          fill="#263238"
        />
        <path
          d="M350.25 194.361C350.25 194.891 349.91 195.311 349.57 195.301C349.23 195.291 348.97 194.861 349 194.301C349.03 193.741 349.34 193.301 349.69 193.361C350.04 193.421 350.28 193.841 350.25 194.361Z"
          fill="#263238"
        />
        <path
          d="M352.13 194.682C351.398 196.279 350.457 197.773 349.33 199.122C349.679 199.391 350.083 199.579 350.514 199.674C350.944 199.769 351.39 199.769 351.82 199.672L352.13 194.682Z"
          fill="#DE5753"
        />
        <path
          d="M358.191 192.66C358.244 192.649 358.294 192.629 358.341 192.6C358.406 192.552 358.451 192.481 358.466 192.401C358.48 192.321 358.464 192.239 358.421 192.17C358.153 191.763 357.789 191.428 357.363 191.192C356.936 190.957 356.458 190.829 355.971 190.82C355.929 190.82 355.889 190.829 355.851 190.845C355.813 190.861 355.779 190.885 355.751 190.915C355.723 190.945 355.701 190.981 355.687 191.019C355.674 191.058 355.668 191.099 355.671 191.14C355.676 191.222 355.712 191.298 355.771 191.354C355.831 191.41 355.909 191.44 355.991 191.44C356.374 191.451 356.749 191.556 357.084 191.744C357.418 191.932 357.702 192.198 357.911 192.52C357.939 192.568 357.98 192.607 358.03 192.631C358.08 192.656 358.135 192.666 358.191 192.66Z"
          fill="#263238"
        />
        <path
          d="M347.811 192.241C347.863 192.238 347.913 192.222 347.957 192.194C348.001 192.166 348.037 192.127 348.061 192.081C348.243 191.729 348.504 191.424 348.823 191.188C349.142 190.952 349.511 190.793 349.901 190.721C349.942 190.717 349.981 190.706 350.017 190.686C350.054 190.667 350.086 190.641 350.112 190.61C350.138 190.578 350.157 190.541 350.169 190.502C350.181 190.463 350.185 190.422 350.181 190.381C350.177 190.34 350.165 190.301 350.146 190.265C350.127 190.228 350.101 190.196 350.069 190.17C350.038 190.144 350.001 190.125 349.962 190.113C349.923 190.101 349.882 190.097 349.841 190.101C349.349 190.179 348.883 190.371 348.478 190.661C348.074 190.951 347.743 191.331 347.511 191.771C347.492 191.807 347.48 191.847 347.476 191.887C347.472 191.928 347.476 191.969 347.488 192.008C347.501 192.047 347.52 192.084 347.546 192.115C347.573 192.146 347.605 192.172 347.641 192.191C347.689 192.228 347.75 192.246 347.811 192.241Z"
          fill="#263238"
        />
        <path
          d="M364.37 181.051C364.37 181.051 358.56 172.891 350.32 175.901C351.171 175.958 352.003 176.178 352.77 176.551C352.77 176.551 345.27 176.961 343.7 182.491L345 181.491C345 181.491 343.73 186.071 346.34 188.601L346.56 187.111C346.56 187.111 350.13 190.451 354.5 188.731L352.82 188.161C352.82 188.161 360.59 186.811 362.24 184.551C363.077 183.466 363.791 182.292 364.37 181.051Z"
          fill="#263238"
        />
        <path
          d="M350.79 201.592C353.31 200.679 355.912 200.01 358.56 199.592C358.21 201.462 357.21 203.122 354.23 203.652C353.869 203.756 353.49 203.785 353.117 203.737C352.744 203.688 352.385 203.563 352.062 203.37C351.739 203.177 351.46 202.919 351.241 202.613C351.022 202.307 350.869 201.96 350.79 201.592Z"
          fill="#263238"
        />
        <path
          d="M355.35 202.071C354.201 202.221 353.127 202.729 352.28 203.521C352.909 203.797 353.612 203.86 354.28 203.701C355.432 203.557 356.507 203.049 357.35 202.251C356.721 201.974 356.019 201.911 355.35 202.071Z"
          fill="#A02724"
        />
        <path
          d="M351 201.492C351.098 201.695 351.249 201.867 351.438 201.99C351.626 202.114 351.845 202.183 352.07 202.192C353.789 201.865 355.478 201.397 357.12 200.792C357.389 200.717 357.635 200.576 357.835 200.382C358.036 200.188 358.186 199.948 358.27 199.682C355.805 200.102 353.374 200.707 351 201.492Z"
          fill="white"
        />
        <path
          d="M248.67 417.08C253.74 417.08 254.42 417 257.27 416.9C259.71 416.82 260.27 417.12 262.27 417.08C263.91 417.08 263.85 415.47 263.34 413.67C263.09 412.76 262.7 410.84 262.34 409.38L254.9 408.84C253.85 410.84 251.49 413.68 248.63 414.07C245.77 414.46 246.65 417.08 248.67 417.08Z"
          fill={PRIMARY_MAIN}
        />
        <path
          d="M253.229 400C256.065 397.262 258.575 394.205 260.709 390.89C262.099 388.89 262.359 389.41 263.839 387.47C264.038 387.163 264.161 386.814 264.2 386.451C264.239 386.088 264.191 385.72 264.062 385.379C263.932 385.037 263.724 384.731 263.455 384.484C263.185 384.238 262.861 384.058 262.509 383.96C261.211 383.7 259.939 383.322 258.709 382.83L253.639 386.56C254.569 388.95 253.349 395.68 250.739 398.45C248.869 400.44 251.749 401.36 253.229 400Z"
          fill={PRIMARY_MAIN}
        />
        <path
          d="M236.21 277C236.21 277 221.62 318.7 219.73 334C217.89 348.89 254 387.71 254 387.71C254.88 388.37 258.5 383.87 258.7 382.71C249.7 365.35 244.13 339.63 235.11 333.33C256.74 301.26 259.76 280.75 259.76 280.75L236.21 277Z"
          fill="#263238"
        />
        <path
          d="M274.429 206.68C279.479 214.28 278.899 218.01 277.289 233C275.679 247.99 281.019 254.29 278.429 261.19C275.839 268.09 257.269 267.51 248.999 253.69C240.729 239.87 251.139 210.17 258.999 207.27C263.97 205.546 269.342 205.34 274.429 206.68Z"
          fill="#263238"
        />
        <path
          opacity="0.2"
          d="M246.46 291.67C246.46 291.67 245.27 307 245 317.43C247.86 312.34 250.18 307.7 252.06 303.55L246.46 291.67Z"
          fill="black"
        />
        <path
          d="M243.279 278.111C243.279 278.111 248.709 320.111 251.819 340.331C255.079 361.551 255.499 395.611 254.679 409.491C255.869 410.181 260.179 409.701 262.519 408.971C262.519 408.971 276.609 363.441 266.259 342.421C272.959 293.311 263.259 281.281 263.259 281.281L243.279 278.111Z"
          fill="#263238"
        />
        <path
          d="M246.86 248.851C242.6 253.842 237.529 258.078 231.86 261.381C221.25 267.561 218.86 249.781 218.53 242.911C218.521 242.412 218.693 241.927 219.012 241.544C219.332 241.161 219.779 240.906 220.271 240.825C220.763 240.743 221.268 240.842 221.693 241.102C222.119 241.362 222.437 241.766 222.59 242.241C223.59 245.241 224.65 248.301 225.85 251.071C226.62 252.711 227.45 254.701 228.65 255.601C228.65 255.331 227.57 255.261 227.38 255.391H227.22L228.61 254.391C232.908 251.111 236.901 247.449 240.54 243.451C244.22 239.641 250.03 244.661 246.83 248.851H246.86Z"
          fill="#AE7461"
        />
        <path
          d="M273.78 248.64C266.2 265.02 263.93 273.56 263.27 281.28L236.21 277C236.83 268.06 237.29 262.71 237.94 257.3C238.287 253.597 238.885 249.922 239.73 246.3C240.46 242.45 243.5 238.47 247.24 237.16C248.101 236.843 249.013 236.687 249.93 236.7C251.28 236.7 252.69 236.78 254.05 236.87C258.187 237.231 262.293 237.879 266.34 238.81C267.5 239.06 268.62 239.33 269.68 239.61C269.9 239.67 270.13 239.75 270.34 239.83C271.171 240.149 271.926 240.639 272.557 241.266C273.188 241.894 273.681 242.647 274.004 243.476C274.328 244.305 274.475 245.192 274.437 246.081C274.398 246.971 274.174 247.842 273.78 248.64Z"
          fill={PRIMARY_MAIN}
        />
        <path
          d="M267.67 224.811C265.97 228.601 263.51 235.641 265.67 238.681C265.67 238.681 262.02 237.531 253.29 241.181C251.51 237.521 253.38 236.741 253.38 236.741C258.04 236.391 258.68 233.141 258.63 229.991L267.67 224.811Z"
          fill="#AE7461"
        />
        <path
          opacity="0.2"
          d="M264 226.92L258.63 230C258.647 230.742 258.604 231.485 258.5 232.22C260.24 232.22 262.98 230.78 263.57 229.07C263.847 228.387 263.993 227.657 264 226.92Z"
          fill="black"
        />
        <path
          opacity="0.6"
          d="M273.78 248.64C272.933 250.46 272.153 252.19 271.44 253.83C269.38 258.54 267.82 262.53 266.64 266.02C264.886 270.946 263.754 276.073 263.27 281.28L236.21 277C236.83 268.06 237.29 262.71 237.94 257.3C238.287 253.597 238.885 249.922 239.73 246.3C240.46 242.45 243.5 238.47 247.24 237.16C248.101 236.843 249.013 236.687 249.93 236.7C251.28 236.7 252.69 236.78 254.05 236.87C258.187 237.231 262.293 237.879 266.34 238.81C267.5 239.06 268.62 239.33 269.68 239.61C269.9 239.67 270.13 239.75 270.34 239.83C271.171 240.149 271.926 240.639 272.557 241.266C273.188 241.894 273.681 242.647 274.004 243.476C274.328 244.305 274.475 245.192 274.437 246.081C274.398 246.971 274.174 247.842 273.78 248.64Z"
          fill="white"
        />
        <path
          d="M272.299 217.411C270.089 223.611 269.299 226.271 265.159 228.751C258.949 232.491 251.799 228.861 252.109 222.241C252.389 216.241 255.959 207.151 262.879 206.081C264.356 205.803 265.88 205.925 267.294 206.434C268.708 206.943 269.96 207.821 270.921 208.976C271.882 210.132 272.516 211.523 272.758 213.006C273.001 214.489 272.842 216.01 272.299 217.411Z"
          fill="#AE7461"
        />
        <path
          d="M265 203.141C260.19 203.141 253.15 207.571 259.26 208.881C259.2 210.881 262.8 211.011 265.45 210.081C265.02 211.511 267.33 214.461 270.65 213.721C268.71 214.671 268.37 219.591 270.65 219.981C272.93 220.371 277.65 221.841 277.24 213.721C276.83 205.601 270.15 203.141 265 203.141Z"
          fill="#263238"
        />
        <path
          d="M263.999 217.191C263.829 217.711 263.419 218.081 263.079 218.001C262.739 217.921 262.599 217.421 262.759 216.891C262.919 216.361 263.329 216.001 263.669 216.081C264.009 216.161 264.139 216.661 263.999 217.191Z"
          fill="#263238"
        />
        <path
          d="M257.369 215.42C257.199 215.95 256.799 216.31 256.449 216.23C256.099 216.15 255.969 215.66 256.129 215.13C256.289 214.6 256.699 214.24 257.039 214.32C257.379 214.4 257.529 214.89 257.369 215.42Z"
          fill="#263238"
        />
        <path
          d="M257.19 214.381L256.04 213.721C256.04 213.721 256.38 214.861 257.19 214.381Z"
          fill="#263238"
        />
        <path
          d="M259 217.061C258.16 218.155 257.185 219.14 256.1 219.991C256.313 220.246 256.581 220.45 256.884 220.587C257.187 220.723 257.517 220.79 257.85 220.781L259 217.061Z"
          fill="#AE7461"
        />
        <path
          d="M260.3 223.44C259.997 223.42 259.696 223.377 259.4 223.31C259.379 223.306 259.359 223.297 259.341 223.284C259.324 223.271 259.309 223.255 259.299 223.236C259.288 223.217 259.282 223.196 259.281 223.174C259.279 223.152 259.282 223.131 259.29 223.11C259.304 223.07 259.332 223.037 259.369 223.017C259.406 222.996 259.449 222.991 259.49 223C260.159 223.163 260.856 223.171 261.528 223.023C262.2 222.876 262.83 222.577 263.37 222.15C263.385 222.135 263.403 222.123 263.423 222.114C263.442 222.106 263.464 222.102 263.485 222.102C263.506 222.102 263.528 222.106 263.547 222.114C263.567 222.123 263.585 222.135 263.6 222.15C263.615 222.164 263.627 222.181 263.635 222.2C263.644 222.219 263.648 222.24 263.648 222.26C263.648 222.281 263.644 222.301 263.635 222.32C263.627 222.339 263.615 222.356 263.6 222.37C262.672 223.126 261.495 223.508 260.3 223.44Z"
          fill="#263238"
        />
        <path
          d="M272.89 223.08C271.917 224.082 270.586 224.657 269.19 224.68C267.4 224.68 267.12 222.83 268.12 221.3C269.12 219.77 271.05 218.25 272.6 219C274.15 219.75 274.13 221.81 272.89 223.08Z"
          fill="#AE7461"
        />
        <path
          d="M266 214.111C265.958 214.104 265.918 214.089 265.882 214.067C265.846 214.044 265.815 214.015 265.79 213.981C265.586 213.647 265.302 213.369 264.963 213.173C264.624 212.978 264.241 212.87 263.85 212.861C263.768 212.861 263.689 212.828 263.631 212.77C263.573 212.712 263.54 212.633 263.54 212.551C263.547 212.465 263.584 212.385 263.644 212.325C263.705 212.265 263.785 212.228 263.87 212.221C264.366 212.225 264.852 212.354 265.284 212.597C265.716 212.84 266.08 213.189 266.34 213.611C266.385 213.682 266.4 213.769 266.381 213.851C266.362 213.934 266.311 214.005 266.24 214.051C266.17 214.098 266.085 214.12 266 214.111Z"
          fill="#263238"
        />
        <path
          d="M255.71 211.302C255.671 211.303 255.631 211.295 255.595 211.28C255.559 211.264 255.526 211.241 255.5 211.212C255.47 211.183 255.445 211.148 255.429 211.109C255.412 211.07 255.403 211.029 255.403 210.987C255.403 210.944 255.412 210.903 255.429 210.864C255.445 210.825 255.47 210.791 255.5 210.762C255.861 210.385 256.304 210.098 256.796 209.925C257.288 209.751 257.813 209.695 258.33 209.762C258.37 209.77 258.408 209.786 258.441 209.809C258.474 209.833 258.502 209.863 258.522 209.898C258.543 209.933 258.556 209.971 258.561 210.012C258.566 210.052 258.562 210.093 258.55 210.132C258.529 210.215 258.477 210.287 258.404 210.333C258.332 210.38 258.245 210.397 258.16 210.382C257.75 210.337 257.336 210.385 256.948 210.524C256.56 210.662 256.208 210.887 255.92 211.182C255.866 211.245 255.792 211.287 255.71 211.302Z"
          fill="#263238"
        />
        <path
          d="M263.789 216.15L262.649 215.49C262.649 215.49 262.999 216.63 263.789 216.15Z"
          fill="#263238"
        />
        <path
          d="M251.5 237.361C246.22 235.361 242.03 237.831 235.62 245.071C236.07 248.781 242.31 254.371 242.31 254.371C242.31 254.371 253 244.221 251.5 237.361Z"
          fill={PRIMARY_MAIN}
        />
        <path
          d="M270.339 239.802C268.158 240.921 265.918 241.922 263.629 242.802C263.039 243.012 262.439 243.232 261.829 243.432C258.349 244.602 254.709 245.332 252.109 244.562C250.975 244.314 249.934 243.75 249.106 242.937C248.278 242.123 247.697 241.091 247.429 239.962C247.208 239.022 247.147 238.052 247.249 237.092C248.11 236.775 249.022 236.619 249.939 236.632H250.849C251.249 236.632 251.629 236.632 252.029 236.632L254.029 236.742C258.166 237.102 262.273 237.751 266.319 238.682L266.579 238.742L267.489 238.942C268.229 239.112 268.959 239.292 269.659 239.482C269.899 239.642 270.129 239.722 270.339 239.802Z"
          fill="#AE7461"
        />
        <path
          d="M267.51 239.001C266.069 240.107 264.766 241.383 263.63 242.801C263.04 243.011 262.44 243.231 261.83 243.431C263.134 241.614 264.753 240.046 266.61 238.801L267.51 239.001Z"
          fill="#263238"
        />
        <path
          d="M251.999 236.731C250.424 238.031 249.013 239.518 247.799 241.161C247.629 240.788 247.495 240.4 247.399 240.001C248.395 238.76 249.544 237.651 250.819 236.701C251.239 236.701 251.619 236.721 251.999 236.731Z"
          fill="#263238"
        />
        <path
          opacity="0.2"
          d="M267.51 239.001C266.069 240.107 264.766 241.383 263.63 242.801C263.04 243.011 262.44 243.231 261.83 243.431C263.134 241.614 264.753 240.046 266.61 238.801L267.51 239.001Z"
          fill="white"
        />
        <path
          opacity="0.2"
          d="M251.999 236.731C250.424 238.031 249.013 239.518 247.799 241.161C247.629 240.788 247.495 240.4 247.399 240.001C248.395 238.76 249.544 237.651 250.819 236.701C251.239 236.701 251.619 236.721 251.999 236.731Z"
          fill="white"
        />
        <path
          opacity="0.1"
          d="M271.44 253.831C269.38 258.541 267.82 262.531 266.64 266.021C256.77 259.911 264.92 249.621 264.92 249.621C267.409 250.46 269.65 251.907 271.44 253.831Z"
          fill="black"
        />
        <path
          d="M222.08 209.571C198.92 208.241 181.32 226.081 179.47 258.571C179.292 261.525 179.269 264.485 179.4 267.441C178.4 298.551 193.66 317.361 216.25 318.651C239.41 319.971 257.01 302.131 258.86 269.651C259.037 266.698 259.06 263.737 258.93 260.781C259.92 229.671 244.67 210.861 222.08 209.571ZM229.16 261.431C228.09 280.351 223.49 285.991 217.73 285.671C213.06 285.401 209.6 281.311 209.04 269.671C209.04 268.751 209.11 267.801 209.16 266.811C210.24 247.891 214.83 242.251 220.59 242.581C225.27 242.841 228.73 246.931 229.29 258.581C229.26 259.501 229.22 260.441 229.16 261.431Z"
          fill={PRIMARY_MAIN}
        />
        <path
          opacity="0.2"
          d="M258.84 253.361C258.84 252.891 258.84 252.421 258.75 251.951C258.79 252.421 258.81 252.891 258.84 253.361Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M179.36 266.371C179.36 265.931 179.36 265.491 179.36 265.051C179.33 265.491 179.35 265.931 179.36 266.371Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M179.47 258.751C181.32 226.231 198.92 208.391 222.08 209.751C241.58 210.861 255.62 225.021 258.46 248.891C255.66 224.891 241.62 210.711 222.08 209.601C198.92 208.271 181.32 226.111 179.47 258.601C179.41 259.601 179.37 260.601 179.35 261.601C179.37 260.681 179.41 259.721 179.47 258.751Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M258.94 255.59C258.94 255.36 258.94 255.14 258.94 254.91C258.93 255.13 258.93 255.36 258.94 255.59Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M258.679 251.111C258.619 250.421 258.559 249.731 258.479 249.061C258.559 249.731 258.619 250.421 258.679 251.111Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M179.319 264.13C179.319 263.42 179.319 262.71 179.319 261.99C179.329 262.71 179.319 263.42 179.319 264.13Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M258.84 263.271C256.98 295.791 239.38 313.621 216.22 312.271C195.22 311.081 180.59 294.781 179.39 267.551C178.44 298.551 193.68 317.361 216.25 318.651C239.41 319.971 257.01 302.131 258.86 269.651C259.037 266.728 259.061 263.797 258.93 260.871C258.91 261.664 258.88 262.464 258.84 263.271Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M209.14 260.441C208.06 279.361 211.98 285.491 217.74 285.811C223.5 286.131 228.09 280.501 229.17 261.581C230.25 242.661 226.32 236.581 220.57 236.201C214.82 235.821 210.21 241.521 209.14 260.441ZM229.29 258.571C229.29 259.501 229.22 260.441 229.16 261.431C228.09 280.351 223.49 285.991 217.73 285.671C213.06 285.401 209.6 281.311 209.04 269.671C209.04 268.751 209.11 267.801 209.16 266.811C210.24 247.891 214.83 242.251 220.59 242.581C225.27 242.841 228.73 246.931 229.29 258.571Z"
          fill="black"
        />
        <path
          d="M214.409 240.001L214.689 241.761C214.74 242.09 214.87 242.401 215.066 242.669C215.263 242.937 215.521 243.154 215.819 243.301L218.629 244.701C219.182 244.978 219.794 245.117 220.412 245.106C221.03 245.096 221.636 244.936 222.179 244.641L222.869 244.281C222.599 241.281 220.139 238.281 220.139 238.281L219.529 237.341C219.409 237.147 219.251 236.978 219.065 236.844C218.879 236.711 218.669 236.615 218.446 236.563C218.223 236.511 217.992 236.504 217.766 236.541C217.54 236.578 217.324 236.66 217.129 236.781L215.269 238.001C214.948 238.216 214.696 238.519 214.544 238.874C214.391 239.229 214.344 239.62 214.409 240.001Z"
          fill="#AE7461"
        />
        <path
          d="M236.12 258.381L238.12 257.941C238.49 257.858 238.875 257.864 239.242 257.96C239.609 258.055 239.948 258.237 240.23 258.491L242.9 260.901C243.427 261.372 243.833 261.964 244.084 262.625C244.334 263.286 244.422 263.998 244.34 264.701L244.24 265.591C240.98 266.591 236.75 265.191 236.75 265.191L235.5 264.931C234.981 264.836 234.521 264.54 234.22 264.107C233.918 263.674 233.8 263.14 233.89 262.621L234.36 260.141C234.454 259.709 234.669 259.314 234.981 259.002C235.293 258.69 235.689 258.474 236.12 258.381Z"
          fill="#AE7461"
        />
        <path
          d="M274.19 253.521C272.413 261.256 269.004 268.522 264.19 274.831C257.69 281.731 244.47 269.011 239.79 264.311C239.452 263.944 239.257 263.467 239.243 262.968C239.229 262.469 239.395 261.982 239.712 261.596C240.028 261.21 240.474 260.952 240.966 260.868C241.458 260.784 241.964 260.882 242.39 261.141C245.55 263.141 248.86 265.201 252.16 266.931C254.37 268.051 256.7 269.281 258.95 269.531C259.68 269.531 259.21 269.351 258.75 269.741L258.21 270.321C259.115 268.938 259.943 267.506 260.69 266.031C262.949 261.359 264.764 256.484 266.11 251.471C266.464 250.505 267.164 249.705 268.074 249.226C268.984 248.746 270.04 248.623 271.037 248.878C272.034 249.133 272.9 249.75 273.468 250.608C274.036 251.466 274.264 252.504 274.11 253.521H274.19Z"
          fill="#AE7461"
        />
        <path
          d="M271.77 240.541C276.38 243.811 276.6 248.691 274.11 258.041C270.77 259.711 262.67 257.601 262.67 257.601C262.67 257.601 265.22 243.081 271.77 240.541Z"
          fill={PRIMARY_MAIN}
        />
        <path
          opacity="0.6"
          d="M271.77 240.541C276.38 243.811 276.6 248.691 274.11 258.041C270.77 259.711 262.67 257.601 262.67 257.601C262.67 257.601 265.22 243.081 271.77 240.541Z"
          fill="white"
        />
        <path
          d="M184.31 402.101L190.52 399.851L187.1 384.881L180.89 387.131L184.31 402.101Z"
          fill="#AE7461"
        />
        <path
          d="M135.32 410.35H141.91L143.79 395.08H137.19L135.32 410.35Z"
          fill="#AE7461"
        />
        <path
          d="M142.78 409.59H134.78C134.649 409.59 134.522 409.634 134.42 409.716C134.318 409.798 134.248 409.912 134.22 410.04L132.86 415.9C132.835 416.045 132.843 416.194 132.881 416.335C132.92 416.477 132.99 416.608 133.086 416.72C133.181 416.832 133.3 416.921 133.434 416.982C133.568 417.042 133.713 417.072 133.86 417.07C136.45 417.02 138.33 416.87 141.59 416.87C143.59 416.87 149.64 417.08 152.4 417.08C155.16 417.08 155.56 414.35 154.4 414.08C149.33 412.97 145.52 411.43 143.91 409.97C143.596 409.704 143.192 409.568 142.78 409.59Z"
          fill="#263238"
        />
        <path
          d="M190.75 398.581L183.69 400.101C183.563 400.127 183.448 400.196 183.365 400.296C183.281 400.396 183.234 400.521 183.23 400.651L183.13 406.651C183.137 406.791 183.173 406.929 183.236 407.055C183.3 407.181 183.39 407.292 183.499 407.381C183.609 407.469 183.736 407.534 183.872 407.57C184.008 407.606 184.151 407.613 184.29 407.591C186.83 407.001 190.48 406.071 193.68 405.381C197.42 404.581 199.74 403.821 204.12 402.881C206.77 402.311 206.96 399.481 205.8 399.481C200.52 399.481 197.32 400.481 192.41 398.741C191.882 398.535 191.307 398.479 190.75 398.581Z"
          fill="#263238"
        />
        <path
          opacity="0.2"
          d="M187.1 384.881L188.86 392.601L182.65 394.851L180.89 387.141L187.1 384.881Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M137.19 395.09H143.79L142.82 402.96H136.22L137.19 395.09Z"
          fill="black"
        />
        <path
          d="M190.68 390.319L180.17 394.129C180.17 394.129 172 357.999 167.58 337.899C165.644 329.962 163.254 322.142 160.42 314.479C156.42 303.219 151.67 291.099 147.82 281.629C143.76 271.629 140.74 264.629 140.74 264.629L163.48 260.029C163.48 260.029 180.83 309.949 184.73 329.689C188.78 350.179 190.68 390.319 190.68 390.319Z"
          fill="#263238"
        />
        <g opacity="0.3">
          <path
            d="M190.68 390.319L180.17 394.129C180.17 394.129 172 357.999 167.58 337.899C165.644 329.962 163.254 322.142 160.42 314.479C156.42 303.219 151.67 291.099 147.82 281.629C143.76 271.629 140.74 264.629 140.74 264.629L163.48 260.029C163.48 260.029 180.83 309.949 184.73 329.689C188.78 350.179 190.68 390.319 190.68 390.319Z"
            fill="white"
          />
        </g>
        <path
          opacity="0.2"
          d="M160.42 314.479C156.42 303.219 151.67 291.099 147.82 281.629L149.58 276.189C156.09 282.209 160.15 302.999 160.42 314.479Z"
          fill="black"
        />
        <path
          d="M151.21 262.49C151.21 262.49 155.27 319.42 155.52 338.76C155.79 359.94 145.88 401.43 145.88 401.43H133.81C133.81 401.43 138.63 364 137 343C135.2 319.54 128.87 267 128.87 267L151.21 262.49Z"
          fill="#263238"
        />
        <g opacity="0.3">
          <path
            d="M151.21 262.49C151.21 262.49 155.27 319.42 155.52 338.76C155.79 359.94 145.88 401.43 145.88 401.43H133.81C133.81 401.43 138.63 364 137 343C135.2 319.54 128.87 267 128.87 267L151.21 262.49Z"
            fill="white"
          />
        </g>
        <path
          d="M147.32 401.69H133.11L133.15 397.44L147.99 396.59L147.32 401.69Z"
          fill="#263238"
        />
        <path
          d="M192.09 390.18L179.49 394.56L178.12 390.49L191.9 385.48L192.09 390.18Z"
          fill="#263238"
        />
        <path
          d="M143.23 410.499C143.2 410.494 143.172 410.479 143.151 410.458C143.13 410.436 143.115 410.409 143.11 410.379C143.105 410.349 143.108 410.317 143.121 410.289C143.133 410.26 143.154 410.236 143.18 410.219C143.42 410.069 145.57 408.759 146.29 409.139C146.371 409.18 146.438 409.243 146.485 409.321C146.531 409.399 146.553 409.489 146.55 409.579C146.567 409.732 146.546 409.888 146.488 410.031C146.431 410.174 146.338 410.301 146.22 410.399C145.847 410.652 145.399 410.772 144.95 410.739C144.369 410.725 143.792 410.644 143.23 410.499ZM143.68 410.279C144.445 410.546 145.285 410.503 146.02 410.159C146.099 410.091 146.161 410.004 146.199 409.907C146.238 409.809 146.252 409.703 146.24 409.599C146.24 409.469 146.19 409.429 146.14 409.409C146.074 409.397 146.006 409.397 145.94 409.409C145.138 409.554 144.371 409.849 143.68 410.279Z"
          fill={PRIMARY_MAIN}
        />
        <path
          d="M143.18 410.479C143.154 410.467 143.134 410.446 143.121 410.421C143.109 410.396 143.104 410.367 143.11 410.339C143.11 410.259 143.29 408.339 144.11 407.729C144.2 407.652 144.305 407.595 144.419 407.56C144.532 407.526 144.652 407.515 144.77 407.529C144.888 407.528 145.003 407.568 145.095 407.643C145.186 407.718 145.248 407.823 145.27 407.939C145.4 408.669 144.06 410.119 143.38 410.489H143.3C143.281 410.5 143.259 410.504 143.238 410.502C143.217 410.501 143.196 410.493 143.18 410.479ZM144.26 407.969C143.793 408.565 143.515 409.285 143.46 410.039C144.14 409.519 145 408.429 144.92 408.039C144.92 408.039 144.92 407.899 144.69 407.879H144.62C144.489 407.876 144.362 407.919 144.26 407.999V407.969Z"
          fill={PRIMARY_MAIN}
        />
        <path
          d="M203.29 179.549L208.57 173.869L211.73 181.869C211.73 181.869 206.73 186.649 204.36 183.969L203.29 179.549Z"
          fill="#AE7461"
        />
        <path
          d="M215.97 171.779L216.35 179.579L211.73 181.869L208.57 173.869L215.97 171.779Z"
          fill="#AE7461"
        />
        <path
          d="M191.44 399.239C191.43 399.208 191.429 399.174 191.438 399.142C191.447 399.11 191.465 399.081 191.49 399.059C191.75 398.849 194.07 396.969 194.95 397.239C195.032 397.266 195.107 397.312 195.166 397.375C195.226 397.438 195.268 397.515 195.29 397.599C195.333 397.734 195.343 397.877 195.319 398.017C195.294 398.156 195.236 398.287 195.15 398.399C194.57 399.179 192.67 399.319 191.59 399.339C191.558 399.338 191.527 399.329 191.5 399.311C191.474 399.293 191.453 399.268 191.44 399.239ZM192.05 399.009C193.53 398.939 194.58 398.649 194.9 398.219C194.959 398.144 194.998 398.055 195.014 397.961C195.03 397.867 195.021 397.77 194.99 397.679C194.99 397.609 194.93 397.559 194.86 397.539H194.8C194.27 397.469 193 398.299 192.05 398.999V399.009Z"
          fill={PRIMARY_MAIN}
        />
        <path
          d="M116.06 211.18C110.37 213.42 92.38 228.18 91.71 234.61C91.04 241.04 112.05 248.24 112.05 248.24L117.15 243.91C117.15 243.91 104.36 234.08 104.36 232.91C104.36 231.74 120.36 223.29 120.36 223.29C121.511 220.541 121.656 217.475 120.77 214.63C119.71 211.66 116.06 211.18 116.06 211.18Z"
          fill="#263238"
        />
        <path
          d="M152.34 204.16C160.85 203.51 176.27 198.65 180.41 196C184.55 193.35 203.29 178.84 203.29 178.84L208.63 184.99C208.63 184.99 194.52 201.38 186.43 206.68C178.34 211.98 156.33 220.33 156.33 220.33L152.34 204.16Z"
          fill="#263238"
        />
        <path
          d="M191.52 399.321C191.497 399.31 191.476 399.293 191.46 399.272C191.445 399.251 191.434 399.227 191.43 399.201C191.307 398.272 191.479 397.327 191.92 396.501C192.016 396.376 192.137 396.273 192.274 396.197C192.412 396.122 192.564 396.075 192.72 396.061C193.15 396.011 193.31 396.201 193.38 396.361C193.64 397.071 192.38 398.791 191.68 399.311H191.57L191.52 399.321ZM192.75 396.371C192.635 396.38 192.524 396.414 192.423 396.471C192.323 396.528 192.237 396.606 192.17 396.701C191.8 397.361 191.643 398.118 191.72 398.871C192.42 398.221 193.24 396.871 193.08 396.481C193.08 396.481 193.08 396.381 192.91 396.371H192.75Z"
          fill={PRIMARY_MAIN}
        />
        <path
          d="M116.06 211.179C117.06 213.619 118 216.089 118.84 218.559C119.766 221.279 120.606 223.986 121.36 226.679C121.88 228.519 122.36 230.359 122.8 232.159C122.96 232.769 123.1 233.389 123.24 233.989C123.59 235.439 123.9 236.869 124.24 238.279C124.58 239.689 124.79 240.949 125.04 242.279C125.29 243.609 125.57 245.029 125.8 246.349C126.03 247.669 126.24 248.939 126.44 250.169C126.64 251.399 126.86 252.859 127.04 254.169C127.27 255.749 127.47 257.229 127.65 258.589C128.21 262.679 128.59 265.669 128.93 267.099L163.52 260.099C163.52 257.389 163.52 254.779 163.39 252.259C163.33 250.579 163.25 248.949 163.13 247.359C163.07 246.309 162.99 245.279 162.9 244.259C162.77 242.719 162.62 241.259 162.46 239.759C162.26 238.129 162.05 236.559 161.82 235.039C161.4 232.239 160.933 229.636 160.42 227.229C159.79 224.309 159.12 221.669 158.42 219.309C157.56 216.309 156.68 213.829 155.88 211.739C154.923 209.147 153.745 206.641 152.36 204.249C149.538 204.092 146.709 204.112 143.89 204.309C143.29 204.309 142.69 204.409 142.08 204.479C137.122 205.191 132.21 206.193 127.37 207.479C126.46 207.709 125.52 207.979 124.62 208.249C121.719 209.081 118.862 210.058 116.06 211.179Z"
          fill="#263238"
        />
        <path
          d="M125 190.461C127 195.021 129.77 203.461 126.91 207.331C128.808 208.575 130.94 209.418 133.176 209.808C135.411 210.198 137.703 210.128 139.91 209.601C144.22 208.711 143.84 205.811 142.55 204.421C136.69 204.201 136.01 200.251 136.22 196.421L125 190.461Z"
          fill="#AE7461"
        />
        <path
          opacity="0.2"
          d="M126.75 193L136.19 196.35C136.124 197.25 136.144 198.154 136.25 199.05C129.48 200.87 126.67 193.56 126.75 193Z"
          fill="black"
        />
        <path
          d="M148.14 301.91L159.51 278.91L157.61 271.23L153.32 269.12L157.32 261.07L155.41 253.39L139.3 245.44L170.3 222.07L168.4 214.4L145.31 203L90.31 243.16L81 262L82.91 269.68L112.91 284.49L108.13 294.17L110.03 301.85L131.84 312.62L139.3 297.51L148.14 301.91ZM127.6 256.38L125.85 255.52L129.31 252.91L127.6 256.38Z"
          fill={PRIMARY_MAIN}
        />
        <path
          opacity="0.2"
          d="M153.32 269.08L157.29 261.03L155.4 253.35L139.28 245.4L170.26 222.03L168.36 214.35L145.31 202.98L90.31 243.14L90.25 243.25L145.24 203.1L168.29 214.48L118.47 252.04L127.52 256.51L127.59 256.38L125.85 255.52L129.29 252.93L134.17 243.04L155.32 253.48L148.67 266.95L157.53 271.32L146.16 294.36L137.3 289.98L129.84 305.09L108.15 294.38L110.01 301.87L131.82 312.64L139.28 297.53L148.14 301.9L159.51 278.87L157.61 271.19L153.32 269.08Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M115.49 279.219L81.0303 262.209L82.8903 269.699L112.88 284.499L115.49 279.219Z"
          fill="black"
        />
        <path
          d="M115.79 243.91L123.14 246.39L117.14 252.56C117.14 252.56 110.71 249.96 112.14 246.71L115.79 243.91Z"
          fill="#AE7461"
        />
        <path
          d="M128.09 252.261L121.13 255.821L117.15 252.561L123.14 246.381L128.09 252.261Z"
          fill="#AE7461"
        />
        <path
          d="M134.36 169.291C140.14 169.531 142.36 181.611 140.58 183.581C137.19 187.251 127.2 169.001 134.36 169.291Z"
          fill="#263238"
        />
        <path
          d="M121.83 184.9C124.7 191.32 126.06 195.22 130.64 197.35C137.54 200.55 144.52 194.49 143.64 187.35C142.83 180.92 138.27 171.5 130.85 171.3C129.216 171.247 127.595 171.613 126.142 172.363C124.688 173.112 123.451 174.222 122.547 175.584C121.643 176.947 121.103 178.518 120.978 180.149C120.852 181.779 121.146 183.415 121.83 184.9Z"
          fill="#AE7461"
        />
        <path
          d="M115 182.75C117.12 186.97 124.29 194.21 126.75 193.04C129.41 191.76 127.98 185.49 124.58 180.59L115 182.75Z"
          fill="#263238"
        />
        <path
          d="M121.58 193.26C122.278 193.79 123.073 194.177 123.921 194.398C124.769 194.619 125.652 194.671 126.52 194.55C128.84 194.22 129.06 191.92 127.63 190.22C126.33 188.69 123.57 187.03 121.63 188.22C121.206 188.488 120.856 188.857 120.612 189.295C120.368 189.733 120.238 190.225 120.233 190.726C120.228 191.228 120.348 191.722 120.584 192.165C120.819 192.607 121.162 192.984 121.58 193.26Z"
          fill="#AE7461"
        />
        <path
          d="M132.49 183.001C132.69 183.541 133.13 183.861 133.49 183.731C133.85 183.601 133.96 183.061 133.76 182.521C133.56 181.981 133.12 181.661 132.76 181.791C132.4 181.921 132.29 182.461 132.49 183.001Z"
          fill="#263238"
        />
        <path
          d="M138.53 180.719C138.74 181.249 139.18 181.579 139.53 181.449C139.88 181.319 140 180.779 139.8 180.239C139.6 179.699 139.15 179.379 138.8 179.509C138.45 179.639 138.33 180.179 138.53 180.719Z"
          fill="#263238"
        />
        <path
          d="M136.71 181.711C137.963 183.106 139.404 184.321 140.99 185.321C140.722 185.719 140.37 186.054 139.959 186.303C139.548 186.552 139.087 186.708 138.61 186.761L136.71 181.711Z"
          fill="#ED847E"
        />
        <path
          d="M137.63 188.82C137.665 188.8 137.692 188.768 137.706 188.729C137.721 188.691 137.722 188.649 137.71 188.61C137.702 188.589 137.69 188.57 137.675 188.553C137.659 188.537 137.641 188.524 137.62 188.514C137.6 188.505 137.578 188.5 137.555 188.499C137.533 188.499 137.511 188.502 137.49 188.51C136.829 188.777 136.116 188.883 135.406 188.82C134.697 188.758 134.013 188.528 133.41 188.15C133.395 188.135 133.377 188.123 133.357 188.114C133.337 188.106 133.316 188.102 133.295 188.102C133.273 188.102 133.252 188.106 133.232 188.114C133.212 188.123 133.195 188.135 133.18 188.15C133.164 188.165 133.152 188.183 133.144 188.203C133.135 188.223 133.131 188.244 133.131 188.265C133.131 188.287 133.135 188.308 133.144 188.328C133.152 188.348 133.164 188.365 133.18 188.38C133.827 188.796 134.564 189.05 135.33 189.122C136.095 189.193 136.867 189.079 137.58 188.79L137.63 188.82Z"
          fill="#263238"
        />
        <path
          d="M130 181.71C130.04 181.689 130.076 181.661 130.105 181.627C130.134 181.592 130.156 181.553 130.17 181.51C130.278 181.102 130.481 180.725 130.763 180.411C131.044 180.096 131.396 179.852 131.79 179.7C131.874 179.672 131.944 179.613 131.985 179.534C132.026 179.456 132.035 179.364 132.01 179.28C131.997 179.237 131.976 179.198 131.948 179.164C131.92 179.129 131.886 179.101 131.846 179.081C131.807 179.06 131.764 179.048 131.72 179.044C131.676 179.04 131.632 179.046 131.59 179.06C131.09 179.244 130.641 179.546 130.281 179.939C129.921 180.332 129.66 180.805 129.52 181.32C129.508 181.362 129.504 181.406 129.509 181.449C129.514 181.493 129.528 181.535 129.549 181.573C129.57 181.611 129.599 181.644 129.634 181.671C129.668 181.698 129.708 181.718 129.75 181.73C129.791 181.744 129.834 181.749 129.877 181.746C129.92 181.743 129.962 181.73 130 181.71Z"
          fill="#263238"
        />
        <path
          d="M140.44 177.631L140.53 177.561C140.561 177.53 140.586 177.493 140.603 177.453C140.619 177.413 140.628 177.37 140.628 177.326C140.628 177.282 140.619 177.239 140.603 177.198C140.586 177.158 140.561 177.121 140.53 177.091C140.154 176.713 139.692 176.432 139.183 176.272C138.674 176.111 138.135 176.077 137.61 176.171C137.567 176.18 137.526 176.198 137.49 176.223C137.455 176.249 137.424 176.281 137.401 176.318C137.377 176.355 137.362 176.397 137.355 176.44C137.348 176.484 137.35 176.528 137.36 176.571C137.383 176.657 137.439 176.731 137.515 176.777C137.591 176.824 137.683 176.839 137.77 176.821C138.186 176.756 138.612 176.792 139.011 176.924C139.411 177.056 139.774 177.281 140.07 177.581C140.121 177.623 140.182 177.65 140.248 177.659C140.313 177.668 140.38 177.658 140.44 177.631Z"
          fill="#263238"
        />
        <path
          d="M119.8 189C118.175 187.965 116.781 186.606 115.705 185.007C114.63 183.408 113.896 181.605 113.55 179.71C112.43 173.31 123.97 166.92 130.4 166.42C136.83 165.92 135.81 172.96 135.81 172.96C135.81 172.96 127.48 175.96 124.61 177.75C121.74 179.54 124.61 180.6 124.61 180.6L119.8 189Z"
          fill="#263238"
        />
        <path
          d="M104.36 233.001C104.36 232.931 104.42 232.841 104.53 232.721H92.3096C92.0041 233.33 91.8014 233.985 91.7096 234.661C91.0496 241.061 112.05 248.291 112.05 248.291L117.15 243.961C117.15 243.961 104.36 234.081 104.36 233.001Z"
          fill="#263238"
        />
        <path
          d="M262.77 168.05L212.12 177.69C210.538 177.992 208.913 177.979 207.336 177.652C205.759 177.325 204.262 176.691 202.931 175.786C201.599 174.88 200.459 173.721 199.575 172.375C198.691 171.029 198.081 169.522 197.78 167.94C197.477 166.358 197.489 164.732 197.815 163.155C198.142 161.578 198.776 160.08 199.681 158.748C200.587 157.416 201.746 156.276 203.093 155.393C204.44 154.509 205.948 153.9 207.53 153.6L258.19 144C261.386 143.394 264.692 144.082 267.381 145.912C270.07 147.742 271.922 150.565 272.53 153.76C273.122 156.948 272.427 160.241 270.598 162.919C268.77 165.596 265.955 167.441 262.77 168.05Z"
          fill={PRIMARY_MAIN}
        />
        <path
          opacity="0.6"
          d="M262.77 168.05L212.12 177.69C210.538 177.992 208.913 177.979 207.336 177.652C205.759 177.325 204.262 176.691 202.931 175.786C201.599 174.88 200.459 173.721 199.575 172.375C198.691 171.029 198.081 169.522 197.78 167.94C197.477 166.358 197.489 164.732 197.815 163.155C198.142 161.578 198.776 160.08 199.681 158.748C200.587 157.416 201.746 156.276 203.093 155.393C204.44 154.509 205.948 153.9 207.53 153.6L258.19 144C261.386 143.394 264.692 144.082 267.381 145.912C270.07 147.742 271.922 150.565 272.53 153.76C273.122 156.948 272.427 160.241 270.598 162.919C268.77 165.596 265.955 167.441 262.77 168.05Z"
          fill="white"
        />
        <path
          d="M220.83 166.489L221.07 167.779L214.89 168.999L213.32 160.759L219.32 159.609L219.56 160.899L215.07 161.749L215.48 163.889L219.48 163.139L219.72 164.399L215.72 165.149L216.15 167.429L220.83 166.489Z"
          fill="black"
        />
        <path
          d="M228.181 166.42L226.001 164.33L225.681 164.4L223.821 164.76L224.281 167.16L222.751 167.45L221.181 159.2L224.571 158.56C226.741 158.14 228.291 159 228.641 160.82C228.773 161.38 228.722 161.968 228.495 162.498C228.268 163.027 227.878 163.469 227.381 163.76L229.791 166.11L228.181 166.42ZM224.781 159.87L222.991 160.21L223.621 163.49L225.411 163.15C226.751 162.9 227.331 162.15 227.141 161.15C226.951 160.15 226.131 159.61 224.781 159.87Z"
          fill="black"
        />
        <path
          d="M236.8 164.779L234.65 162.689L234.34 162.759L232.48 163.109L232.93 165.519L231.4 165.809L229.83 157.559L233.23 156.919C235.39 156.499 236.95 157.359 237.3 159.179C237.429 159.74 237.377 160.326 237.15 160.855C236.924 161.384 236.535 161.826 236.04 162.119L238.45 164.469L236.8 164.779ZM233.4 158.229L231.61 158.569L232.24 161.849L234.03 161.509C235.37 161.259 235.95 160.509 235.75 159.509C235.55 158.509 234.75 157.999 233.4 158.229Z"
          fill="black"
        />
        <path
          d="M238.65 160.16C238.44 159.282 238.498 158.361 238.817 157.516C239.135 156.671 239.699 155.941 240.436 155.42C241.174 154.898 242.05 154.609 242.953 154.591C243.856 154.572 244.743 154.824 245.502 155.315C246.26 155.805 246.854 156.512 247.207 157.343C247.56 158.174 247.656 159.092 247.482 159.978C247.309 160.864 246.874 161.678 246.234 162.315C245.594 162.951 244.777 163.382 243.89 163.55C243.323 163.689 242.733 163.711 242.156 163.614C241.58 163.517 241.03 163.303 240.539 162.986C240.048 162.668 239.628 162.254 239.303 161.768C238.978 161.282 238.756 160.735 238.65 160.16ZM245.99 158.76C245.888 158.172 245.613 157.627 245.199 157.196C244.786 156.765 244.253 156.467 243.669 156.34C243.085 156.214 242.477 156.265 241.922 156.486C241.367 156.707 240.891 157.09 240.555 157.583C240.218 158.077 240.037 158.66 240.034 159.257C240.031 159.855 240.207 160.44 240.538 160.937C240.87 161.434 241.342 161.82 241.895 162.047C242.447 162.274 243.055 162.331 243.64 162.21C244.026 162.149 244.396 162.009 244.726 161.799C245.057 161.59 245.341 161.315 245.562 160.993C245.783 160.67 245.935 160.305 246.011 159.922C246.086 159.538 246.082 159.143 246 158.76H245.99Z"
          fill="black"
        />
        <path
          d="M255.34 161.251L253.2 159.161L252.88 159.231L251.02 159.581L251.48 161.991L249.95 162.281L248.38 154.001L251.77 153.361C253.94 152.941 255.49 153.801 255.84 155.621C255.972 156.181 255.921 156.769 255.694 157.298C255.468 157.828 255.077 158.27 254.58 158.561L256.99 160.911L255.34 161.251ZM251.94 154.701L250.15 155.041L250.78 158.321L252.57 157.981C253.91 157.731 254.49 156.981 254.29 155.981C254.09 154.981 253.29 154.441 251.94 154.701Z"
          fill="black"
        />
      </svg>
    </Box>
  );
}
