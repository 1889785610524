import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
// layouts
import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
// components
import LoadingScreen from "../components/LoadingScreen";
import AuthGuard from "../guards/AuthGuard";
import { frontendPages } from "./paths";

// ----------------------------------------------------------------------
// eslint-disable-next-line
const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = !frontendPages.includes(pathname);
  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: "fixed",
            }),
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: (
        <AuthGuard>
          {" "}
          <DashboardLayout />{" "}
        </AuthGuard>
      ),
      children: [
        { path: "", element: <Dashboard /> },
        {
          path: "settings",
          children: [
            { path: "", element: <Settings /> },
            { path: ":tab", element: <Settings /> },
          ],
        },
        { path: "profile", element: <Profile /> },
        { path: "edit-profile", element: <Profile /> },
        { path: "labs", element: <Labs /> },
        {
          path: "patients",
          children: [
            { path: "", element: <Patients /> },
            { path: "add", element: <AddPatient /> },
          ],
        },
        {
          path: "personnel",
          children: [
            { path: "", element: <Personnel /> },
            { path: "add", element: <AddPersonnel /> },
          ],
        },
        {
          path: "tests",
          children: [
            { path: "", element: <Tests /> },
            { path: "add", element: <AddTest /> },
            { path: ":status", element: <Tests /> },
          ],
        },
        {
          path: "samples",
          children: [
            { path: "", element: <Samples /> },
            { path: "add", element: <AddSample /> },
            { path: "add/:oid", element: <AddSample /> },
          ],
        },
        { path: "approvals", element: <Approval /> },
        { path: "appointments", element: <Appointments /> },
        { path: "connected-instruments", element: <ConnectedInstruments /> },
        { path: "helpdesk", element: <HelpDesk /> },
        { path: "payments", element: <Billings /> },
        { path: "analytics", element: <Analytics /> },
        { path: "log", element: <Log /> },
        { path: "devices", element: <Devices /> },
        {
          path: "test",
          children: [
            { path: "", element: <Test /> },
            { path: ":tid", element: <Test /> },
            { path: ":tid/:name", element: <Test /> },
            { path: "track/:oid", element: <TrackTest /> },
            { path: "result/:oid", element: <AddTestResult /> },
            { path: "show-result/:oid", element: <ShowTestResult /> },
          ],
        },
        {
          path: "sample",
          children: [
            { path: "", element: <Sample /> },
            { path: ":sid", element: <Sample /> },
            { path: "log/:oid", element: <LogSample /> },
          ],
        },
        {
          path: "patient",
          children: [
            { path: "", element: <Patient /> },
            { path: ":uid", element: <Patient /> },
            { path: ":uid/:name", element: <Patient /> },
            { path: "edit/:uid", element: <EditPatient /> },
          ],
        },
        {
          path: "document-manager",
          children: [
            { path: "", element: <DocumentManager /> },
            { path: ":fid", element: <DocumentManager /> },
            { path: ":fid/:name", element: <DocumentManager /> },
            { path: "viewer/:fid", element: <DocumentViewer /> },
            { path: "viewer/:fid/:name", element: <DocumentViewer /> },
            { path: "add", element: <AddDocument /> },
          ],
        },
        {
          path: "inventory",
          children: [
            { path: "", element: <Inventory /> },
            { path: "add", element: <AddInventory /> },
            { path: ":iid", element: <UpdateInventory /> },
            { path: ":iid/:name", element: <UpdateInventory /> },
          ],
        },
        {
          path: "view-personnel",
          children: [
            { path: "", element: <ViewPersonnel /> },
            { path: ":uid", element: <ViewPersonnel /> },
            { path: ":uid/:name", element: <ViewPersonnel /> },
          ],
        },
        {
          path: "labs",
          children: [
            { path: "", element: <Lab /> },
            { path: ":lid", element: <Lab /> },
            { path: ":lid/:name", element: <Lab /> },
          ],
        },
      ],
    },
    // Main Routes
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "404", element: <NotFound /> },
        { path: "500", element: <ErrorOccured /> },
        { path: "*", element: <Navigate to="/404" replace /> },
        {
          path: "login",
          element: (
            <AuthGuard>
              <Login />
            </AuthGuard>
          ),
        },
        { path: "access-denied", element: <AccessDenied /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// admin pages
const Settings = Loadable(lazy(() => import("../pages/dashboard/Settings")));
const Dashboard = Loadable(lazy(() => import("../pages/dashboard/Dashboard")));
const DocumentManager = Loadable(
  lazy(() => import("../pages/dashboard/DocumentManager"))
);
const DocumentViewer = Loadable(
  lazy(() => import("../pages/dashboard/DocumentViewer"))
);
const Labs = Loadable(lazy(() => import("../pages/dashboard/Labs")));
const Patient = Loadable(lazy(() => import("../pages/dashboard/Patient")));
const Sample = Loadable(lazy(() => import("../pages/dashboard/Sample")));
const Log = Loadable(lazy(() => import("../pages/dashboard/Log")));
const Devices = Loadable(lazy(() => import("../pages/dashboard/Devices")));
const TrackTest = Loadable(lazy(() => import("../pages/dashboard/TrackTest")));
const AddTestResult = Loadable(
  lazy(() => import("../pages/dashboard/AddTestResult"))
);
const ShowTestResult = Loadable(
  lazy(() => import("../pages/dashboard/ShowTestResult"))
);
const Inventory = Loadable(lazy(() => import("../pages/dashboard/Inventory")));
const Test = Loadable(lazy(() => import("../pages/dashboard/Test")));
const AddTest = Loadable(lazy(() => import("../pages/dashboard/AddTest")));
const Analytics = Loadable(lazy(() => import("../pages/dashboard/Analytics")));
const ConnectedInstruments = Loadable(
  lazy(() => import("../pages/dashboard/ConnectedInstruments"))
);
const HelpDesk = Loadable(lazy(() => import("../pages/dashboard/HelpDesk")));
const Profile = Loadable(lazy(() => import("../pages/dashboard/Profile")));
const Billings = Loadable(lazy(() => import("../pages/dashboard/Billings")));
const Approval = Loadable(lazy(() => import("../pages/dashboard/Approval")));
const Appointments = Loadable(
  lazy(() => import("../pages/dashboard/Appointments"))
);
const Samples = Loadable(lazy(() => import("../pages/dashboard/Samples")));
const Tests = Loadable(lazy(() => import("../pages/dashboard/Tests")));
const Personnel = Loadable(lazy(() => import("../pages/dashboard/Personnel")));
const EditPatient = Loadable(
  lazy(() => import("../pages/dashboard/EditPatient"))
);

const AddInventory = Loadable(
  lazy(() => import("../pages/dashboard/AddInventory"))
);
const UpdateInventory = Loadable(
  lazy(() => import("../pages/dashboard/UpdateInventory"))
);
const AddDocument = Loadable(
  lazy(() => import("../pages/dashboard/AddDocument"))
);
const AddPatient = Loadable(
  lazy(() => import("../pages/dashboard/AddPatient"))
);
const AddSample = Loadable(lazy(() => import("../pages/dashboard/AddSample")));
const AddPersonnel = Loadable(
  lazy(() => import("../pages/dashboard/AddPersonnel"))
);
const LogSample = Loadable(lazy(() => import("../pages/dashboard/LogSample")));
const Patients = Loadable(lazy(() => import("../pages/dashboard/Patients")));
const ViewPersonnel = Loadable(
  lazy(() => import("../pages/dashboard/ViewPersonnel"))
);
const Lab = Loadable(lazy(() => import("../pages/dashboard/Lab")));

// error
const ErrorOccured = Loadable(
  lazy(() => import("../pages/errors/ErrorOccured"))
);
const NotFound = Loadable(lazy(() => import("../pages/errors/NotFound")));
const AccessDenied = Loadable(
  lazy(() => import("../pages/errors/AccessDenied"))
);

// auth
const Login = Loadable(lazy(() => import("../pages/authentication/Login")));
