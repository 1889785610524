import { useState, useRef } from "react";
import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import { useSnackbar } from "notistack";
import { useLocation, useParams } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  Paper,
  Link,
  IconButton,
  Typography,
  Divider,
  List,
  ListItemText,
  ListItemButton,
} from "@mui/material";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
// hooks
import useCollapseDrawer from "../../hooks/useCollapseDrawer";
import { PATH_DASHBOARD } from "../../routes/paths";
import sidebarConfig from "./SidebarConfig";
import { MHidden } from "../../components/@material-extend";
import useAuth from "../../hooks/useAuth";
import { Block } from "../../components/Block";
import MenuPopover from "../../components/MenuPopover";
import NotificationsPopover from "./NotificationsPopover";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 102;

const APPBAR_MOBILE = 54;
const APPBAR_DESKTOP = 65;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backdropFilter: "blur(6px)",
  borderTopLeftRadius: 25,
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  backgroundColor: theme.palette.background.default,
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

const ListWrapperStyle = styled(Paper)(() => ({
  width: "100%",
  backgroundColor: "inherit",
  borderRadius: "0px",
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};
const useBasePath = () => {
  const location = useLocation();
  const params = useParams();

  return Object.values(params).reduce(
    (path, param) => path.replace(`/${param}`, ""),
    location.pathname
  );
};
export default function DashboardNavbar({ onOpenSidebar }) {
  const { isCollapse } = useCollapseDrawer();
  const pathname = useBasePath();
  let title = "";
  if (pathname === PATH_DASHBOARD.privacy) {
    title = "Privacy & Security";
  } else if (pathname === PATH_DASHBOARD.profile) {
    title = "My Profile";
  } else if (pathname === PATH_DASHBOARD.editProfile) {
    title = "Edit Profile";
  } else {
    title = sidebarConfig.map((menu) => {
      const current = menu.items.filter((submenu) => submenu.path === pathname);
      return current.length > 0 ? current[0].title : "";
    });
  }

  const { enqueueSnackbar } = useSnackbar();
  const anchorRef = useRef(null);
  const { user, currentLab, switchLab } = useAuth();
  const { labs } = user;
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");

  const handleLabSwitch = (bid) => {
    try {
      switchLab(bid);
    } catch (err) {
      enqueueSnackbar(err.message, { variant: "error" });
    }
  };
  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };
  const filteredLabs =
    user && user.user
      ? labs.filter((lab) =>
          lab.name.toLowerCase().includes(search.toLowerCase())
        )
      : [];

  return (
    <RootStyle
      sx={{
        displayPrint: "none",
        ...(isCollapse && {
          width: { lg: `calc(100% - ${COLLAPSE_WIDTH}px)` },
        }),
      }}
    >
      <ToolbarStyle>
        <Typography variant="h5" sx={{ color: "text.secondary" }}>
          {title}
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Stack direction="row" spacing={2} alignItems="center">
          <NotificationsPopover />
          <Divider orientation="vertical" />
          <Link
            underline="none"
            ref={anchorRef}
            sx={{
              cursor: "pointer",
              backgroundColor: "background.neutral",
              py: 1,
              px: 1,
              borderRadius: 1,
            }}
            onClick={() => setOpen(true)}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={3}
            >
              <Box>
                <Typography
                  noWrap
                  variant="subtitle1"
                  sx={{ color: "text.primary", textAlign: "left" }}
                >
                  {currentLab.name || ""}
                </Typography>
                <Typography variant="caption" sx={{ color: "text.secondary" }}>
                  {currentLab.description || ""}
                </Typography>
              </Box>
              {(open && (
                <UnfoldLessIcon
                  fontSize="small"
                  sx={{ color: "common.neutral" }}
                />
              )) || (
                <UnfoldMoreIcon
                  fontSize="small"
                  sx={{ color: "common.neutral" }}
                />
              )}
            </Stack>
          </Link>
          <MenuPopover
            arrow="bottom-left"
            open={open}
            onClose={() => {
              setOpen(false);
            }}
            anchorEl={anchorRef.current}
            sx={{ width: 220 }}
          >
            <Block
              title={user && user.user ? `Labs - ${labs.length}` : ""}
              open={open}
              search={search}
              handleChange={handleSearchChange}
            >
              <ListWrapperStyle>
                <List component="nav">
                  {filteredLabs.map((lab, index) => (
                    <ListItemButton
                      onClick={() => handleLabSwitch(lab.lid)}
                      key={index}
                    >
                      <ListItemText primary={lab.name} />
                    </ListItemButton>
                  ))}
                </List>
              </ListWrapperStyle>
            </Block>
          </MenuPopover>
          <MHidden width="lgUp">
            <IconButton
              onClick={() => {
                onOpenSidebar();
              }}
            >
              <Icon icon="eva:menu-2-fill" />
            </IconButton>
          </MHidden>
        </Stack>
      </ToolbarStyle>
      <Divider variant="fullWidth" />
    </RootStyle>
  );
}
