import useAuth from "../hooks/useAuth";
import { PATH_DASHBOARD } from "../routes/paths";

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_API = "https://api.celldiagnosticslimited.com/lab/";
const ROOTS_ASSETS = "https://assets.celldiagnosticslimited.com/";

// const ROOTS_API = "http://127.0.0.1:8080/lab/";
// const ROOTS_ASSETS = "http://127.0.0.1:8080";
// 
export const API_PATH = {
  root: ROOTS_API,
  dashboard: path(ROOTS_API, "dashboard"),
  personnel: path(ROOTS_API, "personnel"),
  appointments: path(ROOTS_API, "appointments"),
  tests: path(ROOTS_API, "orders"),
  rawTests: path(ROOTS_API, "tests"),
  categories: path(ROOTS_API, "categories"),
  departments: path(ROOTS_API, "departments"),
  labs: path(ROOTS_API, "labs"),
  login: path(ROOTS_API, "login"),
  patients: path(ROOTS_API, "patients"),
  samples: path(ROOTS_API, "samples"),
  profile: path(ROOTS_API, "profile"),
  inventory: path(ROOTS_API, "inventory"),
  personnels: path(ROOTS_API, "personnels"),
  settings: path(ROOTS_API, "settings"),
  documents: path(ROOTS_API, "documents"),
  misc: path(ROOTS_API, "misc"),
  roles: path(ROOTS_API, "roles"),
  approvals: path(ROOTS_API, "approvals"),
  analytics: path(ROOTS_API, "analytics"),
  instruments: path(ROOTS_API, "instruments"),
  helpdesk: path(ROOTS_API, "helpdesk"),
  logs: path(ROOTS_API, "logs"),
  packages: path(ROOTS_API, "packages"),
  devices: path(ROOTS_API, "devices"),
  billings: path(ROOTS_API, "billings"),
};

export const ASSETS_PATH = {
  patients: path(ROOTS_ASSETS, "users/"),
  personnel: path(ROOTS_ASSETS, "admins/"),
  documents: path(ROOTS_ASSETS, "files/"),
  results: path(ROOTS_ASSETS, "results/"),
  inventory: path(ROOTS_ASSETS, "inventory/"),
};

export const STATES = [
  "Abia",
  "Adamawa",
  "Akwa Ibom",
  "Anambra",
  "Bauchi",
  "Bayelsa",
  "Benue",
  "Borno",
  "Cross River",
  "Delta",
  "Ebonyi",
  "Edo",
  "Ekiti",
  "Enugu",
  "FCT - Abuja",
  "Gombe",
  "Imo",
  "Jigawa",
  "Kaduna",
  "Kano",
  "Katsina",
  "Kebbi",
  "Kogi",
  "Kwara",
  "Lagos",
  "Nasarawa",
  "Niger",
  "Ogun",
  "Ondo",
  "Osun",
  "Oyo",
  "Plateau",
  "Rivers",
  "Sokoto",
  "Taraba",
  "Yobe",
  "Zamfara",
];
export const MONTHS = [
  { label: "January", value: "1" },
  { label: "February", value: "2" },
  { label: "March", value: "3" },
  { label: "April", value: "4" },
  { label: "May", value: "5" },
  { label: "June", value: "6" },
  { label: "July", value: "7" },
  { label: "August", value: "8" },
  { label: "September", value: "9" },
  { label: "October", value: "10" },
  { label: "November", value: "11" },
  { label: "December", value: "12" },
];

export const LGAS = [
  {
    state: "Abia",
    lgas: [
      "Aba North",
      "Aba South",
      "Arochukwu",
      "Bende",
      "Ikawuno",
      "Ikwuano",
      "Isiala-Ngwa North",
      "Isiala-Ngwa South",
      "Isuikwuato",
      "Umu Nneochi",
      "Obi Ngwa",
      "Obioma Ngwa",
      "Ohafia",
      "Ohaozara",
      "Osisioma",
      "Ugwunagbo",
      "Ukwa West",
      "Ukwa East",
      "Umuahia North",
      "Umuahia South",
    ],
  },
  {
    state: "Adamawa",
    lgas: [
      "Demsa",
      "Fufore",
      "Ganye",
      "Girei",
      "Gombi",
      "Guyuk",
      "Hong",
      "Jada",
      "Lamurde",
      "Madagali",
      "Maiha",
      "Mayo-Belwa",
      "Michika",
      "Mubi-North",
      "Mubi-South",
      "Numan",
      "Shelleng",
      "Song",
      "Toungo",
      "Yola North",
      "Yola South",
    ],
  },
  {
    state: "Akwa Ibom",
    lgas: [
      "Abak",
      "Eastern-Obolo",
      "Eket",
      "Esit-Eket",
      "Essien-Udim",
      "Etim-Ekpo",
      "Etinan",
      "Ibeno",
      "Ibesikpo-Asutan",
      "Ibiono-Ibom",
      "Ika",
      "Ikono",
      "Ikot-Abasi",
      "Ikot-Ekpene",
      "Ini",
      "Itu",
      "Mbo",
      "Mkpat-Enin",
      "Nsit-Atai",
      "Nsit-Ibom",
      "Nsit-Ubium",
      "Obot-Akara",
      "Okobo",
      "Onna",
      "Oron",
      "Oruk Anam",
      "Udung-Uko",
      "Ukanafun",
      "Urue-Offong/Oruko",
      "Uruan",
      "Uyo",
    ],
  },
  {
    state: "Anambra",
    lgas: [
      "Aguata",
      "Anambra East",
      "Anambra West",
      "Anaocha",
      "Awka North",
      "Awka South",
      "Ayamelum",
      "Dunukofia",
      "Ekwusigo",
      "Idemili-North",
      "Idemili-South",
      "Ihiala",
      "Njikoka",
      "Nnewi-North",
      "Nnewi-South",
      "Ogbaru",
      "Onitsha-North",
      "Onitsha-South",
      "Orumba-North",
      "Orumba-South",
    ],
  },
  {
    state: "Bauchi",
    lgas: [
      "Alkaleri",
      "Bauchi",
      "Bogoro",
      "Damban",
      "Darazo",
      "Dass",
      "Gamawa",
      "Ganjuwa",
      "Giade",
      "Itas/Gadau",
      "Jama'Are",
      "Katagum",
      "Kirfi",
      "Misau",
      "Ningi",
      "Shira",
      "Tafawa-Balewa",
      "Toro",
      "Warji",
      "Zaki",
    ],
  },
  {
    state: "Benue",
    lgas: [
      "Ado",
      "Agatu",
      "Apa",
      "Buruku",
      "Gboko",
      "Guma",
      "Gwer-East",
      "Gwer-West",
      "Katsina-Ala",
      "Konshisha",
      "Kwande",
      "Logo",
      "Makurdi",
      "Ogbadibo",
      "Ohimini",
      "Oju",
      "Okpokwu",
      "Otukpo",
      "Tarka",
      "Ukum",
      "Ushongo",
      "Vandeikya",
    ],
  },
  {
    state: "Borno",
    lgas: [
      "Abadam",
      "Askira-Uba",
      "Bama",
      "Bayo",
      "Biu",
      "Chibok",
      "Damboa",
      "Dikwa",
      "Gubio",
      "Guzamala",
      "Gwoza",
      "Hawul",
      "Jere",
      "Kaga",
      "Kala/Balge",
      "Konduga",
      "Kukawa",
      "Kwaya-Kusar",
      "Mafa",
      "Magumeri",
      "Maiduguri",
      "Marte",
      "Mobbar",
      "Monguno",
      "Ngala",
      "Nganzai",
      "Shani",
    ],
  },
  {
    state: "Bayelsa",
    lgas: [
      "Brass",
      "Ekeremor",
      "Kolokuma/Opokuma",
      "Nembe",
      "Ogbia",
      "Sagbama",
      "Southern-Ijaw",
      "Yenagoa",
    ],
  },
  {
    state: "Cross River",
    lgas: [
      "Abi",
      "Akamkpa",
      "Akpabuyo",
      "Bakassi",
      "Bekwarra",
      "Biase",
      "Boki",
      "Calabar-Municipal",
      "Calabar-South",
      "Etung",
      "Ikom",
      "Obanliku",
      "Obubra",
      "Obudu",
      "Odukpani",
      "Ogoja",
      "Yakurr",
      "Yala",
    ],
  },
  {
    state: "Delta",
    lgas: [
      "Aniocha North",
      "Aniocha-North",
      "Aniocha-South",
      "Bomadi",
      "Burutu",
      "Ethiope-East",
      "Ethiope-West",
      "Ika-North-East",
      "Ika-South",
      "Isoko-North",
      "Isoko-South",
      "Ndokwa-East",
      "Ndokwa-West",
      "Okpe",
      "Oshimili-North",
      "Oshimili-South",
      "Patani",
      "Sapele",
      "Udu",
      "Ughelli-North",
      "Ughelli-South",
      "Ukwuani",
      "Uvwie",
      "Warri South-West",
      "Warri North",
      "Warri South",
    ],
  },
  {
    state: "Ebonyi",
    lgas: [
      "Abakaliki",
      "Afikpo-North",
      "Afikpo South (Edda)",
      "Ebonyi",
      "Ezza-North",
      "Ezza-South",
      "Ikwo",
      "Ishielu",
      "Ivo",
      "Izzi",
      "Ohaukwu",
      "Onicha",
    ],
  },
  {
    state: "Edo",
    lgas: [
      "Akoko Edo",
      "Egor",
      "Esan-Central",
      "Esan-North-East",
      "Esan-South-East",
      "Esan-West",
      "Etsako-Central",
      "Etsako-East",
      "Etsako-West",
      "Igueben",
      "Ikpoba-Okha",
      "Oredo",
      "Orhionmwon",
      "Ovia-North-East",
      "Ovia-South-West",
      "Owan East",
      "Owan-West",
      "Uhunmwonde",
    ],
  },
  {
    state: "Ekiti",
    lgas: [
      "Ado-Ekiti",
      "Efon",
      "Ekiti-East",
      "Ekiti-South-West",
      "Ekiti-West",
      "Emure",
      "Gbonyin",
      "Ido-Osi",
      "Ijero",
      "Ikere",
      "Ikole",
      "Ilejemeje",
      "Irepodun/Ifelodun",
      "Ise-Orun",
      "Moba",
      "Oye",
    ],
  },
  {
    state: "Enugu",
    lgas: [
      "Aninri",
      "Awgu",
      "Enugu-East",
      "Enugu-North",
      "Enugu-South",
      "Ezeagu",
      "Igbo-Etiti",
      "Igbo-Eze-North",
      "Igbo-Eze-South",
      "Isi-Uzo",
      "Nkanu-East",
      "Nkanu-West",
      "Nsukka",
      "Oji-River",
      "Udenu",
      "Udi",
      "Uzo-Uwani",
    ],
  },
  {
    state: "Federal Capital Territory",
    lgas: ["Abuja", "Kwali", "Kuje", "Gwagwalada", "Bwari", "Abaji"],
  },
  {
    state: "Gombe",
    lgas: [
      "Akko",
      "Balanga",
      "Billiri",
      "Dukku",
      "Funakaye",
      "Gombe",
      "Kaltungo",
      "Kwami",
      "Nafada",
      "Shongom",
      "Yamaltu/Deba",
    ],
  },
  {
    state: "Imo",
    lgas: [
      "Aboh-Mbaise",
      "Ahiazu-Mbaise",
      "Ehime-Mbano",
      "Ezinihitte",
      "Ideato-North",
      "Ideato-South",
      "Ihitte/Uboma",
      "Ikeduru",
      "Isiala-Mbano",
      "Isu",
      "Mbaitoli",
      "Ngor-Okpala",
      "Njaba",
      "Nkwerre",
      "Nwangele",
      "Obowo",
      "Oguta",
      "Ohaji-Egbema",
      "Okigwe",
      "Onuimo",
      "Orlu",
      "Orsu",
      "Oru-East",
      "Oru-West",
      "Owerri-Municipal",
      "Owerri-North",
      "Owerri-West",
    ],
  },
  {
    state: "Jigawa",
    lgas: [
      "Auyo",
      "Babura",
      "Biriniwa",
      "Birnin-Kudu",
      "Buji",
      "Dutse",
      "Gagarawa",
      "Garki",
      "Gumel",
      "Guri",
      "Gwaram",
      "Gwiwa",
      "Hadejia",
      "Jahun",
      "Kafin-Hausa",
      "Kaugama",
      "Kazaure",
      "Kiri kasama",
      "Maigatari",
      "Malam Madori",
      "Miga",
      "Ringim",
      "Roni",
      "Sule-Tankarkar",
      "Taura",
      "Yankwashi",
    ],
  },
  {
    state: "Kebbi",
    lgas: [
      "Aleiro",
      "Arewa-Dandi",
      "Argungu",
      "Augie",
      "Bagudo",
      "Birnin-Kebbi",
      "Bunza",
      "Dandi",
      "Fakai",
      "Gwandu",
      "Jega",
      "Kalgo",
      "Koko-Besse",
      "Maiyama",
      "Ngaski",
      "Sakaba",
      "Shanga",
      "Suru",
      "Wasagu/Danko",
      "Yauri",
      "Zuru",
    ],
  },
  {
    state: "Kaduna",
    lgas: [
      "Birnin-Gwari",
      "Chikun",
      "Giwa",
      "Igabi",
      "Ikara",
      "Jaba",
      "Jema'A",
      "Kachia",
      "Kaduna-North",
      "Kaduna-South",
      "Kagarko",
      "Kajuru",
      "Kaura",
      "Kauru",
      "Kubau",
      "Kudan",
      "Lere",
      "Makarfi",
      "Sabon-Gari",
      "Sanga",
      "Soba",
      "Zangon-Kataf",
      "Zaria",
    ],
  },
  {
    state: "Kano",
    lgas: [
      "Ajingi",
      "Albasu",
      "Bagwai",
      "Bebeji",
      "Bichi",
      "Bunkure",
      "Dala",
      "Dambatta",
      "Dawakin-Kudu",
      "Dawakin-Tofa",
      "Doguwa",
      "Fagge",
      "Gabasawa",
      "Garko",
      "Garun-Mallam",
      "Gaya",
      "Gezawa",
      "Gwale",
      "Gwarzo",
      "Kabo",
      "Kano-Municipal",
      "Karaye",
      "Kibiya",
      "Kiru",
      "Kumbotso",
      "Kunchi",
      "Kura",
      "Madobi",
      "Makoda",
      "Minjibir",
      "Nasarawa",
      "Rano",
      "Rimin-Gado",
      "Rogo",
      "Shanono",
      "Sumaila",
      "Takai",
      "Tarauni",
      "Tofa",
      "Tsanyawa",
      "Tudun-Wada",
      "Ungogo",
      "Warawa",
      "Wudil",
    ],
  },
  {
    state: "Kogi",
    lgas: [
      "Adavi",
      "Ajaokuta",
      "Ankpa",
      "Dekina",
      "Ibaji",
      "Idah",
      "Igalamela-Odolu",
      "Ijumu",
      "Kabba/Bunu",
      "Kogi",
      "Lokoja",
      "Mopa-Muro",
      "Ofu",
      "Ogori/Magongo",
      "Okehi",
      "Okene",
      "Olamaboro",
      "Omala",
      "Oyi",
      "Yagba-East",
      "Yagba-West",
    ],
  },
  {
    state: "Katsina",
    lgas: [
      "Bakori",
      "Batagarawa",
      "Batsari",
      "Baure",
      "Bindawa",
      "Charanchi",
      "Dan-Musa",
      "Dandume",
      "Danja",
      "Daura",
      "Dutsi",
      "Dutsin-Ma",
      "Faskari",
      "Funtua",
      "Ingawa",
      "Jibia",
      "Kafur",
      "Kaita",
      "Kankara",
      "Kankia",
      "Katsina",
      "Kurfi",
      "Kusada",
      "Mai-Adua",
      "Malumfashi",
      "Mani",
      "Mashi",
      "Matazu",
      "Musawa",
      "Rimi",
      "Sabuwa",
      "Safana",
      "Sandamu",
      "Zango",
    ],
  },
  {
    state: "Kwara",
    lgas: [
      "Asa",
      "Baruten",
      "Edu",
      "Ekiti (Araromi/Opin)",
      "Ilorin-East",
      "Ilorin-South",
      "Ilorin-West",
      "Isin",
      "Kaiama",
      "Moro",
      "Offa",
      "Oke-Ero",
      "Oyun",
      "Pategi",
    ],
  },
  {
    state: "Lagos",
    lgas: [
      "Agege",
      "Ajeromi-Ifelodun",
      "Alimosho",
      "Amuwo-Odofin",
      "Apapa",
      "Badagry",
      "Epe",
      "Eti-Osa",
      "Ibeju-Lekki",
      "Ifako-Ijaiye",
      "Ikeja",
      "Ikorodu",
      "Kosofe",
      "Lagos-Island",
      "Lagos-Mainland",
      "Mushin",
      "Ojo",
      "Oshodi-Isolo",
      "Shomolu",
      "Surulere",
      "Yewa-South",
    ],
  },
  {
    state: "Nasarawa",
    lgas: [
      "Akwanga",
      "Awe",
      "Doma",
      "Karu",
      "Keana",
      "Keffi",
      "Kokona",
      "Lafia",
      "Nasarawa",
      "Nasarawa-Eggon",
      "Obi",
      "Wamba",
      "Toto",
    ],
  },
  {
    state: "Niger",
    lgas: [
      "Agaie",
      "Agwara",
      "Bida",
      "Borgu",
      "Bosso",
      "Chanchaga",
      "Edati",
      "Gbako",
      "Gurara",
      "Katcha",
      "Kontagora",
      "Lapai",
      "Lavun",
      "Magama",
      "Mariga",
      "Mashegu",
      "Mokwa",
      "Moya",
      "Paikoro",
      "Rafi",
      "Rijau",
      "Shiroro",
      "Suleja",
      "Tafa",
      "Wushishi",
    ],
  },
  {
    state: "Ogun",
    lgas: [
      "Abeokuta-North",
      "Abeokuta-South",
      "Ado-Odo/Ota",
      "Ewekoro",
      "Ifo",
      "Ijebu-East",
      "Ijebu-North",
      "Ijebu-North-East",
      "Ijebu-Ode",
      "Ikenne",
      "Imeko-Afon",
      "Ipokia",
      "Obafemi-Owode",
      "Odeda",
      "Odogbolu",
      "Ogun-Waterside",
      "Remo-North",
      "Shagamu",
      "Yewa North",
    ],
  },
  {
    state: "Ondo",
    lgas: [
      "Akoko North-East",
      "Akoko North-West",
      "Akoko South-West",
      "Akoko South-East",
      "Akure-North",
      "Akure-South",
      "Ese-Odo",
      "Idanre",
      "Ifedore",
      "Ilaje",
      "Ile-Oluji-Okeigbo",
      "Irele",
      "Odigbo",
      "Okitipupa",
      "Ondo West",
      "Ondo-East",
      "Ose",
      "Owo",
    ],
  },
  {
    state: "Osun",
    lgas: [
      "Atakumosa West",
      "Atakumosa East",
      "Ayedaade",
      "Ayedire",
      "Boluwaduro",
      "Boripe",
      "Ede South",
      "Ede North",
      "Egbedore",
      "Ejigbo",
      "Ife North",
      "Ife South",
      "Ife-Central",
      "Ife-East",
      "Ifelodun",
      "Ila",
      "Ilesa-East",
      "Ilesa-West",
      "Irepodun",
      "Irewole",
      "Isokan",
      "Iwo",
      "Obokun",
      "Odo-Otin",
      "Ola Oluwa",
      "Olorunda",
      "Oriade",
      "Orolu",
      "Osogbo",
    ],
  },
  {
    state: "Oyo",
    lgas: [
      "Afijio",
      "Akinyele",
      "Atiba",
      "Atisbo",
      "Egbeda",
      "Ibadan North",
      "Ibadan North-East",
      "Ibadan North-West",
      "Ibadan South-East",
      "Ibadan South-West",
      "Ibarapa-Central",
      "Ibarapa-East",
      "Ibarapa-North",
      "Ido",
      "Ifedayo",
      "Irepo",
      "Iseyin",
      "Itesiwaju",
      "Iwajowa",
      "Kajola",
      "Lagelu",
      "Ogo-Oluwa",
      "Ogbomosho-North",
      "Ogbomosho-South",
      "Olorunsogo",
      "Oluyole",
      "Ona-Ara",
      "Orelope",
      "Ori-Ire",
      "Oyo-West",
      "Oyo-East",
      "Saki-East",
      "Saki-West",
      "Surulere",
    ],
  },
  {
    state: "Plateau",
    lgas: [
      "Barkin-Ladi",
      "Bassa",
      "Bokkos",
      "Jos-East",
      "Jos-North",
      "Jos-South",
      "Kanam",
      "Kanke",
      "Langtang-North",
      "Langtang-South",
      "Mangu",
      "Mikang",
      "Pankshin",
      "Qua'an Pan",
      "Riyom",
      "Shendam",
      "Wase",
    ],
  },
  {
    state: "Rivers",
    lgas: [
      "Abua/Odual",
      "Ahoada-East",
      "Ahoada-West",
      "Akuku Toru",
      "Andoni",
      "Asari-Toru",
      "Bonny",
      "Degema",
      "Eleme",
      "Emuoha",
      "Etche",
      "Gokana",
      "Ikwerre",
      "Khana",
      "Obio/Akpor",
      "Ogba-Egbema-Ndoni",
      "Ogba/Egbema/Ndoni",
      "Ogu/Bolo",
      "Okrika",
      "Omuma",
      "Opobo/Nkoro",
      "Oyigbo",
      "Port-Harcourt",
      "Tai",
    ],
  },
  {
    state: "Sokoto",
    lgas: [
      "Binji",
      "Bodinga",
      "Dange-Shuni",
      "Gada",
      "Goronyo",
      "Gudu",
      "Gwadabawa",
      "Illela",
      "Kebbe",
      "Kware",
      "Rabah",
      "Sabon Birni",
      "Shagari",
      "Silame",
      "Sokoto-North",
      "Sokoto-South",
      "Tambuwal",
      "Tangaza",
      "Tureta",
      "Wamako",
      "Wurno",
      "Yabo",
    ],
  },
  {
    state: "Taraba",
    lgas: [
      "Ardo-Kola",
      "Bali",
      "Donga",
      "Gashaka",
      "Gassol",
      "Ibi",
      "Jalingo",
      "Karim-Lamido",
      "Kurmi",
      "Lau",
      "Sardauna",
      "Takum",
      "Ussa",
      "Wukari",
      "Yorro",
      "Zing",
    ],
  },
  {
    state: "Yobe",
    lgas: [
      "Bade",
      "Bursari",
      "Damaturu",
      "Fika",
      "Fune",
      "Geidam",
      "Gujba",
      "Gulani",
      "Jakusko",
      "Karasuwa",
      "Machina",
      "Nangere",
      "Nguru",
      "Potiskum",
      "Tarmuwa",
      "Yunusari",
      "Yusufari",
    ],
  },
  {
    state: "Zamfara",
    lgas: [
      "Anka",
      "Bakura",
      "Birnin Magaji/Kiyaw",
      "Bukkuyum",
      "Bungudu",
      "Gummi",
      "Gusau",
      "Isa",
      "Kaura-Namoda",
      "Kiyawa",
      "Maradun",
      "Maru",
      "Shinkafi",
      "Talata-Mafara",
      "Tsafe",
      "Zurmi",
    ],
  },
];
export const TEST_STATUS = [
  {
    label: "Pending Payment",
    color: "warning",
    value: "0",
    icon: "wpf:paid",
    stage: "Payment",
  },
  {
    label: "Pending Sample",
    color: "info",
    stage: "Sample",
    value: "1",
    icon: "material-symbols:pending-actions",
  },
  {
    label: "Awaiting Analysis",
    color: "warning",
    value: "2",
    stage: "Sample Analysis",
    icon: "gg:calendar-due",
  },
  {
    label: "Awaiting Approval",
    color: "info",
    value: "3",
    stage: "Sample Approval",
    icon: "carbon:license-draft",
  },
  {
    label: "Awaiting Submission",
    color: "info",
    value: "4",
    stage: "Result",
    icon: "carbon:license-draft",
  },
  {
    label: "Completed",
    color: "success",
    value: "5",
    stage: "Result",
    icon: "carbon:license-draft",
  },
];
export const RAW_TEST_STATUS = [
  { label: "Inactive", color: "warning", value: "0", icon: "wpf:paid" },
  {
    label: "Active",
    color: "info",
    value: "1",
    icon: "material-symbols:pending-actions",
  },
];
export const SAMPLE_STATUS = [
  {
    label: "Pending",
    color: "warning",
    value: "0",
    icon: "material-symbols:pending-actions",
  },
  {
    label: "Samples Logged",
    color: "info",
    value: "1",
    icon: "mdi:credit-card-refund-outline",
  },
  {
    label: "Approved",
    color: "success",
    value: "2",
    icon: "iconoir:laptop-fix",
  },
  {
    label: "Rejected",
    color: "error",
    value: "3",
    icon: "iconoir:laptop-fix",
  },
];
export const INVENTORY_ANALYSIS = [
  {
    label: "Pending",
    color: "error",
    value: "0",
    icon: "material-symbols:pending-actions",
  },
  {
    label: "Part Payment",
    color: "warning",
    value: "1",
    icon: "carbon:license-third-party",
  },
  { label: "Paid", color: "success", value: "2", icon: "wpf:paid" },
];
export const APPROVAL_STATUS = [
  {
    label: "Pending",
    color: "error",
    value: "0",
    icon: "material-symbols:pending-actions",
  },
  {
    label: "Approved",
    color: "success",
    value: "1",
    icon: "carbon:license-third-party",
  },
  { label: "Rejected", color: "error", value: "2", icon: "wpf:paid" },
];
export const APPOINTMENT_STATUS = [
  {
    label: "Pending",
    color: "error",
    value: "0",
  },
  {
    label: "Approved",
    color: "success",
    value: "1",
  },
  { label: "Cancelled", color: "error", value: "2" },
];
export const PAYMENT_STATUS = [
  {
    label: "Pending",
    color: "error",
    value: "0",
  },
  {
    label: "Paid",
    color: "success",
    value: "1",
  },
];
export const PERSONNEL_STATUS = [
  {
    label: "Suspended",
    color: "error",
    value: "0",
    icon: "material-symbols:pending-actions",
  },
  {
    label: "Active",
    color: "success",
    value: "1",
    icon: "carbon:license-third-party",
  },
];
export const ONLINE_STATUS = [
  {
    label: "Pending",
    color: "warning",
    value: "0",
    icon: "material-symbols:pending-actions",
  },
  { label: "Sold", color: "success", value: "1", icon: "wpf:paid" },
];
export const DEVICE_TYPES = [
  {
    label: "Web/Browser",
    color: "warning",
    value: "0",
    icon: "foundation:web",
  },
  {
    label: "Windows",
    color: "info",
    value: "1",
    icon: "ant-design:windows-filled",
  },
  { label: "Mac OS", color: "secondary", value: "2", icon: "wpf:mac-os" },
  {
    label: "Android (Mobile)",
    color: "primary",
    value: "3",
    icon: "ant-design:android-filled",
  },
  {
    label: "IOS (Mobile)",
    color: "primary",
    value: "4",
    icon: "cib:app-store-ios",
  },
];
export const DAMAGED_STATUS = [
  {
    label: "Pending",
    color: "warning",
    value: "0",
    icon: "material-symbols:pending-actions",
  },
  { label: "Fixed", color: "success", value: "1", icon: "iconoir:laptop-fix" },
  {
    label: "Returned",
    color: "info",
    value: "2",
    icon: "mdi:credit-card-refund-outline",
  },
];
export const DOCUMENT_REQUESTS_STATUS = [
  {
    label: "Pending",
    color: "warning",
    value: "0",
    icon: "material-symbols:pending-actions",
  },
  {
    label: "Delivered",
    color: "success",
    value: "1",
    icon: "iconoir:laptop-fix",
  },
  {
    label: "Refunded",
    color: "info",
    value: "2",
    icon: "mdi:credit-card-refund-outline",
  },
];
export const DOCUMENT_ASSIGNED_STATUS = [
  {
    label: "Pending",
    color: "warning",
    value: "0",
    icon: "material-symbols:pending-actions",
  },
  { label: "Active", color: "success", value: "1", icon: "iconoir:laptop-fix" },
  {
    label: "Cancelled",
    color: "error",
    value: "2",
    icon: "fluent:calendar-cancel-20-filled",
  },
  {
    label: "Used",
    color: "info",
    value: "3",
    icon: "fluent:gift-card-multiple-24-filled",
  },
];
export const HELP_STATUS = [
  { label: "Unresolved", color: "error", value: "0" },
  { label: "Resolved", color: "success", value: "1" },
];
export const HELP_TYPE = [
  { label: "General", color: "info", value: "0" },
  { label: "Complaints", color: "warning", value: "1" },
  { label: "Bug Reports", color: "error", value: "2" },
];
export const ROLE_STATUS = [
  { label: "Suspended", color: "error", value: "0" },
  { label: "Active", color: "success", value: "1" },
];
export const ADMIN_STATUS = [
  { label: "Suspended", color: "error", value: "0" },
  { label: "Active", color: "success", value: "1" },
];
export const BRANCH_STATUS = [
  { label: "Suspended", color: "error", value: "0" },
  { label: "Active", color: "success", value: "1" },
];
export const CATEGORY_STATUS = [
  { label: "Hidden", color: "error", value: "0" },
  { label: "Shown", color: "success", value: "1" },
];
export const STAFF_STATUS = [
  { label: "Suspended", color: "warning", value: "0" },
  { label: "Active", color: "success", value: "1" },
  { label: "Sacked", color: "error", value: "3" },
  { label: "Resigned", color: "info", value: "4" },
];
export const INVENTORY_STATUS = [
  { label: "Maintenance Needed", color: "warning", value: "0" },
  { label: "Active", color: "success", value: "1" },
  { label: "Damaged", color: "error", value: "2" },
];
export const DEVICE_STATUS = [
  { label: "Logged Out", color: "warning", value: "0" },
  { label: "Logged In", color: "success", value: "1" },
];
export const PATIENT_STATUS = [
  {
    label: "Pending",
    color: "warning",
    value: "0",
    icon: "material-symbols:pending-actions",
  },
  {
    label: "Sent",
    color: "success",
    value: "1",
    icon: "icon-park-solid:message-sent",
  },
];
export const DOCUMENT_TYPES = [
  { label: "Gift Cards", color: "info", value: "0" },
  { label: "Loyalty Cards", color: "success", value: "1" },
];
export const STORE_CREDIT_TYPES = [
  { label: "Flat Amount", color: "info", value: "0" },
  { label: "Percentage Discount", color: "success", value: "1" },
];
export const STORE_CREDIT_CONDITIONS = [
  { label: "Purchase Price", color: "info", value: "0" },
  { label: "HELP Quantity Purchased", color: "success", value: "1" },
];
export const ANALYTICS_FILTER_TYPES = [
  { label: "Today", value: "today" },
  { label: "This Week", value: "week" },
  { label: "This Month", value: "current_month" },
  { label: "This Year", value: "current_year" },
  { label: "All Time", value: "all_time" },
  { label: "Date", value: "date" },
  { label: "Month", value: "month" },
  { label: "Year", value: "year" },
  { label: "Date Range", value: "range" },
];
export const SUPPORT_STATUS = [
  {
    label: "Pending",
    color: "warning",
    value: "0",
    icon: "material-symbols:pending-actions",
  },
  {
    label: "Replied",
    color: "success",
    value: "1",
    icon: "icon-park-solid:message-sent",
  },
];
export const ONLINE_STORE_OPTIONS = [
  { label: "Own Store", value: "own store", color: "primary" },
  { label: "Jumia", value: "jumia", color: "info" },
  { label: "Konga", value: "konga", color: "info" },
  { label: "PayPorte", value: "PayPorte", color: "info" },
  { label: "VConnect", value: "VConnect", color: "info" },
  { label: "Kara", value: "Kara", color: "info" },
  { label: "Jiji", value: "Jiji", color: "info" },
  { label: "Other", value: "other", color: "warning" },
];
export const MESSAGE_TYPES = [
  { label: "Email", value: "0" },
  { label: "SMS", value: "1" },
];
export const RECIPIENT_TYPES = [
  { label: "Customers", value: "0" },
  { label: "Staff", value: "1" },
  { label: "Administrators", value: "2" },
  { label: "Others", value: "3" },
];
export const GENDERS = [
  { label: "Male", value: "M" },
  { label: "Female", value: "F" },
];

export const FIELDS_DATA = [
  { name: "Specific Gravity", unit: "", ref: "", flag:"", result:"" },
  // the new updates results Templates
  { name: "Alkaline Phosphatase (ALP)", unit: "u/L 37'C", ref: "40-120", flag:"", result:"" },
  { name: "ALT", unit: "U/L", ref: "10-40", flag:"", result:"" },
  { name: "AST", unit: "U/L", ref: "10-42", flag:"", result:"" },
  { name: "Blood Urea Nitrogen (BUN)", unit: "", ref: "", flag:"", result:"" },
  { name: "Calcium, serum", unit: "mmol/L", ref: "2.1-26", flag:"", result:"" },
  { name: "Corrected calcium", unit: "mmol/L", ref: "", flag:"", result:"" },
  { name: "Creatinine", unit: "umol/L", ref: "39-91", flag:"", result:"" },
  { name: "Estimated GFR (CKD-EPI)", unit: "ml/min/ 1.73 m2", ref: "", flag:"", result:"" },
  { name: "Fasting glucose", unit: "mmol/L", ref: "2.5-5.5", flag:"", result:"" },
  { name: "Free T3", unit: "pmol/L", ref: "3.6-6", flag:"", result:"" },
  { name: "Free T4", unit: "pmol/L", ref: "7.2-16.4", flag:"", result:"" },
  { name: "GGT", unit: "U/L", ref: "7-64", flag:"", result:"" },
  { name: "HDL", unit: "mmol/L", ref: "Female > 1.20", flag:"", result:"" },
  { name: "LDL", unit: "mmol/L", ref: "0-2.6", flag:"", result:"" },
  { name: "Potassium, serum", unit: "mmol/L", ref: "3.5-5.1", flag:"", result:"" },
  { name: "Prolactin", unit: "ng/mL", ref: "3.3-27.6", flag:"", result:"" },
  { name: "Random glucose", unit: "mg/dL", ref: "63-99", flag:"", result:"" },
  { name: "Serum carbondioxide", unit: "", ref: "22-30", flag:"", result:"" },
  { name: "Serum urea", unit: "", ref: "2.5-6.4", flag:"", result:"" },
  { name: "Sodium, serum", unit: "mmol/L", ref: "135-145", flag:"", result:"" },
  { name: "Total cholesterol", unit: "mmol/L", ref: "", flag:"", result:"" },
  { name: "Triglyceride", unit: "mmol/L", ref: "0-1.7", flag:"", result:"" },
  { name: "TSH", unit: "mIU/L", ref: "0.38-5.33", flag:"", result:"" },

  { name: "C-Reactive protein", unit: "mg/L", ref: "0 -7.4", flag:"", result:"" },
  { name: "Reticulocyte Absolute count", unit: "x109/L", ref: "50-150", flag:"", result:"" },
  { name: "Reticulocyte percentage", unit: "%", ref: "1.00-2.00", flag:"", result:"" },
  { name: "Vitamin B12", unit: "pmol/L", ref: "133- 675", flag:"", result:"" },
  { name: "Prostate Specific Antigen(PSA)", unit: "ug/L", ref: "0-4", flag:"", result:"" },
  
  { name: "Hepatitis A", unit: "S/CO", ref: "<1.0", flag:"", result:"" },
  { name: "Hepatitis E", unit: "-", ref: "0-0.8", flag:"", result:"" },
  { name: "Antinuclear Antibodies (ANA)", unit: "Titre", ref: "<1:80", flag:"", result:"" },
  
  { name: "G6PD", unit: "U/g Hb", ref: "6.97-20.5", flag:"", result:"" },
  
  { name: "2 Hour Postprandial (2HPP)", unit: "mmol/L or mg/dL ", ref: "< 7.8 or < 140", flag:"", result:"" },
  { name: "Adrenocorticotropic Hormone (ACTH)", unit: "pg/mL", ref: "0-46", flag:"", result:"" },
  { name: "Cortisol", unit: "nmol/L", ref: "240-618", flag:"", result:"" },
  { name: "Urine Albumin Creatinine Ratio(UACR)", unit: "mg/g or mg/mmol", ref: "0-30 or 0-3", flag:"", result:"" },

  // end the new updates results Templates
  { name: "Ph", unit: "", ref: "4.5-8.2", flag:"", result:"" },
  { name: "Haemoglobin", unit: "g/dL", ref: "12.0-16.0", flag:"Low", result:"4" },
  { name: "Red blood cells", unit: "x 1012/L", ref: "4.0-5.2", flag:"High", result:"6.5" },
  { name: "PCV", unit: "%", ref: "36-48", flag:"low", result:"30" },
  { name: "MCV", unit: "fL", ref: "80-95", flag:"low", result:"43" },
  { name: "MCH", unit: "pg", ref: "27-32", flag:"low", result:"21" },
  { name: "MCHC", unit: "g/dL", ref: "32-36", flag:"high", result:"56" },
  { name: "RDW", unit: "%", ref: "12.3-17.7", flag:"", result:"11" },
  { name: "White Cell Count", unit: "x 109/L", ref: "4.0-11.0", flag:"low", result:"2" },
  { name: "Neutrophilis #", unit: "x 109/L", ref: "2.00-7.50", flag:"", result:"4" },
  { name: "Neutrophilis %", unit: "%", ref: "", flag:"", result:"10" },
  { name: "Lymphocytes %", unit: "%", ref: "", flag:"", result:"56" },
  { name: "Lymphocytes #", unit: "x 109/L", ref: "1.00-4.00", flag:"", result:"2" },
  { name: "Monocytes #", unit: "x 109/L", ref: "0.00-1.00", flag:"", result:"0.06" },
  { name: "Monocytes %", unit: "%", ref: "", flag:"", result:"1" },
  { name: "Eosinophils #", unit: "x 109/L", ref: "0.00-0.40", flag:"", result:"0.01" },
  { name: "Eosinophils %", unit: "%", ref: "", flag:"", result:"2" },
  { name: "Basophils #", unit: "x 109/L", ref: "0.00-0.10", flag:"", result:"0.04" },
  { name: "Basophils %", unit: "%", ref: "", flag:"", result:"1" },
  { name: "Platelet Count", unit: "x 109/L", ref: "150-450", flag:"", result:"346" },
  // No Data for Automation
  { name: "Protein", unit: "", ref: "", flag:"", result:"" },
  { name: "Glucose", unit: "", ref: "", flag:"", result:"" },
  { name: "Leucocyte esterase", unit: "", ref: "", flag:"", result:"" },
  { name: "Colour", unit: "", ref: "", flag:"", result:"" },
  { name: "Pus cells", unit: "", ref: "", flag:"", result:"" },
  { name: "Blood", unit: "", ref: "", flag:"", result:"" },
  { name: "Epithelial cells", unit: "", ref: "", flag:"", result:"" },
  { name: "Ketone", unit: "", ref: "", flag:"", result:"" },
  { name: "Bilirubin", unit: "", ref: "", flag:"", result:"" },
  { name: "Nitrites", unit: "", ref: "", flag:"", result:"" },
  { name: "Urobilinogen", unit: "", ref: "", flag:"", result:"" },
  { name: "Microscopy", unit: "", ref: "", flag:"", result:"" },
  { name: "Macroscopy", unit: "", ref: "", flag:"", result:"" },
  { name: "Sedimentation Rate (ESR)", unit: "", ref: "", flag:"", result:"" },
  // end No Data for Automation
  
  // 23 october 2023 by mualiyuoox@gmail.com
  { name: "Carcino-embryonic antigen (CEA)", unit: "ng/mL", ref: "", flag:"", result:"" },
  { name: "CA19.9", unit: "", ref: "", flag:"", result:"" },
  { name: "Alpha Feto protein (AFP)", unit: "", ref: "", flag:"", result:"" },
  { name: "CA125", unit: "", ref: "", flag:"", result:"" },
  { name: "BHCG Screen - quantitative", unit: "", ref: "", flag:"", result:"" },
  { name: "HIV I & II + p24 Ag", unit: "", ref: "", flag:"", result:"" },
  { name: "Bilirubin Conjugated", unit: "umol/L", ref: "0 - 8", flag:"", result:"" },
  { name: "Bilirubin Total ", unit: "umol/L", ref: "2 - 20", flag:"", result:"" },
  { name: "Bilirubin Unconjugated", unit: "umol/L", ref: "2 - 14", flag:"", result:"" },
  { name: "Serum Albumin", unit: "g/L", ref: "35 - 50", flag:"", result:"" },
  { name: "Globulin Serum", unit: "g/L", ref: "18 - 36", flag:"", result:"" },
  { name: "Albumin/Globulin ratio", unit: "", ref: "0.9 - 2.7", flag:"", result:"" },
  { name: "Total protein", unit: "g/L", ref: "64 - 83", flag:"", result:"" },
  { name: "Serum Uric acid", unit: "mmol/L.", ref: "0.15 - 0.42", flag:"", result:"" },
  { name: "Serum Chloride", unit: "mmol/L.", ref: "98 - 110", flag:"", result:"" },
  { name: "HBA1C", unit: "%", ref: "4 - 5.6", flag:"", result:"" },
  { name: "Ultra sensitive C-reactive protein", unit: "mg/L", ref: "0 - 1", flag:"", result:"" },
  { name: "Creatinine Kinase MB (CK-MB)", unit: "", ref: "", flag:"", result:"" },
  { name: "Troponin ", unit: "", ref: "", flag:"", result:"" },
  { name: "Luteinising hormone (LH)", unit: "IU/L", ref: "", flag:"", result:"" },
  { name: "Follicle stimulating hormone (FSH)", unit: "IU/L", ref: "", flag:"", result:"" },
  { name: "Estradiol (E2)", unit: "pmol/L", ref: "", flag:"", result:"" },
  { name: "Estradiol (E2)", unit: "pg/mL", ref: "", flag:"", result:"" },
  { name: "Testosterone", unit: "nmol/L", ref: "0.3 - 2.7", flag:"", result:"" },
  { name: "Progesterone", unit: "", ref: "", flag:"", result:"" },
  { name: "Anti-mullerian hormone (AMH)", unit: "", ref: "", flag:"", result:"" },
  { name: "Vitamin D 25 OH", unit: "nmol/L", ref: "Low (7.5 - 47.4) - Optimal (49.9 - 124.8)", flag:"", result:"" },
  { name: "Serum Zinc", unit: "ug/dL", ref: "70 - 150", flag:"", result:"" },
  { name: "Ferritin", unit: "ng/mL", ref: "10 - 150", flag:"", result:"" },
  { name: "Serum Iron", unit: "umol/L", ref: "9 - 29", flag:"", result:"" },
  { name: "H pylori IgG/IgM", unit: "", ref: "<0.8", flag:"", result:"" },
  { name: "Urate Serum", unit: "mmol/L", ref: "0.15 - 0.42", flag:"", result:"" },
  // { name: "", unit: "", ref: "", flag:"", result:"" },

  // haematology
  { name: "Blood group", unit: "", ref: "", flag:"", result:"" },
  { name: "Genotype ", unit: "", ref: "", flag:"", result:"" },
  { name: "Hb Electrophoresis", unit: "", ref: "", flag:"", result:"" },
  { name: "Reticulocyte Corrected", unit: "", ref: "", flag:"", result:"" },
  { name: "Retic Production Index", unit: "", ref: "", flag:"", result:"" },

  // microbiology
  { name: "Trichomonas vaginalis", unit: "", ref: "", flag:"", result:"" },
  { name: "Clue Cells", unit: "", ref: "", flag:"", result:"" },
  { name: "Yeast", unit: "", ref: "", flag:"", result:"" },
  { name: "Pus Cells", unit: "", ref: "", flag:"", result:"" },
  { name: "Epithelial Cells", unit: "", ref: "", flag:"", result:"" },
  { name: "Bacteriology", unit: "", ref: "", flag:"", result:"" },
  { name: "Leukocytes", unit: "", ref: "", flag:"", result:"" },
  { name: "Epithelial cells", unit: "", ref: "", flag:"", result:"" },
  { name: "Gram Positive Cocci", unit: "", ref: "", flag:"", result:"" },
  { name: "Gram Negative Diplococci", unit: "", ref: "", flag:"", result:"" },
  { name: "Gram Negative Cocco-Bacilli", unit: "", ref: "", flag:"", result:"" },
  { name: "Gram Negative Bacilli", unit: "", ref: "", flag:"", result:"" },
  { name: "Chlamydia trachomatis IgM Ab", unit: "", ref: "", flag:"", result:"" },
  { name: "Parasites", unit: "", ref: "", flag:"", result:"" },
  { name: "Cryptosporidium Stain", unit: "", ref: "", flag:"", result:"" },
  { name: "Herpes simplex Type 1 & 2 Ab IgG", unit: "", ref: "", flag:"", result:"" },
  { name: "Malaria (RDT)", unit: "", ref: "", flag:"", result:"" },
  { name: "Malaria (Microscopy)", unit: "", ref: "", flag:"", result:"" },
  { name: "Semen analysis (spermiogram)", unit: "", ref: "", flag:"", result:"" },
  { name: "Faecal occult blood", unit: "", ref: "", flag:"", result:"" },

  // Serology
  { name: "Hepatitis B surface antigen stat (HbsAg)", unit: "", ref: "", flag:"", result:"" },
  { name: "Hepatitis C Stat", unit: "", ref: "", flag:"", result:"" },
  { name: "HIV stat", unit: "", ref: "", flag:"", result:"" },
  { name: "Syphilis (RPR)", unit: "", ref: "", flag:"", result:"" },

  // cytology
  { name: "Pap smear (Liquid based cytology)", unit: "", ref: "", flag:"", result:"" },
  { name: "HPV test", unit: "", ref: "", flag:"", result:"" },
  { name: "Non-gynaecological cytology", unit: "", ref: "", flag:"", result:"" },
  { name: "Fine needle Aspiration cytology(FNAC)", unit: "", ref: "", flag:"", result:"" },
];
export const FIELDS = [
  "Specific Gravity",
  // the new updates results Templates
  "Alkaline Phosphatase (ALP)",
  "ALT",
  "AST",
  "Blood Urea Nitrogen (BUN)",
  "Calcium, serum",
  "Corrected calcium",
  "Creatinine",
  "Estimated GFR (CKD-EPI)",
  "Fasting glucose",
  "Free T3",
  "Free T4",
  "GGT",
  "HDL",
  "LDL",
  "Potassium, serum",
  "Prolactin",
  "Random glucose",
  "Serum carbondioxide",
  "Serum urea",
  "Sodium, serum",
  "Total cholesterol",
  "Triglyceride",
  "TSH",

  "C-Reactive protein",
  "Reticulocyte Absolute count",
  "Reticulocyte percentage",
  "Vitamin B12",
  "Prostate Specific Antigen(PSA)",
  "Hepatitis A",
  "Hepatitis E",
  "Antinuclear Antibodies (ANA)",
  "G6PD",
  "2 Hour Postprandial (2HPP)",
  "Adrenocorticotropic Hormone (ACTH)",
  "Cortisol",
  "Urine Albumin Creatinine Ratio(UACR)",
  
  // end the new updates results Templates
  "Ph",
  "Haemoglobin",
  "Red blood cells",
  "PCV",
  "MCV",
  "MCH",
  "MCHC",
  "RDW",
  "White Cell Count",
  "Neutrophilis #",
  "Neutrophilis %",
  "Lymphocytes %",
  "Lymphocytes #",
  "Monocytes #",
  "Monocytes %",
  "Eosinophils %",
  "Eosinophils #",
  "Basophils %",
  "Basophils #",
  "Platelet Count",
  // No Data for Automation
  "Protein",
  "Glucose",
  "Leucocyte esterase",
  "Colour",
  "Pus cells",
  "Blood",
  "Epithelial cells",
  "Ketone",
  "Bilirubin",
  "Nitrites",
  "Urobilinogen",
  "Microscopy",
  "Macroscopy",
  "Sedimentation Rate (ESR)",
  // end of no data

  // 23 october 2023 by mualiyuoox@gmail.com
  "Carcino-embryonic antigen (CEA)",
  "CA19.9",
  "Alpha Feto protein (AFP)",
  "CA125",
  "BHCG Screen - quantitative",
  "HIV I & II + p24 Ag",
  "Bilirubin Conjugated",
  "Bilirubin Total ",
  "Bilirubin Unconjugated",
  "Serum Albumin",
  "Globulin Serum",
  "Albumin/Globulin ratio",
  "Total protein",
  "Serum Uric acid",
  "Serum Chloride",
  "HBA1C",
  "Ultra sensitive C-reactive protein",
  "Creatinine Kinase MB (CK-MB)",
  "Troponin ",
  "Luteinising hormone (LH)",
  "Follicle stimulating hormone (FSH)",
  "Estradiol (E2)",
  "Estradiol (E2)",
  "Testosterone",
  "Progesterone",
  "Anti-mullerian hormone (AMH)",
  "Vitamin D 25 OH",
  "Serum Zinc",
  "Ferritin",
  "Serum Iron",
  "H pylori IgG/IgM",
  "Urate Serum",

  // Haematology
  "Blood group",
  "Genotype ",
  "Hb Electrophoresis",
  "Reticulocyte Corrected",
  "Retic Production Index",

  // miclrobiology
  "Trichomonas vaginalis",
  "Clue Cells",
  "Yeast",
  "Pus Cells",
  "Epithelial Cells",
  "Bacteriology",
  "Leukocytes",
  "Epithelial cells",
  "Gram Positive Cocci",
  "Gram Negative Diplococci",
  "Gram Negative Cocco-Bacilli",
  "Gram Negative Bacilli",
  "Chlamydia trachomatis IgM Ab",
  "Parasites",
  "Cryptosporidium Stain",
  "Herpes simplex Type 1 & 2 Ab IgG",
  "Malaria (RDT)",
  "Malaria (Microscopy)",
  "Semen analysis (spermiogram)",
  "Faecal occult blood",

  // Serology
  "Hepatitis B surface antigen stat (HbsAg)",
  "Hepatitis C Stat",
  "HIV stat",
  "Syphilis (RPR)",

  // cytology
  "Pap smear (Liquid based cytology)",
  "HPV test",
  "Non-gynaecological cytology",
  "Fine needle Aspiration cytology(FNAC)",
  
];
export const INVENTORY_TYPE = [
  { label: "Reagent", color: "warning", value: "0" },
  { label: "Consumable", color: "success", value: "1" },
  { label: "Equipment", color: "error", value: "2" },
];
export const WITHDRAWAL_TYPES = [
  "End-of-day Withdrawal",
  "Requested Withdrawal",
];
export const SUBSCRIPTION_STATUS = [
  { label: "Inactive", color: "warning" },
  { label: "Active", color: "success" },
  { label: "Cancelled", color: "error" },
];
export const NOTIFICATION_STATUS = [
  { label: "Unread", color: "warning" },
  { label: "Read", color: "success" },
];
export const EXPORT_STATUS = [
  { label: "Pending", color: "warning" },
  { label: "Exported", color: "success" },
];
export const MARITALS = [
  { label: "Single", value: "single" },
  { label: "Married", value: "married" },
];
export const SUPPORT_REASONS = [
  "Enquiries",
  "Billing & Subscription",
  "Payment Error",
  "System Error",
  "Others",
];
export const LOG_TYPES = [
  { label: "System", value: "0", color: "primary" },
  { label: "Administrator", value: "1", color: "secondary" },
  { label: "Role", value: "2", color: "secondary" },
  { label: "Staff", value: "3", color: "secondary" },
  { label: "Branch", value: "4", color: "info" },
  { label: "HELP Category", value: "5", color: "info" },
  { label: "Customer", value: "6", color: "info" },
  { label: "HELP", value: "7", color: "info" },
  { label: "Sale/Invoice", value: "8", color: "info" },
  { label: "Returned HELP", value: "9", color: "info" },
  { label: "Debtor", value: "10", color: "info" },
  { label: "Damaged HELP", value: "11", color: "info" },
  { label: "Purchases", value: "12", color: "info" },
  { label: "Online Sales", value: "13", color: "info" },
  { label: "Gift Card", value: "14", color: "info" },
  { label: "Loyalty Card", value: "15", color: "info" },
  { label: "Wallet", value: "16", color: "warning" },
  { label: "Withdrawal", value: "17", color: "warning" },
  { label: "Expenses", value: "18", color: "warning" },
  { label: "Subscription", value: "19", color: "warning" },
  { label: "Message", value: "20", color: "success" },
  { label: "Import/Export", value: "21", color: "success" },
  { label: "Login", value: "22", color: "success" },
];
export const PAYSTACK_KEY = "pk_live_d702d9481331a20dd35beded7e602138e7437479";
export const CheckPerm = (perm) => {
  const { user } = useAuth();
  if (perm === "") {
    return true;
  }
  const permissions =
    user && user.role && user.role.permissions && user.role.permissions !== ""
      ? user.role.permissions.split(",")
      : [];
  if (perm.split(",").length > 0) {
    return perm.split(",").some((p) => permissions.includes(p));
  }
  return permissions.includes(perm);
};
export const GetSettings = (name) => {
  const { user } = useAuth();
  const { settings } = user;
  const vals = {};
  settings.map((setting) => (vals[setting.name] = setting.value));
  return Object.prototype.hasOwnProperty.call(vals, name)
    ? vals[name] === "1"
    : false;
};
export const CheckOnboardingDone = () => {
  const { user } = useAuth();
  const { shop, account, subscription, compliance } = user;
  const settingsDone =
    shop && shop.logo && shop.address && account && account.code;
  const subDone = subscription && Object.keys(subscription).includes("plan");
  const complianceDone =
    compliance &&
    Object.keys(compliance).includes("status") &&
    parseInt(compliance.status, 10) === 1;
  return settingsDone && subDone && complianceDone;
};
export const CheckSub = (action) => {
  /**
   * Sub response type
   * 0 - new user
   * 1 - active
   * 2 - cancelled.
   */

  const { user } = useAuth();
  const { subscription } = user;

  if (action === "" || action === "0") {
    // free features
    return 1;
  }

  if (subscription && Object.keys(subscription).includes("plan")) {
    const status = parseInt(subscription.status, 10);
    if (status === 1 || status === 2) {
      // active or cancelled subscription
      const features = subscription.features
        ? JSON.parse(subscription.features)
        : [];
      const isComplied =
        user &&
        user.compliance &&
        user.compliance.status &&
        parseInt(user.compliance.status, 10) === 1;
      if (action.split(",").length > 0) {
        if (
          (action.split(",").includes("8") ||
            action.split(",").includes("13")) &&
          !isComplied
        ) {
          // check if action is sales or online sales
          return 2;
        }
        return action.split(",").some((a) => features.includes(a)) ? 1 : 2;
      }
      if (features.includes(action)) {
        if ((action === "8" || action === "13") && !isComplied) {
          // check if action is sales or online sales
          return 2;
        }
        return 1;
      }
    }
    return 2;
  }
  // new user
  return 0;
};
export const CalcCharge = (amount, isBank = false) => {
  if (isBank) {
    return 0.01 * amount > 300 ? 300 : 0.01 * amount;
  }
  const decimalFee = 0.015;
  const FlatFee = 100;
  const FlatFeeCap = 2500;
  const ChargeCap = 2000;

  let price = 0;
  let applicableFees = 0;
  if (amount > FlatFeeCap) {
    applicableFees = decimalFee * amount + FlatFee;
    price = Math.round(
      applicableFees > ChargeCap
        ? amount + ChargeCap
        : (amount + FlatFee) / (1 - decimalFee) + 0.01
    );
  } else {
    applicableFees = decimalFee * amount;
    price = Math.round(
      applicableFees > ChargeCap
        ? amount + ChargeCap
        : amount / (1 - decimalFee) + 0.01
    );
  }
  return price;
};
export const CopyToBoard = (barcode, enqueueSnackbar, toCopy = "") => {
  navigator.clipboard.writeText(barcode);
  enqueueSnackbar(toCopy || "Barcode copied to clipboard.", {
    variant: "success",
  });
};
export const isValidEmail = (email) =>
  String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
export const PAGE_PERMS = [
  {
    path: PATH_DASHBOARD.root,
    permission: "view_dashboard_analytics",
  },
  {
    path: PATH_DASHBOARD.laboratory.patients,
    permission: "view_patients",
  },
  {
    path: PATH_DASHBOARD.laboratory.addPatient,
    permission: "add_patient",
  },
  {
    path: PATH_DASHBOARD.subpages.patient,
    permission: "view_patients",
  },
  {
    path: PATH_DASHBOARD.subpages.editPatient,
    permission: "update_patients",
  },
  {
    path: PATH_DASHBOARD.laboratory.samples,
    permission: "view_samples",
  },
  {
    path: PATH_DASHBOARD.laboratory.addSample,
    permission: "add_sample",
  },
  {
    path: PATH_DASHBOARD.subpages.logSample,
    permission: "add_sample",
  },
  {
    path: PATH_DASHBOARD.laboratory.tests,
    permission: "view_orders",
  },
  {
    path: PATH_DASHBOARD.laboratory.addTest,
    permission: "add_order",
  },
  {
    path: PATH_DASHBOARD.subpages.trackTest,
    permission: "view_orders",
  },
  {
    path: PATH_DASHBOARD.subpages.testResult,
    permission: "submit_result",
  },
  {
    path: PATH_DASHBOARD.subpages.showResult,
    permission: "view_results",
  },
  {
    path: PATH_DASHBOARD.laboratory.inventory,
    permission: "view_inventory",
  },
  {
    path: PATH_DASHBOARD.laboratory.addInventory,
    permission: "add_inventory",
  },
  {
    path: PATH_DASHBOARD.laboratory.documents,
    permission: "view_documents",
  },
  {
    path: PATH_DASHBOARD.subpages.document,
    permission: "view_documents",
  },
  {
    path: PATH_DASHBOARD.laboratory.approvals,
    permission: "approve_or_reject_orders",
  },
  {
    path: PATH_DASHBOARD.laboratory.appointments,
    permission: "view_appointments",
  },
  {
    path: PATH_DASHBOARD.laboratory.billings,
    permission: "view_payments",
  },
  {
    path: PATH_DASHBOARD.laboratory.personnel,
    permission: "view_staff",
  },
  {
    path: PATH_DASHBOARD.laboratory.addPersonnel,
    permission: "add_staff",
  },
  {
    path: PATH_DASHBOARD.subpages.personnel,
    permission: "update_staff",
  },
  {
    path: PATH_DASHBOARD.laboratory.analytics,
    permission: "view_analytics",
  },
  {
    path: PATH_DASHBOARD.laboratory.help,
    permission: "view_help_desk",
  },
  {
    path: PATH_DASHBOARD.laboratory.log,
    permission: "view_activity_logs",
  },
  {
    path: PATH_DASHBOARD.laboratory.devices,
    permission: "view_devices",
  },
];
