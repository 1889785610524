import { useState } from "react";
import { Outlet } from "react-router-dom";
// material
import { styled, useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";
// hooks
import useCollapseDrawer from "../../hooks/useCollapseDrawer";
//
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./DashboardSidebar";
import RoleBasedGuard from "../../guards/RoleBasedGuard";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
  backgroundColor: theme.palette.primary.main,
}));

const MainStyle = styled("div")(({ theme }) => ({
  position: "absolute",
  flexGrow: 1,
  overflow: "scroll",
  minHeight: "100%",
  bottom: 0,
  top: 0,
  right: 0,
  left: 0,
  paddingBottom: theme.spacing(10),
  backgroundColor: theme.palette.primary.main,
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  [theme.breakpoints.up("lg")]: {
    borderTopLeftRadius: 25,
    borderBottomLeftRadius: 25,
    left: 280,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const theme = useTheme();
  const { collapseClick } = useCollapseDrawer();
  const [open, setOpen] = useState(false);

  return (
    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar
        isOpenSidebar={open}
        onCloseSidebar={() => setOpen(false)}
      />

      <MainStyle
        sx={{
          backgroundColor: "background.default",
          transition: theme.transitions.create("margin", {
            duration: theme.transitions.duration.complex,
          }),
          ...(collapseClick && {
            left: "102px",
          }),
        }}
      >
        <RoleBasedGuard>
          <Box sx={{ pt: 12 }}>
            <Outlet />
          </Box>
        </RoleBasedGuard>
      </MainStyle>
    </RootStyle>
  );
}
