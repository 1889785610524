// components
import DashboardIcon from "@mui/icons-material/Dashboard";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import HubIcon from "@mui/icons-material/Hub";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import VaccinesIcon from "@mui/icons-material/Vaccines";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import ApprovalIcon from "@mui/icons-material/Approval";
import PieChartIcon from "@mui/icons-material/PieChart";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import EventNoteIcon from "@mui/icons-material/EventNote";
import PaymentsIcon from "@mui/icons-material/Payments";
// routes
import { PATH_DASHBOARD } from "../../routes/paths";

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "",
    items: [
      {
        title: "Dashboard",
        path: PATH_DASHBOARD.general.root,
        icon: <DashboardIcon />,
      },
    ],
  },
  {
    subheader: "laboratory",
    items: [
      {
        title: "Patients",
        path: PATH_DASHBOARD.laboratory.patients,
        icon: <RecentActorsIcon />,
      },
      {
        title: "Samples",
        path: PATH_DASHBOARD.laboratory.samples,
        icon: <VaccinesIcon />,
      },
      {
        title: "Tests and Results",
        path: PATH_DASHBOARD.laboratory.tests,
        icon: <LibraryBooksIcon />,
      },
      {
        title: "Inventory",
        path: PATH_DASHBOARD.laboratory.inventory,
        icon: <NoteAltIcon />,
      },
      {
        title: "Document Manager",
        path: PATH_DASHBOARD.laboratory.documents,
        icon: <FolderCopyIcon />,
      },
      {
        title: "Approval",
        path: PATH_DASHBOARD.laboratory.approvals,
        icon: <ApprovalIcon />,
      },
      {
        title: "Appointments",
        path: PATH_DASHBOARD.laboratory.appointments,
        icon: <EventNoteIcon />,
      },
      {
        title: "Payments",
        path: PATH_DASHBOARD.laboratory.billings,
        icon: <PaymentsIcon />,
      },
      {
        title: "Personnel Manager",
        path: PATH_DASHBOARD.laboratory.personnel,
        icon: <SupervisorAccountIcon />,
      },
      {
        title: "Analytics",
        path: PATH_DASHBOARD.laboratory.analytics,
        icon: <PieChartIcon />,
      },
    ],
  },
  {
    subheader: "",
    items: [
      {
        title: "Activities / Log",
        path: PATH_DASHBOARD.laboratory.log,
        icon: <HubIcon />,
      },
      {
        title: "Help Desk",
        path: PATH_DASHBOARD.laboratory.help,
        icon: <QuestionAnswerIcon />,
      },
    ],
  },
];

export default sidebarConfig;
