import { createContext, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { osName, browserName } from "react-device-detect";
// utils
import axios from "../utils/axios";
import { API_PATH } from "../utils/apis";
import {
  setSession,
  setHeader,
  setCurrentLab,
  getCurrentLab,
} from "../utils/jwt";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  isSwitchingLab: false,
  user: null,
  currentLab: {},
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user, currentLab } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      isSwitchingLab: false,
      user,
      currentLab,
    };
  },
  SWITCH_LAB: (state, action) => {
    const { isSwitchingLab } = action.payload;
    return {
      ...state,
      isSwitchingLab,
    };
  },
  UPDATE: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user, currentLab } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user,
      currentLab,
    };
  },
  LOGOUT: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: false,
      isSwitchingLab: false,
      currentLab: {},
      user,
    };
  },
  VERIFY: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  REGISTER: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  method: "jwt",
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const initialize = async () => {
    setHeader();
    let isAuthenticated = false;
    let user = {};
    let currentLab = {};
    try {
      const response = await axios.post(API_PATH.profile, {
        get: getCurrentLab(),
      });
      const { status, data } = response.data;
      user = data;
      if (status === "success") {
        isAuthenticated = Boolean(user.user.uid);
        const { labs } = user;
        if (getCurrentLab() === "") {
          setCurrentLab(labs[0].bid);
        }
        // eslint-disable-next-line
        currentLab = labs.filter((lab) => lab.lid === getCurrentLab())[0];
      }

      if (
        status === "userid_expired" ||
        status === "userid_error" ||
        status === "invalid_profile"
      ) {
        isAuthenticated = false;
        setSession(null);
        setCurrentLab(null);
      }
    } catch (err) {
      // null
    }
    dispatch({
      type: "INITIALIZE",
      payload: {
        isAuthenticated,
        user,
        currentLab,
      },
    });
  };

  useEffect(() => {
    initialize();
  }, []);

  const logout = async (isPopUp) => {
    if (isPopUp) {
      const currentUser = state.user.user;
      localStorage.setItem("popupName", currentUser.fname);
      localStorage.setItem("popupEmail", currentUser.email);
    } else {
      localStorage.removeItem("popupName");
      localStorage.removeItem("popupEmail");
    }

    const { shop } = state.user;
    const user = { shop };
    logDevice(0);
    setSession(null);
    setCurrentLab(null);
    dispatch({ type: "LOGOUT", payload: { user, isPopUp } });
  };
  const login = async (username, password) => {
    const response = await axios.post(API_PATH.login, {
      username,
      password,
    });
    const { status, data, token } = response.data;
    if (status === "success") {
      setSession(token);
      if (getCurrentLab() === "") {
        setCurrentLab(data.labs[0].lid);
      }
      const currentLab = data.labs.filter(
        (lab) => lab.lid === getCurrentLab()
      )[0];
      logDevice(1);
      dispatch({
        type: "LOGIN",
        payload: { user: data, currentLab },
      });
    }
    if (status === "invalid_login") {
      throw new Error(
        "Your username or password is incorrect! Please confirm and try again."
      );
    }
    if (status === "suspended") {
      throw new Error(
        "Your account has been placed on hold! Please contact your shop administrator."
      );
    }
  };
  const logDevice = async (status) => {
    axios.post(API_PATH.login, {
      name: browserName,
      os: osName,
      status,
      type: 1,
    });
  };
  const switchLab = async (labId) => {
    const currentLab = getCurrentLab();
    if (currentLab === labId) {
      throw new Error("This lab is currently selected.");
    }
    const isSwitchingLab = true;
    dispatch({ type: "SWITCH_LAB", payload: { isSwitchingLab } });
    setCurrentLab(labId);
    initialize();
  };
  const getPatients = async (patients) => {
    const response = await axios.post(API_PATH.misc, {
      patients: patients || "",
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    return [];
  };
  const getDepartments = async (departments) => {
    const response = await axios.post(API_PATH.misc, { departments });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    return [];
  };
  const getLabs = async (labs) => {
    const response = await axios.post(API_PATH.misc, { labs });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    return [];
  };
  const getJobs = async (jobs) => {
    const response = await axios.post(API_PATH.misc, { jobs });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    return [];
  };
  const getCategories = async (categories) => {
    const response = await axios.post(API_PATH.misc, { categories });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    return [];
  };
  const getPackages = async (packages) => {
    const response = await axios.post(API_PATH.misc, { packages });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    return [];
  };
  const getAdmins = async (admins) => {
    const response = await axios.post(API_PATH.misc, { admins });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    return [];
  };
  const getOrders = async (orders) => {
    const response = await axios.post(API_PATH.misc, { orders });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    return [];
  };
  const getRawTests = async (tests) => {
    const response = await axios.post(API_PATH.misc, { tests });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    return [];
  };
  const getAllFolders = async (search) => {
    const response = await axios.post(API_PATH.documents, {
      folders: search || "",
    });
    const { status, data } = response.data;
    if (status === "success") {
      if (data.length > 0) {
        return data;
      }
      throw new Error(
        "No Folder created! Please click on the button below to add."
      );
    }
    throw new Error(
      "Something went wrong while fetching your folders! Please try again later."
    );
  };
  const getFileSeens = async (seens) => {
    const response = await axios.post(API_PATH.documents, {
      seens,
    });
    const { status, data } = response.data;
    if (status === "success") {
      if (data.length > 0) {
        return data;
      }
      throw new Error("No Personnel at the moment!");
    }
    throw new Error(
      "Something went wrong while fetching personnels! Please try again later."
    );
  };
  const getLabes = async () => {
    const response = await axios.post(API_PATH.misc, { labs: "" });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    return [];
  };
  const addIventory = async (values) => {
    const response = await axios.post(API_PATH.inventory, {
      ...values,
      add: "",
    });
    const { status, iid } = response.data;
    if (status === "success") {
      return iid;
    }
    if (status === "name_exists") {
      throw new Error(
        "An item already exists with the name specified! Please go to its details page or enter a new name to proceed."
      );
    }
    if (status === "invalid_fields") {
      throw new Error(
        "There is an error with the data supplied! Please confirm your entries and try again."
      );
    }
    throw new Error(
      "Something went wrong while attempting to add item! Please try again later."
    );
  };
  const addTest = async (values) => {
    const response = await axios.post(API_PATH.rawTests, {
      ...values,
      lab: getCurrentLab(),
      add: "",
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    if (status === "name_exists") {
      throw new Error(
        "A test already exists with the name specified! Please go to its details page or enter a new name to proceed."
      );
    }
    if (status === "invalid_category") {
      throw new Error(
        "The category selected is not valid or does not exists! Please select a valid category to proceed."
      );
    }
    if (status === "invalid_department") {
      throw new Error(
        "The department selected is not valid or does not exists! Please select a valid department to proceed."
      );
    }
    if (status === "invalid_fields") {
      throw new Error(
        "There is an error with the data supplied! Please confirm your entries and try again."
      );
    }
    throw new Error(
      "Something went wrong while attempting to add test! Please try again later."
    );
  };
  const addAppointment = async (values) => {
    const response = await axios.post(API_PATH.appointments, {
      ...values,
      add: "",
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    if (status === "invalid_patient") {
      throw new Error(
        "A patient already exists with the name specified! Please select a valid patient to proceed."
      );
    }
    if (status === "invalid_lab") {
      throw new Error(
        "The lab selected is not valid or does not exists! Please select a valid lab to proceed."
      );
    }
    if (status === "invalid_doctor") {
      throw new Error(
        "The physician selected is not valid or does not exists! Please select a valid physician to proceed."
      );
    }
    if (status === "invalid_fields") {
      throw new Error(
        "There is an error with the data supplied! Please confirm your entries and try again."
      );
    }
    throw new Error(
      "Something went wrong while attempting to add appointment! Please try again later."
    );
  };
  const addOrder = async (values) => {
    const response = await axios.post(API_PATH.tests, {
      ...values,
      tests: JSON.stringify(values.tests),
      lab: getCurrentLab(),
      add: "",
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    if (status === "invalid_patient") {
      throw new Error(
        "The patient selected is not valid or does not exists! Please select a valid patient to proceed."
      );
    }
    if (status === "invalid_fields") {
      throw new Error(
        "There is an error with the data supplied! Please confirm your entries and try again."
      );
    }
    throw new Error(
      "Something went wrong while attempting to create order! Please try again later."
    );
  };
  const updateTest = async (values) => {
    const response = await axios.post(API_PATH.rawTests, {
      ...values,
      lab: getCurrentLab(),
      update: values.tid,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    if (status === "invalid_test") {
      throw new Error(
        "The test selected is not valid or does not exists! Please select a valid test to proceed."
      );
    }
    if (status === "invalid_category") {
      throw new Error(
        "The category selected is not valid or does not exists! Please select a valid category to proceed."
      );
    }
    if (status === "invalid_department") {
      throw new Error(
        "The department selected is not valid or does not exists! Please select a valid department to proceed."
      );
    }
    if (status === "invalid_fields") {
      throw new Error(
        "There is an error with the data supplied! Please confirm your entries and try again."
      );
    }
    throw new Error(
      "Something went wrong while attempting to update test! Please try again later."
    );
  };
  const updateAppointment = async (values) => {
    const response = await axios.post(API_PATH.appointments, {
      ...values,
      update: values.aid,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    if (status === "invalid_appointment") {
      throw new Error(
        "The appointment selected is not valid or does not exists! Please select a valid appointment to proceed."
      );
    }
    if (status === "invalid_lab") {
      throw new Error(
        "The lab selected is not valid or does not exists! Please select a valid lab to proceed."
      );
    }
    if (status === "invalid_doctor") {
      throw new Error(
        "The physician selected is not valid or does not exists! Please select a valid physician to proceed."
      );
    }
    if (status === "invalid_fields") {
      throw new Error(
        "There is an error with the data supplied! Please confirm your entries and try again."
      );
    }
    throw new Error(
      "Something went wrong while attempting to update test! Please try again later."
    );
  };
  const addCategory = async (values) => {
    const response = await axios.post(API_PATH.categories, {
      ...values,
      add: "",
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    if (status === "name_exists") {
      throw new Error(
        "A category already exists with the name specified! Please go to its details page or enter a new name to proceed."
      );
    }
    if (status === "invalid_fields") {
      throw new Error(
        "There is an error with the data supplied! Please confirm your entries and try again."
      );
    }
    throw new Error(
      "Something went wrong while attempting to add category! Please try again later."
    );
  };
  const updateCategory = async (values) => {
    const response = await axios.post(API_PATH.categories, {
      ...values,
      update: values.cid,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    if (status === "invalid_category") {
      throw new Error(
        "The category selected is not valid or does not exists! Please select a valid category to proceed."
      );
    }
    if (status === "invalid_fields") {
      throw new Error(
        "There is an error with the data supplied! Please confirm your entries and try again."
      );
    }
    throw new Error(
      "Something went wrong while attempting to update category! Please try again later."
    );
  };
  const addLab = async (values) => {
    const response = await axios.post(API_PATH.labs, {
      ...values,
      add: "",
    });
    const { status, data } = response.data;
    if (status === "success") {
      initialize();
      return data;
    }
    if (status === "name_exists") {
      throw new Error(
        "A lab already exists with the name specified! Please go to its details page or enter a new name to proceed."
      );
    }
    if (status === "invalid_fields") {
      throw new Error(
        "There is an error with the data supplied! Please confirm your entries and try again."
      );
    }
    throw new Error(
      "Something went wrong while attempting to add lab! Please try again later."
    );
  };
  const updateLab = async (values) => {
    const response = await axios.post(API_PATH.labs, {
      ...values,
      update: values.lid,
    });
    const { status, data } = response.data;
    if (status === "success") {
      initialize();
      return data;
    }
    if (status === "invalid_lab") {
      throw new Error(
        "The lab selected is not valid or does not exists! Please select a valid lab to proceed."
      );
    }
    if (status === "invalid_fields") {
      throw new Error(
        "There is an error with the data supplied! Please confirm your entries and try again."
      );
    }
    throw new Error(
      "Something went wrong while attempting to update lab! Please try again later."
    );
  };
  const addDepartment = async (values) => {
    const response = await axios.post(API_PATH.departments, {
      ...values,
      add: "",
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    if (status === "name_exists") {
      throw new Error(
        "A department already exists with the name specified! Please go to its details page or enter a new name to proceed."
      );
    }
    if (status === "invalid_fields") {
      throw new Error(
        "There is an error with the data supplied! Please confirm your entries and try again."
      );
    }
    throw new Error(
      "Something went wrong while attempting to add department! Please try again later."
    );
  };
  const updateDepartment = async (values) => {
    const response = await axios.post(API_PATH.departments, {
      ...values,
      update: values.did,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    if (status === "invalid_department") {
      throw new Error(
        "The department selected is not valid or does not exists! Please select a valid department to proceed."
      );
    }
    if (status === "invalid_fields") {
      throw new Error(
        "There is an error with the data supplied! Please confirm your entries and try again."
      );
    }
    throw new Error(
      "Something went wrong while attempting to update department! Please try again later."
    );
  };
  const addPackage = async (values) => {
    const response = await axios.post(API_PATH.packages, {
      ...values,
      add: "",
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    if (status === "name_exists") {
      throw new Error(
        "A package already exists with the name specified! Please go to its details page or enter a new name to proceed."
      );
    }
    if (status === "invalid_fields") {
      throw new Error(
        "There is an error with the data supplied! Please confirm your entries and try again."
      );
    }
    throw new Error(
      "Something went wrong while attempting to add package! Please try again later."
    );
  };
  const updatePackage = async (values) => {
    const response = await axios.post(API_PATH.packages, {
      ...values,
      update: values.pid,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    if (status === "invalid_package") {
      throw new Error(
        "The package selected is not valid or does not exists! Please select a valid package to proceed."
      );
    }
    if (status === "invalid_fields") {
      throw new Error(
        "There is an error with the data supplied! Please confirm your entries and try again."
      );
    }
    throw new Error(
      "Something went wrong while attempting to update package! Please try again later."
    );
  };
  const sendReply = async (reply, id) => {
    const response = await axios.post(API_PATH.helpdesk, {
      reply,
      update: id,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    if (status === "invalid_help") {
      throw new Error(
        "The message you selected is invalid! Please select a valid one and try again."
      );
    }
    if (status === "invalid_fields") {
      throw new Error(
        "There is an error with the data supplied! Please confirm your entries and try again."
      );
    }
    throw new Error(
      "Something went wrong while attempting to send a reply! Please try again later."
    );
  };
  const sendResult = async (order, email) => {
    const response = await axios.post(API_PATH.tests, {
      send: order,
      email,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    if (status === "invalid_help") {
      throw new Error(
        "The message you selected is invalid! Please select a valid one and try again."
      );
    }
    if (status === "invalid_fields") {
      throw new Error(
        "There is an error with the data supplied! Please confirm your entries and try again."
      );
    }
    throw new Error(
      "Something went wrong while attempting to send a reply! Please try again later."
    );
  };
  const uploadFile = async (formData) => {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    const response = await axios.post(API_PATH.documents, formData, config);
    const { status, data, name } = response.data;
    if (status === "success") {
      return { data, name };
    }
    throw new Error(
      "Something went wrong while attempting to upload file! Please try again later."
    );
  };
  const addFolder = async (name, description) => {
    const response = await axios.post(API_PATH.documents, {
      name,
      description,
      addFolder: "",
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    if (status === "name_exists") {
      throw new Error(
        "A folder already exists with the name specified! Please enter a new name to proceed."
      );
    }
    if (status === "invalid_fields") {
      throw new Error(
        "There is an error with the data supplied! Please confirm your entries and try again."
      );
    }
    throw new Error(
      "Something went wrong while attempting to add folder! Please try again later."
    );
  };
  const addPatient = async (values) => {
    const response = await axios.post(API_PATH.patients, {
      ...values,
      add: "",
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    if (status === "name_exists") {
      throw new Error(
        "A patient already exists with the name specified! Please go to their profile or enter a new name to proceed."
      );
    }
    if (status === "email_exists") {
      throw new Error(
        "A patient already exists with the email address specified! Please go to their profile or enter a new name to proceed."
      );
    }
    if (status === "invalid_fields") {
      throw new Error(
        "There is an error with the data supplied! Please confirm your entries and try again."
      );
    }
    throw new Error(
      "Something went wrong while attempting to add patient! Please try again later."
    );
  };
  const addPersonnel = async (values) => {
    const response = await axios.post(API_PATH.personnels, {
      ...values,
      role: values.role ? values.role.rid : "",
      lab: values.lab ? values.lab.lid : "",
      job: values.job ? values.job.uid : "",
      department: values.department ? values.department.did : "",
      add: "",
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    if (status === "name_exists") {
      throw new Error(
        "A personnel already exists with the name specified! Please go to their profile or enter a new name to proceed."
      );
    }
    if (status === "email_exists") {
      throw new Error(
        "A personnel already exists with the email address specified! Please go to their profile or enter a new name to proceed."
      );
    }
    if (status === "invalid_fields") {
      throw new Error(
        "There is an error with the data supplied! Please confirm your entries and try again."
      );
    }
    throw new Error(
      "Something went wrong while attempting to add personnel! Please try again later."
    );
  };
  const addRole = async (name, description, permissions) => {
    const response = await axios.post(API_PATH.roles, {
      name,
      description,
      selectedPermissions: JSON.stringify(permissions),
      add: "",
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    if (status === "name_exists") {
      throw new Error(
        "A patient already exists with the name specified! Please go to their profile or enter a new name to proceed."
      );
    }
    if (status === "email_exists") {
      throw new Error(
        "A patient already exists with the email address specified! Please go to their profile or enter a new name to proceed."
      );
    }
    if (status === "invalid_fields") {
      throw new Error(
        "There is an error with the data supplied! Please confirm your entries and try again."
      );
    }
    throw new Error(
      "Something went wrong while attempting to add patient! Please try again later."
    );
  };
  const addFile = async (folder, file, name) => {
    const response = await axios.post(API_PATH.documents, {
      folder: folder.fid || "",
      file,
      name,
      add: "",
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    if (status === "invalid_folder") {
      throw new Error(
        "The folder selected is invalid or does not exist! Please select a valid folder to proceed."
      );
    }
    if (status === "invalid_fields") {
      throw new Error(
        "There is an error with the data supplied! Please confirm your entries and try again."
      );
    }
    throw new Error(
      "Something went wrong while attempting to add file! Please try again later."
    );
  };
  const removeFolder = async (folder) => {
    const response = await axios.post(API_PATH.documents, {
      removeFolder: folder,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    if (status === "invalid_folder") {
      throw new Error(
        "The folder selected is invalid or does not exist! Please select a valid folder to proceed."
      );
    }
    if (status === "invalid_fields") {
      throw new Error(
        "There is an error with the data supplied! Please confirm your entries and try again."
      );
    }
    throw new Error(
      "Something went wrong while attempting to remove folder! Please try again later."
    );
  };
  const removeRole = async (role) => {
    const response = await axios.post(API_PATH.roles, {
      remove: role,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    if (status === "invalid_role") {
      throw new Error(
        "The role selected is invalid or does not exist! Please select a valid role to proceed."
      );
    }
    if (status === "invalid_fields") {
      throw new Error(
        "There is an error with the data supplied! Please confirm your entries and try again."
      );
    }
    throw new Error(
      "Something went wrong while attempting to remove role! Please try again later."
    );
  };
  const moveFile = async (folder, file) => {
    const response = await axios.post(API_PATH.documents, {
      folder,
      file,
      move: "",
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    if (status === "invalid_folder") {
      throw new Error(
        "The folder selected is invalid or does not exist! Please select a valid folder to proceed."
      );
    }
    if (status === "invalid_fields") {
      throw new Error(
        "There is an error with the data supplied! Please confirm your entries and try again."
      );
    }
    throw new Error(
      "Something went wrong while attempting to move file! Please try again later."
    );
  };
  const renameFile = async (file, name) => {
    const response = await axios.post(API_PATH.documents, {
      name,
      file,
      rename: "",
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    if (status === "invalid_file") {
      throw new Error(
        "The file selected is invalid or does not exist! Please select a valid folder to proceed."
      );
    }
    if (status === "invalid_fields") {
      throw new Error(
        "There is an error with the data supplied! Please confirm your entries and try again."
      );
    }
    throw new Error(
      "Something went wrong while attempting to rename file! Please try again later."
    );
  };
  const updateOrder = async (values) => {
    const response = await axios.post(API_PATH.tests, {
      ...values,
      tests: JSON.stringify(values.tests),
      update: values.order,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occurred while updating the order! Please try again later."
      );
    }
  };
  const updateRole = async (name, description, permissions, rid) => {
    const response = await axios.post(API_PATH.roles, {
      name,
      description,
      selectedPermissions: JSON.stringify(permissions),
      update: rid,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occurred while updating the role! Please try again later."
      );
    } else {
      initialize();
    }
  };
  const getLab = async (lab) => {
    if (!lab) {
      throw new Error(
        "Invalid lab's ID supplied! Please go back to the labs page to select the right one."
      );
    }
    const response = await axios.post(API_PATH.labs, { lab });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }

    if (status === "not_found") {
      throw new Error(
        "Invalid lab's ID supplied! Please go back to the labs page to select the right one."
      );
    }
    throw new Error(
      "Something went wrong while fetching lab's data! Please try again later."
    );
  };
  const updateLabStatus = async (newStatus, uid) => {
    const response = await axios.post(API_PATH.labs, {
      update_status: uid,
      newStatus,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occurred while updating the lab's status! Please try again later."
      );
    }
  };

  const getOrder = async (order) => {
    if (!order) {
      throw new Error(
        "Invalid order's ID supplied! Please go back to the orders page to select the right one."
      );
    }
    const response = await axios.post(API_PATH.tests, { order });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }

    if (status === "not_found") {
      throw new Error(
        "Invalid order's ID supplied! Please go back to the orders page to select the right one."
      );
    }
    throw new Error(
      "Something went wrong while fetching order's data! Please try again later."
    );
  };
  const getPatientAnalytics = async (filter) => {
    const response = await axios.post(API_PATH.analytics, {
      patients: "",
      ...filter,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    throw new Error(
      "Something went wrong while fetching the patients' analytics! Please try again later."
    );
  };
  const getPersonnelsAnalytics = async (filter) => {
    const response = await axios.post(API_PATH.analytics, {
      personnels: "",
      ...filter,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    throw new Error(
      "Something went wrong while fetching the personnels' analytics! Please try again later."
    );
  };

  const getTestsAnalytics = async (filter) => {
    const response = await axios.post(API_PATH.analytics, {
      tests: "",
      ...filter,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    throw new Error(
      "Something went wrong while fetching the tests' analytics! Please try again later."
    );
  };

  const getPatient = async (patient) => {
    if (!patient) {
      throw new Error(
        "Invalid patient's ID supplied! Please go back to the patients page to select the right one."
      );
    }
    const response = await axios.post(API_PATH.patients, { patient });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }

    if (status === "not_found") {
      throw new Error(
        "Invalid patient's ID supplied! Please go back to the patients page to select the right one."
      );
    }
    throw new Error(
      "Something went wrong while fetching patient's data! Please try again later."
    );
  };
  const getInventory = async (inventory) => {
    if (!inventory) {
      throw new Error(
        "Invalid patient's ID supplied! Please go back to the inventory page to select the right one."
      );
    }
    const response = await axios.post(API_PATH.inventory, { inventory });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }

    if (status === "not_found") {
      throw new Error(
        "Invalid item's ID supplied! Please go back to the inventory page to select the right one."
      );
    }
    throw new Error(
      "Something went wrong while fetching item's data! Please try again later."
    );
  };
  const getDocument = async (document) => {
    if (!document) {
      throw new Error(
        "Invalid patient's ID supplied! Please go back to the documents page to select the right one."
      );
    }
    const response = await axios.post(API_PATH.documents, { document });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }

    if (status === "not_found") {
      throw new Error(
        "Invalid item's ID supplied! Please go back to the documents page to select the right one."
      );
    }
    throw new Error(
      "Something went wrong while fetching file's data! Please try again later."
    );
  };
  const getPersonnel = async (personnel) => {
    if (!personnel) {
      throw new Error(
        "Invalid personnel's ID supplied! Please go back to the personnel page to select the right one."
      );
    }
    const response = await axios.post(API_PATH.personnels, { personnel });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }

    if (status === "not_found") {
      throw new Error(
        "Invalid personnel's ID supplied! Please go back to the personnel page to select the right one."
      );
    }
    throw new Error(
      "Something went wrong while fetching personnel's data! Please try again later."
    );
  };
  const updatePatient = async (values, uid) => {
    const response = await axios.post(API_PATH.patients, {
      ...values,
      update: uid,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occurred while updating the patient's details! Please try again later."
      );
    }
  };
  const readDocument = async (file) => {
    const response = await axios.post(API_PATH.documents, {
      markAsRead: file,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occurred while performing this action! Please try again later."
      );
    }
  };
  const logSample = async (samples, order, comments) => {
    const response = await axios.post(API_PATH.samples, {
      samples: JSON.stringify(samples),
      log: order,
      comments,
    });
    const { status } = response.data;
    if (status === "invalid_fields") {
      throw new Error(
        "Invalid data supplied! Please confirm you uploaded the correct data to proceed."
      );
    }
    if (status === "invalid_order") {
      throw new Error(
        "Invalid order id supplied! Please confirm you uploaded the correct data to proceed."
      );
    }
    if (status !== "success") {
      throw new Error(
        "An error occurred while logging samples! Please try again later."
      );
    }
  };
  const submitTestResult = async (results, order, comments) => {
    const response = await axios.post(API_PATH.tests, {
      results: JSON.stringify(results),
      submit: order,
      comments,
    });
    const { status } = response.data;
    if (status === "invalid_fields") {
      throw new Error(
        "Invalid data supplied! Please confirm you uploaded the correct data to proceed."
      );
    }
    if (status === "invalid_order") {
      throw new Error(
        "Invalid order id supplied! Please confirm you uploaded the correct data to proceed."
      );
    }
    if (status !== "success") {
      throw new Error(
        "An error occurred while submitting result! Please try again later."
      );
    }
  };
  const updatePersonnel = async (values, uid) => {
    const response = await axios.post(API_PATH.personnels, {
      ...values,
      role: values.role ? values.role.rid : "",
      lab: values.lab ? values.lab.lid : "",
      job: values.job ? values.job.uid : "",
      department: values.department ? values.department.did : "",
      update: uid,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occurred while updating the personnel's details! Please try again later."
      );
    } else {
      const { user } = state.user;
      if (parseInt(uid, 10) === parseInt(user.uid, 10)) {
        initialize();
      }
    }
  };
  const updateInventory = async (values, iid) => {
    const response = await axios.post(API_PATH.inventory, {
      ...values,
      update: iid,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occurred while updating the item's details! Please try again later."
      );
    }
  };
  const getTests = async (
    patient,
    search,
    date,
    page,
    perpage,
    order,
    sortBy
  ) => {
    if (!patient) {
      throw new Error(
        "Invalid patient's ID supplied! Please go back to the patients page to select the right one."
      );
    }
    const response = await axios.post(API_PATH.tests, {
      patient,
      page,
      search,
      perpage,
      order,
      sortBy,
      date,
    });
    const { status, data, total } = response.data;
    if (status === "success") {
      return { data, total };
    }

    if (status === "not_found") {
      throw new Error(
        "Invalid patient's ID supplied! Please go back to the patients page to select the right one."
      );
    }
    throw new Error(
      "Something went wrong while fetching patient's data! Please try again later."
    );
  };
  const getSamples = async (
    patient,
    search,
    date,
    page,
    perpage,
    order,
    sortBy
  ) => {
    if (!patient) {
      throw new Error(
        "Invalid patient's ID supplied! Please go back to the patients page to select the right one."
      );
    }
    const response = await axios.post(API_PATH.samples, {
      patient,
      page,
      search,
      perpage,
      order,
      sortBy,
      date,
    });
    const { status, data, total } = response.data;
    if (status === "success") {
      return { data, total };
    }

    if (status === "not_found") {
      throw new Error(
        "Invalid patient's ID supplied! Please go back to the patients page to select the right one."
      );
    }
    throw new Error("Something went wrong! Please try again later.");
  };
  const getRoles = async () => {
    const response = await axios.post(API_PATH.roles, { get: "" });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }

    if (status === "not_found") {
      throw new Error(
        "Invalid patient's ID supplied! Please go back to the patients page to select the right one."
      );
    }
    throw new Error("Something went wrong! Please try again later.");
  };
  const getPermissions = async () => {
    const response = await axios.post(API_PATH.roles, { permissions: "" });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    throw new Error("Something went wrong! Please try again later.");
  };

  return (
    <AuthContext.Provider
      // eslint-disable-next-line
      value={{
        ...state,
        method: "jwt",
        switchLab,
        login,
        logout,
        getPatients,
        addLab,
        getLabes,
        getOrders,
        addPatient,
        addIventory,
        updateOrder,
        getLab,
        updateLab,
        updateLabStatus,
        getPatient,
        updatePatient,
        addFile,
        getTests,
        sendReply,
        getSamples,
        getDepartments,
        getAdmins,
        initialize,
        getOrder,
        getAllFolders,
        getFileSeens,
        addFolder,
        uploadFile,
        getRoles,
        getPermissions,
        getCategories,
        addRole,
        updateRole,
        addTest,
        updateTest,
        addPersonnel,
        getPersonnel,
        getLabs,
        getJobs,
        logSample,
        updatePersonnel,
        submitTestResult,
        updateInventory,
        getInventory,
        getDocument,
        readDocument,
        moveFile,
        renameFile,
        removeFolder,
        addCategory,
        updateCategory,
        addDepartment,
        updateDepartment,
        addPackage,
        updatePackage,
        removeRole,
        getRawTests,
        addOrder,
        sendResult,
        getPatientAnalytics,
        getPackages,
        getTestsAnalytics,
        getPersonnelsAnalytics,
        updateAppointment,
        addAppointment,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
