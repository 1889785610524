import { styled } from "@mui/material/styles";
import { Box, Card, Stack, Container, Typography } from "@mui/material";
// components
import Page from "../../components/Page";
import { LoginForm } from "../../components/authentication/login";
import Logo from "../../components/Logo";

const RootStyle = styled(Page)(({ theme }) => ({
  backgroundImage: "url('/static/auth_bg.svg')",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  return (
    <RootStyle title="Login to Dashboard">
      <Container maxWidth="sm">
        <ContentStyle>
          <Card sx={{ p: 3 }}>
            <Stack
              direction="row"
              alignItems="center"
              sx={{
                mb: 5,
              }}
            >
              <Box sx={{ flexGrow: 1, textAlign: "center" }}>
                <Logo isText sx={{ mx: "auto", mb: 5 }} />
                <Typography variant="h4" gutterBottom>
                  Sign in to Cell Diagnostics Lab
                </Typography>
                <Typography sx={{ color: "text.secondary" }}>
                  Enter your email address or username and password to proceed!
                </Typography>
              </Box>
            </Stack>
            <LoginForm />
          </Card>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
