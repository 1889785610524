import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
// slices
import dashboardReducer from "./slices/dashboard";
import testReducer from "./slices/test";
import sampleReducer from "./slices/sample";
import personnelReducer from "./slices/personnel";
import inventoryReducer from "./slices/inventory";
import patientReducer from "./slices/patient";
import logReducer from "./slices/log";
import helpReducer from "./slices/help";
import instrumentReducer from "./slices/instrument";
import documentReducer from "./slices/document";
import approvalReducer from "./slices/approval";
import analysisReducer from "./slices/analysis";
import rawTestReducer from "./slices/rawTest";
import categoryReducer from "./slices/categories";
import LabReducer from "./slices/lab";
import DepartmentReducer from "./slices/department";
import NotificationReducer from "./slices/notification";
import deviceReducer from "./slices/device";
import packageReducer from "./slices/package";
import appointmentReducer from "./slices/appointment";
import billingReducer from "./slices/billing";

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const rootReducer = combineReducers({
  dashboard: dashboardReducer,
  lab: LabReducer,
  test: testReducer,
  rawTests: rawTestReducer,
  sample: sampleReducer,
  personnel: personnelReducer,
  inventory: inventoryReducer,
  helpdesk: helpReducer,
  patient: patientReducer,
  log: logReducer,
  document: documentReducer,
  instrument: instrumentReducer,
  approval: approvalReducer,
  analysis: analysisReducer,
  category: categoryReducer,
  department: DepartmentReducer,
  notification: NotificationReducer,
  device: deviceReducer,
  package: packageReducer,
  appointment: appointmentReducer,
  billing: billingReducer,
});

export { rootPersistConfig, rootReducer };
