import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { CircularProgress, Typography, Button, Box, Stack } from '@mui/material';
import { SeverErrorIllustration } from '../assets';
import { MotionContainer, varBounceIn } from '../components/animate';

// eslint-disable-next-line
export const CircularIndeterminate = ({ message }) => (<Stack spacing={2}
    direction="column"
    alignItems="center"
    justifyContent="center" sx={{ display: 'flex', height: '100vh' }}> <CircularProgress sx={{ mx: 'auto' }} />{message && (<Typography variant="subtitle2" sx={{ textAlign: 'center' }}>{message}</Typography>)}</Stack>);
    
// eslint-disable-next-line
export const ShowError = ({ message, tryAgain, onTryAgain, buttonText }) => {
    if (!message) {
        return <Box />;
    }
    return (<MotionContainer initial="initial" open>
        <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center', mt: 5, p: 2 }}>
            <Typography sx={{ color: 'error.main' }}>
                {message.message}
            </Typography>
            <motion.div variants={varBounceIn}>
                <SeverErrorIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
            </motion.div>
            {tryAgain &&
                (<Button size="large" variant="contained" onClick={() => {
                    if (onTryAgain) {
                        onTryAgain()
                    } else {
                        window.location.reload();
                    }
                }}>
                    {buttonText || "Try Again"}
                </Button>)}
        </Box>
    </MotionContainer>)
};
ShowError.propTypes = {
    message: PropTypes.any,
    tryAgain: PropTypes.bool
};
export const iff = (condition, then, otherwise) => condition ? then : otherwise;