import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
import { API_PATH } from "../../utils/apis";

const initialState = {
  isLoading: false,
  error: false,
  deleteError: false,
  search: "",
  page: 0,
  perPage: 10,
  total: 0,
  data: [],
  sortBy: null,
  filters: {
    method: "",
  },
};

const slice = createSlice({
  name: "analysis",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    removeDeleteError(state) {
      state.deleteError = false;
    },
    hasDeleteError(state, action) {
      state.isLoading = false;
      state.deleteError = action.payload;
    },
    getSuccess(state, action) {
      state.isLoading = false;
      state.data = action.payload.data;
      state.total = parseInt(action.payload.total, 10);
    },
    deletedSuccess(state, action) {
      const deleted = action.payload.split(",");
      state.data = state.data.filter((obj) => !deleted.includes(obj.uid));
      state.isLoading = false;
    },
    //  SORT & FILTER POSTS
    sortBy(state, action) {
      state.sortBy = action.payload;
    },
    filter(state, action) {
      state.filters.method = action.payload.method;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { filter, sortBy } = slice.actions;

export function getAnalysis(
  search,
  stat,
  role,
  branch,
  page,
  perpage,
  order,
  sortBy
) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(API_PATH.data, {
        page,
        status: stat,
        search,
        perpage,
        order,
        sortBy,
        role,
        branch,
      });
      const { status, data, total } = response.data;
      if (status === "success") {
        dispatch(slice.actions.getSuccess({ data, total }));
      } else {
        dispatch(
          slice.actions.hasError(
            new Error(
              "We cannot fetch your analysisistrators at the moment! Please check back later."
            )
          )
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
