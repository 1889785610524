// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/";
const ROOTS_DASHBOARD = "/";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  Login: path(ROOTS_AUTH, "login"),
};

export const PATH_PAGE = {
  about: "about",
  faqs: "faqs",
  page404: "404",
  errorOccured: "500",
  storeError: "store-error",
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    root: path(ROOTS_DASHBOARD, ""),
    profile: path(ROOTS_DASHBOARD, "profile"),
    settings: path(ROOTS_DASHBOARD, "settings"),
  },
  laboratory: {
    patients: path(ROOTS_DASHBOARD, "patients"),
    addPatient: path(ROOTS_DASHBOARD, "patients/add"),
    samples: path(ROOTS_DASHBOARD, "samples"),
    addSample: path(ROOTS_DASHBOARD, "samples/add"),
    tests: path(ROOTS_DASHBOARD, "tests"),
    billings: path(ROOTS_DASHBOARD, "payments"),
    addTest: path(ROOTS_DASHBOARD, "tests/add"),
    inventory: path(ROOTS_DASHBOARD, "inventory"),
    addInventory: path(ROOTS_DASHBOARD, "inventory/add"),
    documents: path(ROOTS_DASHBOARD, "document-manager"),
    addDocument: path(ROOTS_DASHBOARD, "document-manager/add"),
    approvals: path(ROOTS_DASHBOARD, "approvals"),
    appointments: path(ROOTS_DASHBOARD, "appointments"),
    addApprovals: path(ROOTS_DASHBOARD, "approvals/add"),
    personnel: path(ROOTS_DASHBOARD, "personnel"),
    addPersonnel: path(ROOTS_DASHBOARD, "personnel/add"),
    instruments: path(ROOTS_DASHBOARD, "instruments"),
    addInstruments: path(ROOTS_DASHBOARD, "instruments/add"),
    help: path(ROOTS_DASHBOARD, "helpdesk"),
    analytics: path(ROOTS_DASHBOARD, "analytics"),
    log: path(ROOTS_DASHBOARD, "log"),
    devices: path(ROOTS_DASHBOARD, "devices"),
  },
  subpages: {
    trackTest: path(ROOTS_DASHBOARD, "test/track"),
    testResult: path(ROOTS_DASHBOARD, "test/result"),
    logSample: path(ROOTS_DASHBOARD, "sample/log"),
    patient: path(ROOTS_DASHBOARD, "patient"),
    editPatient: path(ROOTS_DASHBOARD, "patient/edit"),
    personnel: path(ROOTS_DASHBOARD, "view-personnel"),
    document: path(ROOTS_DASHBOARD, "document-manager/viewer"),
    showResult: path(ROOTS_DASHBOARD, "test/show-result"),
  },
};

export const frontendPages = ["/login", "/404", "/error-occured"];
