import { useEffect, useRef } from "react";
import useAuth from "../hooks/useAuth";

export default function SessionTimeout() {
  const { isAuthenticated, logout } = useAuth();
  const logoutTimerIdRef = useRef(null);

  useEffect(() => {
    const autoLogout = () => {
      if (document.visibilityState === "hidden" && isAuthenticated) {
        const timeOutId = window.setTimeout(() => {
          logout(true);
        }, 60 * 60 * 1000);
        logoutTimerIdRef.current = timeOutId;
      } else {
        window.clearTimeout(logoutTimerIdRef.current);
      }
    };
    document.addEventListener("visibilitychange", autoLogout);
    return () => {
      document.removeEventListener("visibilitychange", autoLogout);
    };
  }, [logout, isAuthenticated]);
}
