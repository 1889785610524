import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
import { API_PATH } from "../../utils/apis";

const initialState = {
  isLoading: false,
  error: false,
  summary: {},
  billings: [],
};

const slice = createSlice({
  name: "billing",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // GET PRODUCTS
    getSuccess(state, action) {
      state.isLoading = false;
      state.billings = action.payload.data;
      state.summary = action.payload.summary;
      state.total = parseInt(action.payload.total, 10);
    },
    //  SORT & FILTER PRODUCTS
    sortBy(state, action) {
      state.sortBy = action.payload;
    },

    filter(state, action) {
      state.filters.category = action.payload.category;
    },
  },
});

// Reducer
export default slice.reducer;

export const { filter, sortBy } = slice.actions;

export function getBillings(search, date, page, perpage, order, sortBy) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(API_PATH.billings, {
        search,
        page,
        perpage,
        date,
        order,
        sortBy,
      });
      const { status, data, total, summary } = response.data;
      if (status === "success") {
        dispatch(slice.actions.getSuccess({ data, total, summary }));
      } else {
        dispatch(
          slice.actions.hasError(
            new Error(
              "We cannot fetch billings at the moment! Please check back later."
            )
          )
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
